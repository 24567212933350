import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAddress, IEvent } from '@renovars/common';
import { Opportinity, PltEventType } from '@renovars/common/plenitude';

import { MessageService } from 'primeng/api';
import { tap, catchError, of } from 'rxjs';
import { OpportunityEventService } from '../../../services/opportunity-event.service';

@Component({
  selector: 'opportunity-create-event-modal',
  template: `
    <div mat-dialog-content>
      <calendar-slot-selector-modal (valueChange)="onDateSelected($event)"></calendar-slot-selector-modal>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="close()" class="btn btn-default rounded me-3">Annulla</button>
      <button mat-button (click)="save()" cdkFocusInitial class="btn btn-warning rounded">Salva</button>
    </div>
  `,
  styles: [],
})
export class OpportunityCreateEventModalComponent implements OnInit {
  private eventData: IEvent & { opportunity: string };
  constructor(
    private opportunityEventService: OpportunityEventService,
    @Inject(MAT_DIALOG_DATA) public data: { opportunity: Opportinity; type: PltEventType },
    public dialogRef: MatDialogRef<OpportunityCreateEventModalComponent>,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {}
  onDateSelected(event: {
    address: IAddress;
    start: Date;
    end: Date;
    user: string;
    medium: number;
    connectionInfo: string;
  }) {
    this.eventData = {
      ...event,
      opportunity: this.data.opportunity._id,
      contact: this.data.opportunity.contact,
      type: this.data.type,
    };
  }

  save() {
    const source$ = this.opportunityEventService.createOpportunityEvent(this.data.opportunity._id, this.eventData);
    source$
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Evento creato con successo',
          });
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        }),
      )
      .subscribe(() => this.dialogRef.close());
  }
  close() {
    this.dialogRef.close();
  }
}
