<div [formGroup]="form">
  <div>
    <div class="row">
      <div class="col-12">
        <header class="page-header">
          <h2 class="tx-title-2">Dati prodotto</h2>
        </header>
      </div>
    </div>

    <div class="mt-2 row gy-2">
      <div class="col-sm-4">
        <fullstack-input-box [options]="{ label: 'Nome' }" formControlName="name"></fullstack-input-box>
      </div>

      <div class="col-sm-4">
        <fullstack-input-select-box [options]="{ label: 'Categoria' }" formControlName="category">
          <sb-option
            [key]="'code'"
            [value]="i"
            *ngFor="let i of domainValuesList$ | async | domainValuesFilter : 'products_category_type'"
          >
            {{ i.label }}
          </sb-option>
        </fullstack-input-select-box>
      </div>

      <div class="col-sm-4">
        <fullstack-input-box
          [options]="{ label: 'Prezzo' }"
          [type]="'number'"
          formControlName="price"
        ></fullstack-input-box>
      </div>

      <div class="col-sm-12">
        <fullstack-input-textarea
          [options]="{ label: 'Descrizione' }"
          formControlName="description"
        ></fullstack-input-textarea>
      </div>
    </div>
  </div>
</div>
