import { NgModule } from '@angular/core';
import { SignSurfaceComponent } from './sign-surface.component';
import { MatButtonModule } from '@angular/material/button';


@NgModule({
  declarations: [SignSurfaceComponent],
  imports: [MatButtonModule],
  providers: [],
  exports: [SignSurfaceComponent],
})
export class SignSurfaceModule {}
