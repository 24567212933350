import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ITimelineUiItem } from '@renovars/common';
import { ContactsServices } from '@renovars/fe-core-libs/services';
import { catchError, of, tap } from 'rxjs';

@Component({
  selector: 'contact-upsert',
  template: `
    <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">Contatti</h1>
      <h2 class="tx-subtitle mb-0">Creazione/Modifica</h2>
    </ng-template>

    <ng-template header-menu>
      <div class="d-flex justify-content-between">
        <fullstack-timeline #timeline>
          <ng-template timeline-items>
            <contacts-timeline-content
              [items]="timelineItems"
            ></contacts-timeline-content>
          </ng-template>
        </fullstack-timeline>
        <div class="me-4">
          <button class="btn btn-timeline" (click)="timeline.timelineToggle()">
            <img src="assets/img/icn-timeline.svg" alt="icon" />
          </button>
        </div>
      </div>
    </ng-template>

    <form [formGroup]="form" novalidate>
      <div class="content">
        <fullstack-contact-form formControlName="contact">
        </fullstack-contact-form>
        <div class="row mt-4">
          <div class="col-sm-12 text-end">
            <button
              type="button"
              class="btn btn-primary rounded me-4"
              [routerLink]="['/contacts']"
            >
              Annulla
            </button>
            <button
              type="button"
              class="btn btn-secondary rounded"
              (click)="save()"
              [disabled]="!form.valid || form.get('contact').value === null"
            >
              Salva
            </button>
          </div>
        </div>
      </div>
    </form>
  `,
})
export class ContactUpsertComponent implements OnInit {
  form = this.fb.group({
    contact: new UntypedFormControl(null),
  });
  timelineItems: ITimelineUiItem[];
  id: string = null;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private contactsServices: ContactsServices,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params['id'];
      if (this.id) {
        this.contactsServices.getContact(this.id).subscribe((res) => {
          if (res != null) this.form.patchValue({ contact: res });
        });
      }
    });

    this.loadTimeline();
  }

  save() {
    if (this.id) {
      this.contactsServices
        .updateContact(this.form.value.contact, this.id)
        .pipe(
          tap(() =>
            this.router.navigate(['..'], { relativeTo: this.activatedRoute })
          )
        )
        .subscribe();
    } else {
      this.contactsServices
        .saveContact(this.form.value.contact)
        .pipe(
          tap(() =>
            this.router.navigate(['..'], { relativeTo: this.activatedRoute })
          )
        )
        .subscribe();
    }
  }

  private loadTimeline() {
    this.contactsServices
      .getTimeline(this.id)
      .pipe(catchError((err) => of(null)))
      .subscribe((res) => (this.timelineItems = res));
  }
}
