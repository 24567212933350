import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HistoryComponent } from '../../components/history.component';
import { OpportunityDocumentsComponent } from './component/opportunity-documents.component';
import { OpportunityListComponent } from './component/opportunity-list.component';
import { OpportunityTabComponent } from './component/opportunity-tab.component';
import { OpportunityUpsertFormComponent } from './component/opportunity-upsert-form.component';
import { OpportunityFromNewContactFormComponent } from './component/opportunity-from-new-contact-form.component';
import { PrivacyConsentsComponent } from '@fi/app/components/privacy-consents/privacy-consents.component';
import { EstimatesComponent } from '@fi/app/components/estimates/estimates.component';
import { BuyerComponent } from '@fi/app/components/buyer/buyer.component';
import { OpportunityProfileComponent } from './component/opportunity-profile.component';

const routes: Routes = [
  {
    path: '',
    component: OpportunityListComponent,
  },
  {
    path: 'details',
    component: OpportunityTabComponent,
    children: [
      {
        path: 'upsert',
        component: OpportunityUpsertFormComponent,
      },
      {
        path: 'profile',
        component: OpportunityProfileComponent,
      },
      {
        path: 'privacy',
        component: PrivacyConsentsComponent,
        data: {
          type: 'opportunity',
        },
      },
      {
        path: 'estimates',
        component: EstimatesComponent,
        data: {},
      },
      {
        path: 'buyer',
        component: BuyerComponent,
      },
      {
        path: 'newContact',
        component: OpportunityFromNewContactFormComponent,
      },
      {
        path: 'documents',
        component: OpportunityDocumentsComponent,
      },
      {
        path: 'history',
        component: HistoryComponent,
        data: {
          type: 'opportunity',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class OpportunityRoutingModule {}
