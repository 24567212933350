import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'fullstack-columns-selector',
  template: `
    <button (click)="op.toggle($event)" class=" px-3 py-0 ">
      <i class="fa fa-bookmark text-gray-800"></i>
    </button>

    <p-overlayPanel #op>
      <input
        type="text"
        [(ngModel)]="searchTerm"
        (input)="updateFilteredOptions()"
        placeholder="Cerca..."
      />

      <button
        (click)="toggleSelectAll()"
        class="border border-gray-800 mx-2 px-2 rounded text-[#777777]"
      >
        {{
          checkedColumns.length == availableColumns.length
            ? 'Deseziona tutto'
            : 'Seleziona tutto'
        }}
      </button>
      <hr />
      <div class="!max-h-[40vh] overflow-y-auto">
        <div
          *ngFor="let col of filteredOptions"
          class="flex items-center m-2 p-2 cursor-pointer"
        >
          <p-checkbox
            name="group1"
            value="{{ col }}"
            [(ngModel)]="checkedColumns"
            inputId="{{ col }}"
          ></p-checkbox>
          <label class="mx-3 cursor-pointer text-base" for="{{ col }}">{{
            col
          }}</label>
        </div>
      </div>
      <button
        (click)="saveAndClose()"
        class="btn btn-secondary rounded px-3 py-0 m-2"
        [disabled]="checkedColumns.length == 0"
      >
        <i class="fa fa-bookmark text-white"></i>
        {{
          checkedColumns.length > 0
            ? 'Salva preferenza'
            : 'Seleziona almeno una voce'
        }}
      </button>
    </p-overlayPanel>
  `,
})
export class ColumnsSelectorComponent implements OnInit {
  @Input() availableColumns: Array<string> = [];
  @Input() checkedColumns: Array<string> = [];
  @ViewChild('op') overlayPanel: OverlayPanel;
  @Output() selectedColumns: EventEmitter<Array<string>> = new EventEmitter<
    Array<string>
  >();
  filteredOptions: Array<string> = [];
  searchTerm: string;

  ngOnInit() {
    this.filteredOptions = this.availableColumns;
  }

  saveAndClose() {
    if (this.checkedColumns.length > 0) {
      this.selectedColumns.emit(this.checkedColumns);
      this.overlayPanel.hide();
    }
  }

  toggleSelectAll() {
    if (this.checkedColumns.length < this.availableColumns.length) {
      this.checkedColumns = [...this.availableColumns];
    } else {
      this.checkedColumns = [];
    }
  }

  updateFilteredOptions() {
    if (this.searchTerm) {
      this.filteredOptions = this.availableColumns.filter((option) =>
        option.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    } else {
      this.filteredOptions = this.availableColumns;
    }
  }
}
