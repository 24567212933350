import { Injectable } from '@angular/core';
import { IUser } from '@renovars/common';
import { IUtentiModuleService } from '@renovars/core-ng';
import { PltRoles, PltRolesLevels } from 'src/libs/shared/plenitude/src';

@Injectable()
export class UtentiService implements IUtentiModuleService {
  calculateNetworkPriority(user: IUser, applicationId: string) {
    const reg = user.registrations?.find((r) => r.applicationId == applicationId);

    const roles = reg?.roles;

    let maxLevel = 0;
    roles?.forEach((r) => {
      const level = PltRolesLevels.indexOf(<PltRoles>r);
      if (maxLevel < level) {
        maxLevel = level;
      }
    });

    return PltRolesLevels.slice(maxLevel + 1);
  }
}
