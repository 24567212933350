import { Component, forwardRef, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { IProduct } from '@renovars/common/plenitude';
import { DomainValuesService } from '@renovars/fe-core-libs/services/domain-values.service';
import { FormElement } from '@renovars/ui-ng';
import { IFormBuilder, IFormGroup } from '@rxweb/types';

@Component({
  selector: 'products-form',
  templateUrl: `./products-form.component.html`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProductsFormComponent),
      multi: true,
    },
  ],
})
export class ProductsFormComponent extends FormElement implements OnInit {
  form: IFormGroup<IProduct>;
  fb: IFormBuilder = new UntypedFormBuilder();
  id: string;
  domainValuesList$;

  constructor(private domainValuesService: DomainValuesService) {
    super();
    this.form = this.fb.group<IProduct>({
      _id: new UntypedFormControl(null),
      name: new FormControl<string>(null, [Validators.required]),
      category: new FormControl<number>(null, [Validators.required]),
      description: new FormControl<string>(null, []),
      price: new FormControl<number>(null, [Validators.required]),
    });

    this.form.patchValue(this.value);
  }

  ngOnInit(): void {
    this.domainValuesList$ = this.domainValuesService.list();
    this.form.valueChanges.subscribe((v) => this._onChange(v));
  }
}
