import {
  DocumentAction,
  DocumentRequiredState,
  IDomainValue,
} from '@renovars/common';

export enum FiDomainValueType {
  LEAD_STATE = 'lead_state',
  LEAD_KO_STATE = 'lead_ko_state',
  LEAD_REASON = 'lead_reason',
  LEAD_SOURCE = 'lead_source',
  OPPORTUNITY_STATE = 'opportunity_state',
  OPPORTUNITY_LEASE_STATE = 'opportunity_lease_state',
  OPPORTUNITY_KO_STATE = 'opportunity_ko_state',
  PROPERTY_SALE_REASON = 'property_sale_reason',
  PROPERTY_FLOOR_TYPE = 'property_floor_type',
  PROPERTY_PURCHASE_REASON = 'property_purchase_reason',
  PROPERTY_TYPOLOGY = 'property_typology',
  PROPERTY_TYPE = 'property_type',
  CADASTRAL_CATEGORY = 'cadastral_category',
  ENERGY_CLASS = 'energy_class',
  FLOOR = 'floor',
  GARDEN = 'garden',
  GARAGE = 'garage',
  FURNITURE = 'furniture',
  PROPERTY_CLASS = 'property_class',
  PROPERTY_STATE = 'property_state',
  HEATING = 'heating',
  FREE_SIDES = 'free_sides',
  OVERLOOKING = 'overlooking',
  HEATING_PLANT_TYPE = 'heating_plant_type',
  AIR_CONDITIONING_PLANT = 'air_conditioning_plant',
  HEATING_TYPE = 'heating_type',
  AIR_CONDITIONING_PLANT_TYPE = 'air_conditioning_plant_type',
  PROPERTY_SALE_STATE = 'property_sale_state',
  OPPORTUNITY_PURCHASE_STATE = 'opportunity_purchase_state',
  OPPORTUNITY_RENT_STATE = 'opportunity_rent_state',
  EVENT_MEDIUM = 'event_medium',
  EVENT_TYPE = 'event_type',
  PROPERTY_PROJECT_STATE = 'property_project_state',
  CONCIERGE_SERVICE = 'concierge_service',
  EXTERNAL_FIXTURES = 'external_fixtures',
  FINISHES = 'finishes',
  KITCHEN = 'kitchen',
  KITCHEN_QUALITY = 'kitchen_quality',
  PLANTS_QUALITY = 'plants_quality',
  TV_SYSTEM = 'tv_system',
  EVENT_STATE = 'event_state',
  PROPERTY_STRUCTURE_STATE = 'property_structure_state',
  PURCHASE_MUST_SELL_BEFORE = 'purchase_must_sell_before',
  BC_PRODUCT_CAPTIVE = 'bc_product_captive',
  BC_PRODUCT_PROSPECT = 'bc_product_prospect',
  TAX_CODE = 'tax_code',
  LEAD_DOCUMENTS_TYPE = 'lead_documents_type',
}

export interface ILeadDocumentsDomainValueMeta {
  action: DocumentAction;
  required: boolean;
  requiredState: DocumentRequiredState[];
}

export interface IFiDomainValue extends IDomainValue<FiDomainValueType> {}
