import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ContextMenuModule } from 'primeng/contextmenu';
import { TableModule } from 'primeng/table';
import { DatatableComponent } from './datatable.component';
import {
  DatatableBodyDirective, DatatableCellDirective,
  DatatableColumnDirective,
  DatatableColumnTitleDirective,
  DatatableFilterDirective,
  DatatableHeaderDirective,
} from './datatable.directive';
import {ColumnsSelectorComponent} from "./columns-selector.component";
import {OverlayPanelModule} from "primeng/overlaypanel";
import {CheckboxModule} from "primeng/checkbox";

const COMPONENTS = [
  DatatableComponent,
  DatatableHeaderDirective,
  DatatableBodyDirective,
  DatatableCellDirective,
  DatatableColumnDirective,
  DatatableFilterDirective,
  DatatableColumnTitleDirective,
  ColumnsSelectorComponent
];
@NgModule({
  declarations: COMPONENTS,
  imports: [CommonModule, FormsModule, TableModule, ContextMenuModule, OverlayPanelModule, CheckboxModule],
  exports: [...COMPONENTS],
  providers: [],
})
export class DatatableModule {}
