import { Inject, Injectable } from '@angular/core';
import { IAppConfig } from './interfaces/app-config.interface';

@Injectable()
export class AppConfigService {
  constructor(@Inject('APP_CONFIG') private config: IAppConfig) {}

  getApplicationId(): string {
    return this.config.applicationId;
  }

  getApiGatewayURL(): string {
    return this.config.apiGatewayURL;
  }

  getApiPortaleURL(): string {
    return this.config.apiPortaleURL;
  }

  getRedirectUrl(): string {
    return this.config.redirectUrl;
  }

  getConfig(): IAppConfig {
    return this.config;
  }

  getApiProxiedURL(): string {
    return this.config.apiProxiedUrl;
  }
}
