import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private messageService: MessageService, private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((err: HttpErrorResponse) => {
        const error = err.error;

        if (error.status == HttpStatusCode.Forbidden) {
          this.router.navigate(['/login']);
        }
        if (error.message) {
          let errorMsg = '';
          if (typeof error.message === 'string') {
            errorMsg = error.message;
          } else {
            Object.keys(error.message).forEach((k) => {
              errorMsg += `${error.message[k]}\n`;
            });
          }

          this.messageService.add({
            severity: 'error',
            summary: 'Errore',
            detail: errorMsg,
          });
        }
        return throwError(error);
      }),
    );
  }
}
