import { IAddress, IContact, IContactCompany, IContactIndividual, IPrivacy, ITrackingData } from '@renovars/common';
import { PltLeadStatusCode } from '../../enums';
import { PaymentsType } from '../payments';
import { Opportinity } from './opportunities.interface';
import { Profile } from '../profile';
export type FlowStatus<S> = {
  code: keyof S;
  user: string;
  date: Date;
  metadata?: any;
  assigned?: string;
};
export interface CommonFlow<S> {
  _id: string;
  status: FlowStatus<S>;
  history: FlowStatus<S>[];
  assigned: string[];
  createdBy: string;
  createdAt: Date;
  updatedAt: Date;
}
interface CommonLeadData {
  addressOp: IAddress;
  callPreference: string;
  concern: PaymentsType;
  trackingUrl: string;
  tracking: ITrackingData;

  profile: Profile;
  forProduct: string;
  notes: { note: string }[];
  privacy: IPrivacy;
}
export type LeadStatus = FlowStatus<typeof PltLeadStatusCode>;
export type LeadStatusCommand = Pick<LeadStatus, 'code' | 'metadata' | 'assigned'>;
export interface Lead extends CommonLeadData, CommonFlow<typeof PltLeadStatusCode> {
  contact: string;
  cache?: {
    contact: IContactCompany | IContactIndividual;
  };
}

export interface ICreateLeadDto extends CommonLeadData, CommonFlow<typeof PltLeadStatusCode> {
  contact: IContactCompany | IContactIndividual;
}

export interface IUpdateLead {
  contact?: Partial<IContactCompany | IContactIndividual>;
  lead?: Partial<Lead>;
}

export interface LeadFilter {
  assigned: string;
  createdBy: string;
  createdAt: { start: Date; end: Date };
  'cache.contact.lastName': string;
  'cache.contact.firstName': string;
  'cache.contact.phone': string;
  'cache.addressOp.locality': string;
  'status.code': string;
  'status.user': string;
  'privacy.marketing': null;
  'privacy.commercial': null;
  'privacy.profile': null;
  'privacy.otherSub': null;
}

export type UpdateLeadProperty = CommonLeadData;

interface OkLead {
  status: 'OK';
}
interface ErrorLead {
  status: 'ERROR';
}
export type DuplicateLeadReturnValue =
  | OkLead
  | ErrorLead
  | {
      status: 'DUPLICATE';
      duplicate: string;
    };

export interface ICreateOpportinityFromContactDTO {
  contact: IContact;
  leadId: string;
  lead: UpdateLeadProperty;
}

export function isLead(data: Lead | Opportinity | Record<string, unknown>): data is Lead {
  return 'concern' in data;
}
