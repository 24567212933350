<div class="content">
  <h3>Nota</h3>

  <div>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-12">
          <fullstack-input-textarea
            [options]="{ label: 'Testo *', rows: 8, cols: 80 }"
            formControlName="note"
          ></fullstack-input-textarea>
          <div *ngIf="form.get('note').errors">
            <small class="text-danger">Inserisci almeno 5 caratteri per le note</small>
          </div>
        </div>
      </div>
      <div class="text-end buttons">
        <button type="button" class="btn btn-default rounded m-1" (click)="close()">Annulla</button>
        <button
          type="submit"
          class="btn btn-secondary rounded m-1"
          (click)="confirm()"
          [disabled]="!form.valid"
        >
          Conferma
        </button>
      </div>
    </form>
  </div>
</div>
