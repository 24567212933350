<label class="card card--form">
  <span class="card-body"
    ><span class="label-control">{{ options.label }}</span>
    <textarea
      class="form-control"
      type="text"
      [ngModel]="value"
      (ngModelChange)="_onChange($event)"
      [disabled]="isDisabled"
      placeholder="{{ options.placeholder }}"
      rows="{{ options.rows }}"
      cols="{{ options.cols }}"
      readonly="{{ options.readonly }}"
    >
    </textarea>
  </span>
</label>
