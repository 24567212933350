import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fi/environment/environment';
import { IPromotion } from '@renovars/common/plenitude';

type PromotionsBrowseResponse = { items: IPromotion[]; total: number };

@Injectable()
export class PromotionsService {
  constructor(private http: HttpClient) {}
  browse(filter: { offset: number; limit: number }) {
    return this.http.post<PromotionsBrowseResponse>(`${environment.apiGatewayURL}/promotions/browse`, filter);
  }
  delete(id: string) {
    return this.http.delete<any>(`${environment.apiGatewayURL}/promotions/${id}`);
  }
  findById(id: string) {
    return this.http.get<IPromotion>(`${environment.apiGatewayURL}/promotions/${id}`);
  }
  save(dto: Omit<IPromotion, '_id'>) {
    return this.http.post<any>(`${environment.apiGatewayURL}/promotions`, dto);
  }
  update(id: string, dto) {
    return this.http.put<any>(`${environment.apiGatewayURL}/promotions/${id}`, dto);
  }
}
