<ng-template header-body>
  <div class="text-end align-items-center">
    <button class="btn btn-header" [routerLink]="['./detail']">
      <i class="fas fa-plus cl-medium fa-1x"></i>
    </button>
  </div>
</ng-template>

<fullstack-datatable
  [list]="promotions"
  [cmItems]="cmItems"
  (selected)="selected = $event"
  [options]="{ lazy: true }"
  [totalRecords]="total"
  (lazyLoad)="onLazyLoad($event)"
>
  <div t-header>
    <div t-column [name]="'Nome'"></div>
    <div t-column [name]="'Descrizione'"></div>
    <div t-column [name]="'Costo materiali'"></div>
    <div t-column [name]="'Costo installazione'"></div>
    <div t-column [name]="'Costo marketing'"></div>
    <div t-column [name]="'Costo call center'"></div>
    <div t-column [name]="'Margine'"></div>
    <div t-column [name]="'Totale'"></div>
  </div>

  <ng-template t-body let-row>
    <td>{{ row?.name }}</td>
    <td>{{ row?.description }}</td>
    <td>{{ row?.materialsCost | currency: 'EUR' : '€' : '0.0-2' }}</td>
    <td>{{ row?.installationCost | currency: 'EUR' : '€' : '0.0-2' }}</td>
    <td>{{ row?.marketingCost | currency: 'EUR' : '€' : '0.0-2' }}</td>
    <td>{{ row?.callcenterCost | currency: 'EUR' : '€' : '0.0-2' }}</td>
    <td>{{ row?.marginality | currency: 'EUR' : '€' : '0.0-2' }}</td>
    <td>{{ row?.total | currency: 'EUR' : '€' : '0.0-2' }}</td>
  </ng-template>
</fullstack-datatable>
