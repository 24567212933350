const appointmentTemplate = /*html*/ `<fullstack-header *ngIf="!headerLess"></fullstack-header>

  <ng-template *ngIf="!headerLess" header-body>

    <h1 class="tx-title-1">Appuntamenti</h1>
    <fullstack-checkbox-button
      [ngModel]="paginateRequest.form.state ? paginateRequest.form.state[0] : null"
      (ngModelChange)="paginateRequest.form.state = $event ? [$event] : null; search()"
    >
      <checkbox *ngFor="let state of stateList" [label]="state.label" [value]="state.value"></checkbox>
      <checkbox [label]="'Tutti'"></checkbox>
    </fullstack-checkbox-button>

    <!-- <h2 class="tx-subtitle mb-0"></h2> -->
    <div class="text-end align-items-center">
    <button class="btn btn-header" (click)="downloadCsv('event')">
      <i class="fas fa-download cl-medium fa-1x"></i>
    </button>
    </div>
  </ng-template>

  <div class="d-flex flex-row-reverse p-2">
  <fullstack-input-select-box
    [options]="{ label: 'Network' }"
    ngModel
    (ngModelChange)="setNetworkFilter($event); loadAgentList($event)"
  >
    <sb-option [key]="'id'" [value]="elem" *ngFor="let elem of networkList$ | async"
      >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
    >
  </fullstack-input-select-box>
</div>
<fullstack-datatable
    [options]="{lazy : true}"
    (lazyLoad)="onLazyLoad($event)"
    [list]="eventsList?.data"
    [totalRecords]="eventsList?.total"
    (selected)="selected = $event; defineContextMenu()"
    [cmItems]="cmItems"
    [paginateRequest]="paginateRequest"
    [showColumnSelector]="true"
    [visibleColumns]="visibleColumns"
    (selectedColumnsChoosed)="saveSelectedColumn($event)"
    (rowsChange)="saveRowCount($event)"
    [maxRows]="maxRows"
  >
    <div t-header>
      <!--<div t-column [name]="'Nominativo'" [width]="'250px'">
        <fullstack-input-select-box
          *t-filter
          [options]="{autocomplete : true}"
          ngModel
          (ngModelChange)="paginateRequest.form.contactId = $event; search()"
          (inputChange)="searchContact($event)"
        >
          <sb-option [value]="i.id" *ngFor="let i of contacts$ | async"
            >{{ i | contactName}}</sb-option
          >
        </fullstack-input-select-box>
      </div>-->
      <div t-column [name]="'Nome'" field="cache.contact.firstName">
          <fullstack-input-box *t-filter (keyup.enter)="search()" [(ngModel)]="paginateRequest.form.firstName">
          </fullstack-input-box>
      </div>
      <div t-column [name]="'Cognome'" field="cache.contact.lastName">
        <fullstack-input-box *t-filter (keyup.enter)="search()" [(ngModel)]="paginateRequest.form.lastName">
        </fullstack-input-box>
      </div>
      <div t-column [name]="'Data inserimento'" field="createdAt">
        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.insertDateRange = $event; search()">
        </fullstack-daterangepicker>
      </div>
      <div t-column [name]="'Stella'" field="isStar">
      </div>
      <div t-column [name]="'Telefono'">
        <fullstack-input-box
          *t-filter
          (keyup.enter)="search()"
          [(ngModel)]="paginateRequest.form.phone"
        >
        </fullstack-input-box>
      </div>
      <div t-column [name]="'Email'">
        <fullstack-input-box
          *t-filter
          (keyup.enter)="search()"
          [(ngModel)]="paginateRequest.form.email"
        >
        </fullstack-input-box>
      </div>
      <div t-column [name]="'Indirizzo'">
        <fullstack-input-box
          *t-filter
          (keyup.enter)="search()"
          [(ngModel)]="paginateRequest.form.address"
        >
        </fullstack-input-box>
      </div>
      <div t-column [name]="'Data inizio'" field="start">
        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.eventStartRange = $event; search()">
        </fullstack-daterangepicker>
      </div>
      <div t-column [name]="'Data fine'">

        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.eventEndRange = $event; search()">
        </fullstack-daterangepicker>
      </div>
      <div t-column [name]="'Tipo'">

        <fullstack-input-select-box
          *t-filter
          [ngModel]="paginateRequest.form.eventType"
          (ngModelChange)="paginateRequest.form.eventType = $event; search()"
        >
          <sb-option [key]="'code'" [value]="i" *ngFor="let i of eventTypes">{{ i.label }}</sb-option>
        </fullstack-input-select-box>
      </div>
      <div t-column [name]="'Visita Openhouse'">

        <fullstack-input-select-box
          *t-filter
          [ngModel]="paginateRequest.form.isOpenhouseVisit"
          (ngModelChange)="paginateRequest.form.isOpenhouseVisit = $event; search()"
        >
        <sb-option [value]="true">Si</sb-option>
        <sb-option [value]="false">No</sb-option>
        </fullstack-input-select-box>
      </div>
      <div t-column [name]="'Utente'">

        <fullstack-input-select-box
          *t-filter
          [ngModel]="paginateRequest.form.userId"
          (ngModelChange)="paginateRequest.form.userId = $event; search()"
        >
          <sb-option [key]="'id'" [value]="elem" *ngFor="let elem of agentList$ | async"
            >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
          >
        </fullstack-input-select-box>
      </div>
      <div t-column [name]="'Esito'">

        <fullstack-input-select-box
        *t-filter
        [ngModel]="paginateRequest.form.state"
        (ngModelChange)="paginateRequest.form.state = $event; search()"
      >
        <sb-option [value]=null>Tutti</sb-option>
        <sb-option [value]="-1">Non esitato</sb-option>
        <sb-option [key]="'code'" [value]="i" *ngFor="let i of eventStates">{{ i.label }}</sb-option>
      </fullstack-input-select-box>
      </div>
      <div t-column [name]="'Data esito eseguito'">

        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.doneStateDate = $event; search()">
        </fullstack-daterangepicker>
      </div>
      <div t-column [name]="'Creato Da'">

        <fullstack-input-select-box
          *t-filter
          [ngModel]="paginateRequest.form.insertUserId"
          (ngModelChange)="paginateRequest.form.insertUserId = $event; search()"
        >
          <sb-option [key]="'id'" [value]="elem" *ngFor="let elem of usersList$ | async"
            >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
          >
        </fullstack-input-select-box>
      </div>
      <div t-column [name]="'Data Creazione opp.'">

        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.oppInsertDateRange = $event; search()">
        </fullstack-daterangepicker>
      </div>
        <div t-column [name]="'Tipo fonte'">

          <fullstack-input-select-box
          *t-filter
          [options]="{autocomplete : false}"
          [ngModel]="paginateRequest.form.cache"
          (ngModelChange)="paginateRequest.form.cache = {
            projectSale: {
              data: {
                source: $event
              }
            }
          }; search()">
        <sb-option
          [key]="'code'"
          [value]="i"
          *ngFor="let i of sourceLeadTypes"
        >{{ i.label }}</sb-option>
        </fullstack-input-select-box>
      </div>
      <div t-column [name]="'Fonte'">

      <fullstack-input-select-box
        *t-filter
        [(ngModel)]="paginateRequest.form.source"
        (ngModelChange)="paginateRequest.form.source = $event; search()"
      >
        <sb-option
          [key]="'code'"
          [value]="i"
          *ngFor="let i of sourcesList"
          >{{ i.label }}</sb-option
        >
      </fullstack-input-select-box>
    </div>
      <div t-column [name]="'Campagna'">

      </div>
      <div t-column [name]="'UTM Campaign'">

        <fullstack-input-box
          pTooltip="Scrivi '/vuoto' per filtrare se il campo è assente o vuoto, oppure scrivi '/not' seguito dal testo che vuoi escludere dalla ricerca"
          *t-filter
          (keyup.enter)="search()"
          [(ngModel)]="paginateRequest.form.utmCampaign"
        ></fullstack-input-box>
      </div>
      <div t-column [name]="'UTM Source'">

        <fullstack-input-box
          pTooltip="Scrivi '/vuoto' per filtrare se il campo è assente o vuoto, oppure scrivi '/not' seguito dal testo che vuoi escludere dalla ricerca"
          *t-filter
          (keyup.enter)="search()"
          [(ngModel)]="paginateRequest.form.utmSource"
        ></fullstack-input-box>
      </div>
    </div>

    <ng-template t-body let-row>
      <!--<td t-cell [name]="'Nominativo'" >{{row.contact | contactName}}</td>-->
      <td t-cell [name]="'Nome'" >{{ row.cache?.contact?.firstName }}</td>
      <td t-cell [name]="'Cognome'" >{{ row.cache?.contact?.lastName }}</td>
      <td t-cell [name]="'Data inserimento'" >{{ row?.createdAt | date : 'short'}}</td>
      <td  t-cell [name]="'Stella'" class="justify-content-center"><i *ngIf="row.isStar" class="fa fa-star" style="color: #e6e645"></i></td>
      <td t-cell [name]="'Telefono'" >
        {{(row?.contact?.phones && row?.contact.phones.length > 0) ? row?.contact?.phones[0].phoneNumber :
        ''}}
      </td>
      <td t-cell [name]="'Email'" >
        {{(row?.contact?.emails && row?.contact.emails.length > 0 )? row?.contact?.emails[0].email : ''}}
      </td>
      <td t-cell [name]="'Indirizzo'" >{{row?.address?.formatted_address}}</td>
      <td t-cell [name]="'Data inizio'" >{{ row?.start | date : 'short'}}</td>
      <td t-cell [name]="'Data fine'" >{{ row?.end | date : 'short'}}</td>
      <td t-cell [name]="'Tipo'" >{{ row?.type | domainValue: 'event_type' | async}}</td>
      <td t-cell [name]="'Visita Openhouse'" >{{ row?.openHouseParentEvent ? 'Si' : 'No'}}</td>
      <td t-cell [name]="'Utente'" >{{ row?.user?.firstName}} {{ row?.user?.lastName}}</td>
      <td t-cell [name]="'Esito'" >{{ (row?.state | domainValue: 'event_state' | async) || 'Non esitato' }}</td>
      <td t-cell [name]="'Data esito eseguito'" >{{ row?._history | historyDates : { 'state' : 1 } }}</td>
      <td t-cell [name]="'Creato Da'" >{{ row?.insertUserObj?.firstName}} {{ row?.insertUserObj?.lastName}}</td>
      <td t-cell [name]="'Data Creazione opp.'" >{{ row?.cache?.projectSale?.data.opportunityInsertDate | date: 'short' }}</td>
      <td t-cell [name]="'Tipo fonte'" >{{ row?.cache?.project?.data?.source || row?.cache?.projectSale?.data?.source | sourceType | async | domainValue : 'source_lead_type' | async}}</td>
      <td t-cell [name]="'Fonte'" >{{ row?.cache?.projectPurchase?.data?.source || row?.cache?.projectSale?.data?.source | sourceLabel | async }}</td>
      <td t-cell [name]="'Campagna'" >{{ row?.cache?.project?.data?.source || row?.cache?.projectSale?.data?.source | sourceCampaign | async}}</td>
      <td t-cell [name]="'UTM Campaign'" >{{ row?.cache?.project?.data?.tracking?.utmCampaign || row?.cache?.projectSale?.data?.tracking?.utmCampaign }}</td>
      <td t-cell [name]="'UTM Source'" >{{ row?.cache?.project?.data?.tracking?.utmSource || row?.cache?.projectSale?.data?.tracking?.utmSource }}</td>
    </ng-template>
  </fullstack-datatable>`;

export default appointmentTemplate;
