import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { FullcalendarComponent } from './fullcalendar.component';
import { FullcalendarEventDirective } from './fullcalendar.directive';
import { CommonModule } from '@angular/common';

const COMPONENTS = [FullcalendarComponent, FullcalendarEventDirective];
@NgModule({
  imports: [CommonModule, FullCalendarModule],
  declarations: COMPONENTS,
  exports: COMPONENTS,
})
export class FullcalendarModule {}
