import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '../../../environments/environment';
import { PltAppointmentComponent } from './plt-appointment.component';
import { SharedModule } from '../../shared.module';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { PipesModule } from '../../pipes/pipes.module';
import { PltAppointmentRoutingModule } from './plt-appointment-routing.module';
import { AppuntamentoModule, SharedCoreModule } from '@renovars/core-ng';

@NgModule({
  declarations: [PltAppointmentComponent],
  imports: [
    CommonModule,
    SharedModule,
    SharedCoreModule,
    PltAppointmentRoutingModule,
    AppuntamentoModule.forRoot({
      eventsEndpoint: `${environment.apiGatewayURL}/events`,
    }),
    ConfirmDialogModule,
    PipesModule,
  ],
  exports: [RouterModule],
})
export class PltAppointmentModule {}
