import { NgModule } from '@angular/core';
import { PdfViewerModule as Ng2PdfViewerModule } from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './pdf-viewer.component';


@NgModule({
  declarations: [PdfViewerComponent],
  imports: [Ng2PdfViewerModule],
  providers: [],
  exports: [PdfViewerComponent],
})
export class PdfViewerModule {}
