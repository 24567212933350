import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'renovars-step-timeline',
  template: `
    <p-timeline [value]="value" align="alternate">
      <ng-template pTemplate="marker" let-event>
        <span class="custom-marker p-shadow-2" *ngIf="!event?.newState?.status?.code">
          <i class="pi pi-replay"></i>
        </span>
        <span class="custom-marker p-shadow-2" *ngIf="event?.newState?.status?.code">
          <i class="pi pi-circle-off"></i>
        </span>
      </ng-template>
      <ng-template pTemplate="content" let-event>
        <div class="tx-thin">{{ event?.createdAt | date: 'dd-MM-yyyy HH:mm' }}</div>
        <div class="tx-thin">{{ event?.username }}</div>
        <div *ngIf="event?.newState?.crmFrSubappaltatoreId">Esportato in FR CRM</div>
        <div>
          <div *ngIf="event?.newState?.status?.code" class="font-weight-bold">
            {{ labels[event?.newState?.status?.code] }}
          </div>
          <div class="tx-12">{{ event?.newState?.status?.data?.date | date: 'dd-MM-yyyy HH:mm' }}</div>
          <div class="tx-12">{{ event?.newState?.status?.data?.note }}</div>
        </div>
        <div class="tx-12" *ngIf="event?.newState?.status?.data?.startDate">
          Appuntamento: {{ event?.newState?.status?.data?.startDate | date: 'dd-MM-yyyy HH:mm' }}
        </div>
      </ng-template>
    </p-timeline>
  `,
  styles: [],
})
export class StepTimelineComponent implements OnInit {
  @Input() value: any[] = [];
  @Input() labels: { [k: string]: string }[];
  ngOnInit() {}
}
