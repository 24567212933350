<div [formGroup]="form">
  <div>
    <div class="row">
      <div class="col-12">
        <header class="page-header">
          <h2 class="tx-title-2">Dati promozione</h2>
        </header>
      </div>
    </div>

    <div class="mt-2 row gy-2">
      <div class="col-sm-6">
        <fullstack-input-box [options]="{ label: 'Nome' }" formControlName="name"></fullstack-input-box>
      </div>
      <div class="col-sm-6">
        <fullstack-input-select-box formControlName="type" [options]="{ label: 'Tipologia:' }">
          <sb-option [value]="1">Principale</sb-option>
          <sb-option [value]="2">Accessorio</sb-option>
        </fullstack-input-select-box>
      </div>
      <div class="col-sm-12">
        <fullstack-input-textarea
          [options]="{ label: 'Descrizione' }"
          formControlName="description"
        ></fullstack-input-textarea>
      </div>
    </div>
  </div>
</div>
