import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from '../../core/ui-interfaces';
import { SidebarItemDirective } from './sidebar-menu.directive';

@Component({
  selector: 'fullstack-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styles: [`
    .show {
      visibility: visible;
    }
  `]
})
export class SidebarMenuComponent implements OnInit, AfterContentInit, OnChanges {
  @Input() options: {
    logoUrl: string;
    logoMinUrl: string;
  };
  @Input() user: string;
  @Input() userId: string;
  @Output() logout: EventEmitter<void> = new EventEmitter<void>();
  @ContentChildren(SidebarItemDirective) children: QueryList<SidebarItemDirective>;
  sidebarItems: SidebarItemDirective[];
  currentItem: MenuItem;

  initials: string;
  constructor(private rootElement: ElementRef, private router: Router) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.user.currentValue) {
      this.initials = this.user
        .split(' ')
        .map((n) => n[0])
        .join('')
        .toUpperCase();
    }
  }

  ngOnInit(): void {
    document.querySelector('#wrapper').classList.remove('sidebar--collapsed');
  }

  ngAfterContentInit(): void {
    this.sidebarItems = this.children.toArray();
    this.children.changes.subscribe(() => (this.sidebarItems = this.children.toArray()));
  }

  checkActive(elem) {
    const activeElem = elem.querySelector('.active');
    return !!activeElem;
  }

  toggleSubitem(elem) {
    const a = elem.classList.contains('active');
    if (!a) {
      const elems = this.rootElement.nativeElement.querySelectorAll('.active');
      elems.forEach((e) => {
        e.classList.remove('active');
      });

      elem.classList.add('active');
    }
  }

  SidebarControl() {
    const element = document.getElementById('wrapper');
    element.classList.toggle('sidebar--collapsed');
  }

  goToProfile() {
    if (this.userId) {
      this.router.navigate(['/profile/additional-info'], { queryParams: { id: this.userId } });
    }
  }
}
