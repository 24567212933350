import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import {
  IEmail,
  IPhone,
  ITimelineItem,
  TimelineOperationType,
} from '@renovars/common';

@Component({
  selector: 'contacts-timeline-content',
  templateUrl: './contacts-timeline-content.component.html',
})
export class ContactsTimelineContentComponent implements OnChanges {
  @Input() items: ITimelineItem[] = [];

  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.items.currentValue) {
      this.processTimeline();
    }
  }

  processTimeline() {
    this.items = this.items.map((i) => {
      if (i.opType == TimelineOperationType.CREATE && i.newState) {
        let phones: IPhone[] = i.newState.phones
          ? Object.values(i.newState.phones)
          : [];

        let emails: IEmail[] = i.newState.emails
          ? Object.values(i.newState.emails)
          : [];

        i['title'] = 'Creazione contatto';
        i['body'] = `
          ${i.newState.firstName} 
          ${i.newState.lastName}
          ${phones.map((p) => p.phoneNumber).join(' - ')}
          ${emails.map((e) => e.email).join(' - ')}`;
        return i;
      }
      if (i.newState?.firstName || i.newState?.lastName) {
        i['title'] = 'Nominativo modificato';
        i['body'] = `${i.newState.firstName} ${i.newState.lastName}`;
        return i;
      }
      if (i.newState?.phones) {
        let phones: IPhone[] = Object.values(i.newState.phones);
        phones = phones.filter((p) => !!p.phoneNumber);
        if (phones.length) {
          i['title'] = 'Telefono modificato';
          i['body'] = `${phones.map((p) => p.phoneNumber).join(' - ')}`;
          return i;
        }
      }
      if (i.newState?.emails) {
        let emails: IEmail[] = Object.values(i.newState.emails);
        emails = emails.filter((e) => !!e.email);
        if (emails.length) {
          i['title'] = 'Email modificata';
          i['body'] = `${emails.map((p) => p.email).join(' - ')}`;
          return i;
        }
      }

      return null;
    });
  }
}
