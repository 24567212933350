import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ISource } from '@renovars/common';
import { SourcesServices } from '@renovars/fe-core-libs/services/sources.service';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'source-list-component',
  templateUrl: `./source-list.component.html`,
})
export class SourceListComponent implements OnInit {
  cmItems: MenuItem[] = [
    {
      label: 'Dettaglio',
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        this.gotoDetail();
      },
    },
    {
      label: 'Elimina',
      icon: 'pi pi-fw pi-trash',
      command: () => {
        this.delete();
      },
    },
  ];
  sources: ISource[];
  selected: ISource = null;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sourceService: SourcesServices
  ) {}

  ngOnInit(): void {
    this.refreshList();
  }

  refreshList() {
    this.sourceService.list(true).subscribe((res) => (this.sources = res));
  }

  gotoDetail() {
    this.router.navigate([`./detail`], {
      queryParams: { id: this.selected.code },
      relativeTo: this.activatedRoute,
    });
  }

  delete() {
    this.sourceService
      .delete(this.selected.code)
      .subscribe(() => this.refreshList());
  }
}
