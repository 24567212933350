<ng-template header-body></ng-template>
<div class="content">
  <form [formGroup]="form" (submit)="save()" novalidate>
    <promotions-form formControlName="promoForm"></promotions-form>
    <div class="col-sm-12 mt-2">
      <p-pickList
        [source]="availableItems"
        [showSourceFilter]="true"
        [showTargetFilter]="true"
        [showSourceControls]="false"
        [showTargetControls]="false"
        [target]="selectedItems"
        sourceHeader="Disponibili"
        targetHeader="Selezionati"
        [dragdrop]="true"
        filterBy="product.name"
        [responsive]="true"
        [sourceStyle]="{ height: '40rem' }"
        [targetStyle]="{ height: '40rem' }"
        breakpoint="1400px"
      >
        <ng-template let-product pTemplate="item">
          <div class="flex flex-wrap p-2 align-items-center gap-3" (dblclick)="voidDblClick($event)">
            <div class="flex-1 flex flex-column gap-2">
              <span class="font-bold">{{ product.product.name }}</span>
              <div class="flex align-items-center gap-2">
                <mat-icon>{{ product.product.category == 1 ? 'local_laundry_service' : 'design_services' }}</mat-icon>
                <span>{{ product.product.category | domainValue : 'products_category_type' | async }}</span>
              </div>
            </div>
            <span class="font-bold text-900">{{ product.product.price | currency : 'EUR' }}</span>

            <div class="flex justify-between w-100 items-center">
              <div>Quantità</div>
              <p-inputNumber
                [size]="'10'"
                min="1"
                [inputStyleClass]="'!bg-gray-200 !font-bold'"
                [showButtons]="true"
                value="1"
                [(ngModel)]="product.quantity"
                [ngModelOptions]="{
                  standalone: true
                }"
              />
            </div>
          </div>
        </ng-template>
      </p-pickList>
    </div>
    <div class="text-end mt-2">
      <button type="submit" mat-raised-button color="primary">Salva</button>
    </div>
  </form>
</div>
