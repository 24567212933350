import { IContactCompany, IContactIndividual, IEvent } from '@renovars/common';
import { Lead, Opportinity } from '../plt-flow';

export enum PltEventType {
  SOPRALLUOGO = 1,
  INSTALLAZIONE = 2,
}
export enum PltEventLabelType {
  'sopralluogo' = 1,
  'installazione' = 2,
}
export interface IClimaEvent extends IEvent {
  lead: string;
  opportunity: string;
  cache?: {
    lead: Lead;
    contact: IContactCompany | IContactIndividual;
    opportunity: Opportinity;
  };
}
