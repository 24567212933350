export interface Profile {
  propietario: boolean;
  indipendente: IsIndipendentAnswer;
  piano: FloorAnswer;
  balconi: boolean;
  mq: MqAnswer;
  camere: RoomsAnswer[];
  posizione_caldaia: BoilerAnswer;
  accensione_caldaia: BoilerStartAnswer;
  termosifoni: RadiatorsAnswer;
  climatizzatori_installati: boolean;
  camere_climatizzatori: RoomsAnswer[];
}

export enum IsIndipendentAnswer {
  casa_indipendente = 'casa_indipendente',
  condominio = 'condominio',
}

export const IsIndipendentAnswerLabel = {
  casa_indipendente: 'Casa indipendente',
  condominio: 'Condominio',
} as const;

export enum FloorAnswer {
  terra = 'terra',
  primo = 'primo',
  secondo = 'secondo',
  terzo = 'terzo',
  quarto = 'quarto',
}

export const FloorAnswerLabel = {
  terra: 'Terra',
  primo: 'Primo',
  secondo: 'Secondo',
  terzo: 'Terzo',
  quarto: 'Quarto',
} as const;

export enum MqAnswer {
  minore_di_120mq = 'minore_di_120mq',
  tra_i_120_e_i_180mq = 'tra_i_120_e_i_180mq',
  maggiore_di_180mq = 'maggiore_di_180mq',
}
export const MqAnswerLabel = {
  minore_di_120mq: 'minore di 120mq',
  tra_i_120_e_i_180mq: 'tra i 120 e i 180mq',
  maggiore_di_180mq: 'maggiore di 180mq',
} as const;

export enum RoomsAnswer {
  cucina_salone = 'cucina_salone',
  salone = 'salone',
  camera_patronale = 'camera_patronale',
  camera_2 = 'camera_2',
  camera_3 = 'camera_3',
  camera_4 = 'camera_4',
}

export const RoomsAnswerLabel = {
  cucina_salone: 'Cucina / cucina+salone',
  salone: 'Salone',
  camera_patronale: 'Camera patronale',
  camera_2: 'Camera 2',
  camera_3: 'Camera 3',
  camera_4: 'Camera 4',
} as const;

export enum BoilerAnswer {
  dentro = 'dentro',
  fuori = 'fuori',
}
export const BoilerAnswerLabel = {
  dentro: 'dentro',
  fuori: 'fuori',
} as const;

export enum BoilerStartAnswer {
  dentro = 'dentro',
  caldaia = 'caldaia',
}

export const BoilerStartAnswerLabel = {
  dentro: 'dentro',
  caldaia: 'caldaia',
} as const;

export enum RadiatorsAnswer {
  minore_di_4_termosifoni = 'minore_di_4_termosifoni',
  maggiore_di_5_termosifoni = 'maggiore_di_5_termosifoni',
}

export const RadiatorsAnswerLabel = {
  minore_di_4_termosifoni: 'minore di 4 termosifoni',
  maggiore_di_5_termosifoni: 'maggiore di 5 termosifoni',
} as const;
