import { Pipe, PipeTransform } from '@angular/core';
import { IContactCompany, IContactIndividual } from '@renovars/common';

@Pipe({ name: 'contactPhone' })
export class ContactPhonePipe implements PipeTransform {
  transform(contact: IContactCompany | IContactIndividual) {
    let res = '<telefono assente>';
    if (contact) {
      if ((<IContactCompany>contact).phones && (<IContactCompany>contact).phones.length > 0)
        res = (<IContactCompany>contact).phones[0].phoneNumber;
    }
    return res;
  }
}
