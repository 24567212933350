import { IContactCompany, IContactIndividual } from '@renovars/common';

export interface ISupplierWorker {
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  fusionId?: string;
  isActive: boolean;
}
export interface ISupplier {
  _id: string;
  contact: string;
  documents: string[];
  kind: 'individual' | 'company';
  management: {
    employerName: string;
    employerSurname: string;
    rlsName: string;
    rlsSurname: string;
    rsppName: string;
    rsppSurname: string;
    doctorName: string;
    doctorSurname: string;
  };
  workers: Array<ISupplierWorker>;
  cache: {
    contact: IContactCompany;
  };
}
