import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PltStatusLabels, PltOpportunityStatusCode } from '@renovars/common/plenitude';
import { filter, map, Observable, switchMap } from 'rxjs';
import { OpportunityApiService } from '../opportunity-api.service';

@Component({
  selector: 'opportunity-documents',
  template: `
  <header class="page-header">
  <h2 class="tx-title-2">Documenti</h2>
  <p class="text-gray-600">Documenti per opportunità e preventivi</p>
</header>
    <document-manager
      [projectId]="id | async"
      [documentStateTypes]="documentStateTypes"
      (fileUploaded)="onFileUp($event)"
    ></document-manager>
  `,
  styles: [],
})
export class OpportunityDocumentsComponent implements OnInit {
  id: Observable<string>;
  documentStateTypes = [
    {
      label: PltStatusLabels.DA_INSTALLARE,
      value: PltOpportunityStatusCode.DA_INSTALLARE,
      color: 'primary',
    },
    { label: 'Altri', value: null, color: 'primary' },
  ];
  constructor(private route: ActivatedRoute, private opportunityService: OpportunityApiService) {}

  ngOnInit(): void {
    this.id = this.route.queryParams.pipe(
      filter((params) => params && params.id),
      map((p) => p.id),
    );
  }
  onFileUp($event) {
    this.id.pipe(switchMap((id) => this.opportunityService.addAttachment(id, $event))).subscribe(console.log);
  }
}
