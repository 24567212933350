<div class="chatbox__container">
  <ng-container *ngIf="messages && messages.length >0; else emptyBlock" >

  <mat-list>
    <mat-list-item *ngFor="let message of messages">
      <div
        mat-line
        [ngClass]="message.isSender == true ? 'chatbox__chat__body__sender' : 'chatbox__chat__body__receiver'"
      >
        <div class="chatbox__chat__user">
          <mat-icon mat-list-icon color="primary">portrait</mat-icon>
        </div>
        <div class="chatbox__chat__message">
          <div class="chatbox__chat__message__author">
            <span> {{ message.author }}</span> {{ message.date | date :'dd/MM/YYYY HH:mm'}}
          </div>
          <div class="message">{{ message.text }}</div>
        </div>
        <div mat-line></div>
        <div mat-line></div>

      </div>
    </mat-list-item>
  </mat-list>
  </ng-container>
  <ng-template #emptyBlock>
    Inizia la conversazione usando il bottone "Aggiungi nota"</ng-template>

</div>
