import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@renovars/fe-core-libs/guards/auth.guard';
import { FeauturesSharedModule } from '../features-shared.module';
import { HomePageComponent } from './components/pages/home-page.component';
import { WelcomeComponent } from './components/welcome/welcome.component';

export const ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'welcome',
    pathMatch: 'full',
  },
  {
    path: '',
    component: HomePageComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'welcome',
        component: WelcomeComponent,
      },
      {
        path: 'users',
        loadChildren: () => import('@renovars/core-ng').then((m) => m.UtentiModule),
      },
      {
        path: 'contacts',
        loadChildren: () => import('@renovars/core-ng').then((m) => m.ContactModule),
      },
      {
        path: 'flows',
        loadChildren: () => import('../lead/lead.module').then((m) => m.LeadModule),
      },
      {
        path: 'opportunity',
        loadChildren: () => import('../plt-opportunity/opportunity.module').then((m) => m.OpportunityModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('@renovars/core-ng').then((m) => m.ProfileModule),
      },
      {
        path: 'network',
        loadChildren: () => import('@renovars/core-ng').then((m) => m.NetworkModule),
      },
      {
        path: 'supplier',
        loadChildren: () => import('../supplier/supplier.module').then((m) => m.SupplierModule),
      },
      {
        path: 'events',
        loadChildren: () =>
          import('../appointment/plt-appointment.module').then((m) => m.PltAppointmentModule),
      },
      {
        path: 'calendar',
        loadChildren: () => import('../plt-calendar/plt-calendar.module').then((m) => m.PltCalendarModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./../settings/settings.module').then((m) => m.SettingsModule),
      },
    ],
  },
];

@NgModule({
  declarations: [HomePageComponent],
  imports: [RouterModule.forChild(ROUTES), FeauturesSharedModule],
  providers: [],
})
export class HomeModule {}
