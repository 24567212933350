<div class="input-file-upload">
  <div class="label-control" *ngIf="options?.label">{{ options?.label }}</div>

  <div *ngIf="!readonly">
    <input
      [accept]="requiredFileType"
      type="file"
      [id]="inputId"
      style="display: none"
      (change)="onFileSelectFn($event)"
      multiple="true"
    />

    <div class="card p-1 d-flex flex-row-reverse mb-1">
      <div class="m-1">
        <button type="button" class="btn btn-secondary rounded m-0" (click)="onSaveChanges.emit()">
          Salva modifiche
        </button>
      </div>
      <div class="m-1">
        <label class="btn btn-secondary rounded m-0" [for]="inputId">Upload</label>
      </div>
    </div>
  </div>
  <div
    class="card p-2"
    *ngIf="!singleView && fileList?.length > 0"
    [ngStyle]="maxHeight ? { 'max-height': maxHeight, 'overflow-y': 'scroll' } : {}"
  >
    <ng-container *ngFor="let f of fileList; let i = index">
      <div class="d-flex align-items-center">
        <div class="flex-fill">
          <ng-container *ngTemplateOutlet="rowTemplate.templateRef; context: { $implicit: f }"></ng-container>
        </div>
        <div class="p-1 h4 text-center" style="width: 150px">
          <button type="button" class="m-1" (click)="open(i)">
            <i class="fas fa-external-link-alt"></i>
          </button>
          <button type="button" class="m-1" (click)="download(i)">
            <i class="fas fa-download"></i>
          </button>
          <button type="button" class="m-1" (click)="delete(i)"><i class="fa fa-times"></i></button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
