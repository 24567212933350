import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
  selector: 'app-sign-surface',
  templateUrl: './sign-surface.component.html',
  styleUrls: ['./sign-surface.component.scss'],
})
export class SignSurfaceComponent implements AfterViewInit {
  @ViewChild('drawingArea') private canvas: ElementRef = {} as ElementRef;
  canvasContext: CanvasRenderingContext2D;

  state;

  lineWidth = 4;
  halfLineWidth = this.lineWidth / 2;
  fillStyle = '#000';
  strokeStyle = '#000';


  @Output('signed') signed = new EventEmitter<any>()
  ngAfterViewInit(): void {
    this.state = {
      mousedown: false,
    };
    this.canvasContext = this.canvas.nativeElement.getContext('2d');
    this.initCanvasBackground();
    // =====================
    // == Event Listeners ==
    // =====================
    this.canvas.nativeElement.addEventListener('mousedown', this.handleWritingStart.bind(this));
    this.canvas.nativeElement.addEventListener('mousemove', this.handleWritingInProgress.bind(this));
    this.canvas.nativeElement.addEventListener('mouseup', this.handleDrawingEnd.bind(this));
    this.canvas.nativeElement.addEventListener('mouseout', this.handleDrawingEnd.bind(this));

    this.canvas.nativeElement.addEventListener('touchstart', this.handleWritingStart.bind(this));
    this.canvas.nativeElement.addEventListener('touchmove', this.handleWritingInProgress.bind(this));
    this.canvas.nativeElement.addEventListener('touchend', this.handleDrawingEnd.bind(this));
  }

  initCanvasBackground() {
    this.canvasContext.fillStyle = 'transparent';
    this.canvasContext.fillRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  }
  clear() {
    this.canvasContext.clearRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
    this.canvasContext.fillStyle = 'transparent';
    this.canvasContext.fillRect(0, 0, this.canvas.nativeElement.width, this.canvas.nativeElement.height);
  }

  handleWritingStart(event) {
    event.preventDefault();

    const mousePos = this.getMouseCoords(event);

    this.canvasContext.beginPath();

    this.canvasContext.moveTo(mousePos.x, mousePos.y);

    this.canvasContext.lineWidth = this.lineWidth;
    this.canvasContext.strokeStyle = this.strokeStyle;

    this.canvasContext.fill();

    this.state.mousedown = true;
  }

  handleWritingInProgress(event) {
    event.preventDefault();

    if (this.state.mousedown) {
      const mousePos = this.getMouseCoords(event);

      this.canvasContext.lineTo(mousePos.x, mousePos.y);
      this.canvasContext.stroke();
    }
  }
  getPosition(el) {
    var xPosition = 0;
    var yPosition = 0;

    while (el) {
      xPosition += el.offsetLeft - el.scrollLeft + el.clientLeft;
      yPosition += el.offsetTop - el.scrollTop + el.clientTop;
      el = el.offsetParent;
    }
    return {
      x: xPosition,
      y: yPosition,
    };
  }

  handleDrawingEnd(event) {
    event.preventDefault();

    if (this.state.mousedown) {
      this.canvasContext.stroke();
    }

    this.state.mousedown = false;
  }
  getMouseCoords(event) {
    const canvasPos = this.getPosition(this.canvas.nativeElement);
    const clientX = event.clientX || event.touches[0].clientX;
    const clientY = event.clientY || event.touches[0].clientY;
    const canvasX = clientX - canvasPos.x;
    const canvasY = clientY - canvasPos.y;

    return { x: canvasX, y: canvasY };
  }

  save() {
    //let image = this.canvas.nativeElement.toDataURL('image/png').replace('image/png', 'image/octet-stream'); // here is the most important part because if you dont replace you will get a DOM 18 exception.

    // window.location.href = image; // it will save locally
    let instance = this;
    this.canvas.nativeElement.toBlob(function (blob) {
      instance.signed.emit(blob)
    }, 'image/png');

    /*
    UPLOAD AS BLOB

    let file = null;
    let blob = document.querySelector("#canvas").toBlob(function(blob) {
        file = new File([blob], 'test.png', { type: 'image/png' });
      }, 'image/png');



      UPLOAD AS BASE64
      let image_base64 = document.querySelector("#canvas").toDataURL().replace(/^data:image\/png;base64,/, "");

    */
  }
}
