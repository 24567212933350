import { Injectable } from '@angular/core';
import { IUser } from '@renovars/common';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import { distinctUntilChanged, Observable, shareReplay } from 'rxjs';
import { PltRoles } from 'src/libs/shared/plenitude/src';

@Injectable()
export class PltUsersCacheService {
  cachedUsers$: Observable<IUser[]>;
  constructor(private usersService: UsersService) {}

  getUsers(): Observable<IUser[]> {
    if (!this.cachedUsers$) {
      this.cachedUsers$ = this.usersService
        .excludeNetworkUsers$([
          PltRoles.is_admin,
          PltRoles.is_cc_operator,
          PltRoles.is_technical_coordinator,
          PltRoles.is_customer_satisfaction,
          PltRoles.is_installer,
        ])
        .pipe(
          shareReplay(1),
          distinctUntilChanged((p, c) => p.length !== c.length),
        );
    }
    return this.cachedUsers$;
  }
}
