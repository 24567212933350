import { PltRolesLabel } from '@renovars/common/plenitude';

export interface IRole {
  description?: string;
  name: string;
  isDefault?: boolean;
  isSuperRole?: boolean;
}

export enum CommonRoles {
  IS_ADMIN = 'is_admin',
}
export const LabelRoles = PltRolesLabel;
