import { Component } from '@angular/core';
import { UntypedFormGroup, FormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Observable, map, switchMap, tap } from 'rxjs';
import { OpportunityApiService } from '../opportunity-api.service';
import { LeadApiService } from '../../lead/lead-api';

@Component({
  selector: 'app-opportunity-profile',
  template: `
    <form [formGroup]="form">
      <profile-form formControlName="profile" />
      <div class="row mt-4">
        <div class="col-sm-12 text-end">
          <button type="button" class="btn btn-primary rounded me-4" [routerLink]="['/flows']">Annulla</button>
          <button type="submit" class="btn btn-secondary rounded" (click)="save()" [disabled]="!form.valid">
            Salva
          </button>
        </div>
      </div>
    </form>
  `,
  styles: [],
})
export class OpportunityProfileComponent {
  form: UntypedFormGroup;
  id$: Observable<string>;
  leadId: string;
  constructor(
    private fb: FormBuilder,
    private readonly opportunityApi: OpportunityApiService,
    private readonly leadApi: LeadApiService,
    private route: ActivatedRoute,
  ) {
    this.form = this.fb.group({
      profile: new UntypedFormControl(),
    });
  }
  ngOnInit(): void {
    this.id$ = this.route.queryParamMap.pipe(map((params) => params.has('id') && params.get('id')));
    this.id$
      .pipe(
        switchMap((id) =>
          this.opportunityApi.getById(id).pipe(
            tap((opportunity) => this.form.patchValue({ profile: opportunity?.cache?.lead?.profile })),
            tap((opp) => (this.leadId = opp.lead)),
            map(() => id),
          ),
        ),
      )
      .subscribe();
  }
  save() {
    if (!this.leadId) return;
    this.leadApi.patchProfile(this.leadId, this.form.get('profile').value).subscribe();
  }
}
