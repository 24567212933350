<h1 mat-dialog-title>Carica documento</h1>
<div class="row gy-2">
  <fullstack-input-select-box [options]="{ label: 'Tipo documento:' }" [(ngModel)]="idType">
    <sb-option *ngFor="let t of documentTypes$ | async" [value]="t.code">{{ t.label }}</sb-option>
  </fullstack-input-select-box>

  <fullstack-input-box [options]="{ label: 'Note: (almeno 5 caratteri) *' }" [(ngModel)]="note">
  </fullstack-input-box>
</div>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Chiudi</button>
  <button
    mat-raised-button
    color="primary"
    (click)="fileInput.click()"
    [disabled]="!idType || !(note && note.length >= 5)"
  >
    Seleziona file
  </button>
  <input type="file" #fileInput (change)="uploadFile($event.target.files)" hidden />
</mat-dialog-actions>
