import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Lead, PltLeadStatusCode, PltStatusLabels } from '@renovars/common/plenitude';
import { LeadApiService } from '../lead-api';

@Component({
  selector: 'move-lead-state',
  template: `
    <h2 mat-dialog-title>
      Cambia stato del lead in:
      <span class="capitalize font-bold text-amber-400">{{ codeLabel[statusCode] }}</span>
    </h2>

    <mat-dialog-content class="mat-typography">
      <form #statusForm="ngForm">
        <div class="row">
          <div class="col-12">
            <fullstack-input-textarea [options]="{ label: 'Note', rows: 2 }" [(ngModel)]="note" name="note">
            </fullstack-input-textarea>
          </div>
        </div>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button (click)="annulla()">Annulla</button>

      <button mat-raised-button color="primary" (click)="save()">Salva</button>
    </mat-dialog-actions>
  `,
  styles: [],
})
export class MoveLeadStateComponent implements OnInit {
  statusCode: PltLeadStatusCode;
  StatusCodes: PltLeadStatusCode;
  codeLabel = PltStatusLabels;
  lead: Lead;
  note: string;
  constructor(
    public dialogRef: MatDialogRef<MoveLeadStateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { code: PltLeadStatusCode; lead: Lead },
    private flowService: LeadApiService,
  ) {}
  ngOnInit(): void {
    this.statusCode = this.data.code;
    this.lead = this.data.lead;
  }
  save() {
    this.flowService
      .moveState(this.lead._id, { code: this.statusCode, metadata: { note: this.note } })
      .subscribe(() => this.dialogRef.close(null));
  }
  close(event) {
    this.dialogRef.close(event);
  }
  annulla() {
    this.dialogRef.close(null);
  }
}
