import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowMenuService } from './flow-menu.service';
import { StateDefinition } from '@renovars/common/plenitude';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class FlowClientModule {
  static register<R, S, L>(options: { sd: StateDefinition<R, S>; labels: L }): ModuleWithProviders<FlowClientModule> {
    return {
      ngModule: FlowClientModule,
      providers: [
        {
          provide: 'FLOW_OPTION',
          useValue: options,
        },
        FlowMenuService,
      ],
    };
  }
}
