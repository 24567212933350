import { NgModule } from '@angular/core';
import { RadioButtonModule } from 'primeng/radiobutton';
import { SharedModule } from '../shared.module';
import { LeadFormComponent } from './lead-form.component';
import { EstimateFormComponent } from './estimate-form/estimate-form.component';
import { PickListModule } from 'primeng/picklist';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { PipesModule } from '../pipes/pipes.module';
import { ProfileFormComponent } from './profile-form.component';

const DECLARATIONS = [LeadFormComponent, EstimateFormComponent, ProfileFormComponent];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [SharedModule, RadioButtonModule, PickListModule, TableModule, InputTextModule, PipesModule],
  providers: [],
  exports: [...DECLARATIONS],
})
export class FormsModule {}
