import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAddress, IEvent } from '@renovars/common';
import { Lead, PltEventType } from '@renovars/common/plenitude';
import { EventService } from '@renovars/core-ng';

import { MessageService } from 'primeng/api';
import { tap, catchError, of } from 'rxjs';

@Component({
  selector: 'create-event-lead-modal',
  template: `
    <mat-dialog-content class="mat-typography">
      <calendar-slot-selector-modal (valueChange)="onDateSelected($event)"></calendar-slot-selector-modal>
    </mat-dialog-content >
    <mat-dialog-actions align="end">
      <button mat-button (click)="close()">Annulla</button>
      <button mat-raised-button color="primary" (click)="save()" >Salva</button>
    </mat-dialog-actions>
  `,
  styles: [],
})
export class CreateEventLeadModalComponent implements OnInit {
  private eventData: IEvent & { lead: string };
  constructor(
    private eventService: EventService,
    @Inject(MAT_DIALOG_DATA) public data: { lead: Lead; type: PltEventType },
    public dialogRef: MatDialogRef<CreateEventLeadModalComponent>,
    private messageService: MessageService,
  ) {}

  ngOnInit(): void {}
  onDateSelected(event: {
    address: IAddress;
    start: Date;
    end: Date;
    user: string;
    medium: number;
    connectionInfo: string;
  }) {
    this.eventData = { ...event, lead: this.data.lead._id, contact: this.data.lead.contact, type: this.data.type };
  }
  save() {
    const source$ = this.eventService.create(this.eventData);
    source$
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Evento creato con successo',
          });
        }),
        catchError((err) => {
          this.messageService.add({
            severity: 'error',
            summary: err?.message,
          });
          return of(err);
        }),
      )
      .subscribe(() => this.dialogRef.close());
  }

  close() {
    this.dialogRef.close();
  }
}
