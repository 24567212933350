import { Component, forwardRef, OnInit } from '@angular/core';
import {
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { ISource } from '@renovars/common';
import { DomainValuesService } from '@renovars/fe-core-libs/services/domain-values.service';
import { FormElement } from '@renovars/ui-ng';
import { IFormBuilder, IFormGroup } from '@rxweb/types';

@Component({
  selector: 'source-form',
  templateUrl: `./source-form.component.html`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SourceFormComponent),
      multi: true,
    },
  ],
})
export class SourceFormComponent extends FormElement implements OnInit {
  form: IFormGroup<ISource>;
  fb: IFormBuilder = new UntypedFormBuilder();
  id: string;
  domainValuesList$;

  constructor(private domainValuesService: DomainValuesService) {
    super();
    this.form = this.fb.group<ISource>({
      label: new UntypedFormControl(null, [Validators.required]),
      campaign: new UntypedFormControl(null, []),
      description: new UntypedFormControl(null, []),
      type: new UntypedFormControl(null, []),
      group: new UntypedFormControl(null, []),
    });

    this.form.patchValue(this.value);
  }

  ngOnInit(): void {
    this.domainValuesList$ = this.domainValuesService.list();
    this.form.valueChanges.subscribe((v) => this._onChange(v));
  }
}
