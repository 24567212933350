import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPromotion } from '@renovars/common/plenitude';

@Component({
  selector: 'app-select-primary-package-modal',
  templateUrl: './select-primary-package-modal.component.html',
  styleUrls: ['./select-primary-package-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class SelectPrimaryPackageModalComponent implements OnInit {

  primaryPackages: IPromotion[] | undefined;

  selectedPackage: IPromotion | undefined;
  constructor(
    public dialogRef: MatDialogRef<SelectPrimaryPackageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selected: string, primaryPackages: IPromotion[] },
  ) {}
  ngOnInit(): void {

    this.primaryPackages = this.data.primaryPackages;

    this.selectedPackage = this.primaryPackages.find(p => p._id === this.data.selected)
  }
  confirm() {
    this.dialogRef.close({ selected: { ...this.selectedPackage, quantity: 1 } });
  }
}
