import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'promotions-page',
  template: ` <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">Pacchetti</h1>
    </ng-template>

    <router-outlet></router-outlet>`,
})
export class PromotionsPageComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
