export interface ITimelineItem {
  oldState?: any;
  newState?: any;
  insertDate?: Date;
  userId?: string;
  username?: string;
  mongoCollection?: string;
  docId?: Object;
  opType?: TimelineOperationType;
  migrationId?: string;
}

export enum TimelineOperationType {
  UPDATE = 'update',
  CREATE = 'create',
  DELETE = 'delete',
  REMOVE = 'remove',
  MAIL = 'mail',
}
