import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { OpportunityMoveStateComponent } from '@fi/app/features/plt-opportunity/modals/opportunity-move-state.component';
import { OpportunityApiService } from '@fi/app/features/plt-opportunity/opportunity-api.service';
import { CreateEstimateModalComponent } from '@fi/app/modals/create-estimate-modal/create-estimate-modal.component';
import { InsertSignatureModalComponent } from '@fi/app/modals/insert-signature-modal/insert-signature-modal.component';
import { UpdateEstimateModalComponent } from '@fi/app/modals/update-estimate-modal/update-estimate-modal.component';
import { ViewEstimatePdfModalComponent } from '@fi/app/modals/view-estimate-pdf-modal/view-estimate-pdf-modal.component';
import { EstimateService } from '@fi/app/services/estimates.service';
import {
  EstimateState,
  IEstimate,
  Opportinity,
  PaymentsTypeLabel,
  PltOpportunityStatusCode,
} from '@renovars/common/plenitude';
import { ConfirmModalComponent } from '@renovars/ui-ng';
import { MenuItem } from 'primeng/api';
import { filter, switchMap, tap } from 'rxjs';
@Component({
  selector: 'app-estimates',
  templateUrl: './estimates.component.html',
  styleUrls: ['./estimates.component.scss'],
})
export class EstimatesComponent implements OnInit {
  paymentTypeLabels = PaymentsTypeLabel;
  cmItems: MenuItem[] = [
    {
      label: 'Dettaglio',
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        this.viewEstimate();
      },
    },
    {
      label: 'Stampa per cliente',
      icon: 'pi pi-fw pi-print',
      command: () => {
        this.printEstimate(false);
      },
    },
    {
      label: 'Stampa per installatore',
      icon: 'pi pi-fw pi-print',
      command: () => {
        this.printEstimate(true);
      },
    },
    {
      label: 'Firma preventivo',
      icon: 'pi pi-fw pi-check-circle',
      command: () => {
        this.signEstimate();
      },
    },
    {
      label: 'Firma contratto',
      icon: 'pi pi-fw pi-check-circle',
      command: () => {
        this.signContract();
      },
    },
    {
      label: 'Stampa contratto',
      icon: 'pi pi-fw pi-print',
      command: () => {
        this.printContract();
      },
    },
    {
      label: 'Anteprima',
      icon: 'pi pi-fw pi-search-plus',
      command: () => {
        this.previewEstimate();
      },
    },
    {
      label: 'Cambia stato',
      icon: 'pi pi-fw pi-cog',
      items: [
        {
          label: 'Da presentare',
          command: () => {
            this.changeState(EstimateState.DA_PRESENTARE);
          },
        },
        {
          label: 'Presentato',
          command: () => {
            this.changeState(EstimateState.PRESENTATO);
          },
        },
        {
          label: 'Accettato',
          command: () => {
            this.changeState(EstimateState.ACCETTATO);
          },
        },
        {
          label: 'Rifiutato',
          command: () => {
            this.changeState(EstimateState.RIFIUTATO);
          },
        },
      ],
    },
    {
      label: 'Elimina',
      icon: 'pi pi-fw pi-trash',
      command: () => {
        this.delete();
      },
    },
  ];
  estimates: IEstimate[];
  selected: IEstimate = null;

  opportunityId: string;
  opportunity: Opportinity;
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private estimateApi: EstimateService,
    private opportunityApi: OpportunityApiService,
    public dialog: MatDialog,
  ) {}

  ngOnInit(): void {
    this.getOpportunityData();
  }

  getOpportunityData() {
    this.activatedRoute.queryParams
      .pipe(
        tap((params) => (this.opportunityId = params.id)),
        switchMap((_) => this.opportunityApi.getById(this.opportunityId)),
        tap((opportunity: Opportinity) => (this.opportunity = opportunity)),
        switchMap((_) => this.estimateApi.browse({ opportunity: { $eq: this.opportunityId } })),
      )

      .subscribe((res) => (this.estimates = res.items));
  }

  refreshList() {
    this.estimateApi
      .browse({ opportunity: { $eq: this.opportunityId } })
      .subscribe((res) => (this.estimates = res.items));
  }

  delete() {
    this.dialog
      .open(ConfirmModalComponent, {
        data: {
          text: `Sei sicuro di voler eliminare questo preventivo ?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((res) => res && res.confirmed),
        switchMap((_) => this.estimateApi.delete(this.selected._id)),
      )
      .subscribe(() => this.refreshList());
  }

  changeState(state: EstimateState) {
    this.dialog
      .open(ConfirmModalComponent, {
        data: {
          text: `Sei sicuro di voler cambiare lo stato di questo preventivo ?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((res) => res && res.confirmed),
        switchMap((_) => this.estimateApi.update(this.selected._id, { state })),
        switchMap((_) => this.opportunityApi.getById(this.opportunityId)),
        tap((opportunity: Opportinity) => (this.opportunity = opportunity)),
      )
      .subscribe(() => {
        this.refreshList();

        if (state === EstimateState.RIFIUTATO && this.estimates.length == 1) {
          this.rejectOpportunity();
        }
      });
  }

  rejectOpportunity() {
    this.dialog
      .open(OpportunityMoveStateComponent, {
        data: {
          opportunity: this.opportunity,
          code: PltOpportunityStatusCode.KO_PREZZO,
        },
      })
      .afterClosed()
      .subscribe((_) => this.getOpportunityData());
  }

  createEstimate() {
    this.dialog
      .open(CreateEstimateModalComponent, {
        panelClass: 'estimate-modal',
        disableClose: true,
        data: {
          opportunity: this.opportunity,
        },
      })
      .afterClosed()
      .subscribe((_) => this.refreshList());
  }

  viewEstimate() {
    this.dialog
      .open(UpdateEstimateModalComponent, {
        panelClass: 'estimate-modal',
        maxHeight: '85vh',
        disableClose: true,
        data: {
          estimate: this.selected,
          opportunity: this.opportunity,
        },
      })
      .afterClosed()
      .subscribe((_) => this.refreshList());
  }

  printEstimate(isInstaller) {
    this.estimateApi.export(this.selected._id, isInstaller);
  }

  printContract() {
    this.estimateApi.exportContract(this.selected._id);
  }

  signEstimate() {
    this.dialog.open(InsertSignatureModalComponent, {
      data: {
        type: 40,
        estimateId: this.selected._id,
        projectId: this.selected.opportunity,
      },
    });
  }
  signContract() {
    this.dialog.open(InsertSignatureModalComponent, {
      data: {
        type: 41,
        estimateId: this.selected._id,
        projectId: this.selected.opportunity,
      },
    });
  }
  previewEstimate() {
    this.dialog.open(ViewEstimatePdfModalComponent, {
      panelClass: 'preview-modal',
      data: {
        type: 40,
        estimateId: this.selected._id,
        projectId: this.selected.opportunity,
      },
    });
  }

  resolveState(state: EstimateState) {
    switch (state) {
      case EstimateState.DA_PRESENTARE:
        return 'Da presentare';
      case EstimateState.PRESENTATO:
        return 'Presentato';
      case EstimateState.ACCETTATO:
        return 'Accettato';
      case EstimateState.RIFIUTATO:
        return 'Rifiutato';
    }
  }

  notValidOpportunityState() {
    return this.opportunity.status.code === PltOpportunityStatusCode.SOPRALLUOGO_FISSATO
  }
}
