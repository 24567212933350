import { Component, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  PltEventType,
  PltRoles,
  PltLeadStatusCode,
  PltStatusLabels,
  getPltUserRole,
  Lead,
  getEnumKeyByEnumValue,
  StatusIcon,
} from '@renovars/common/plenitude';
import { AuthServices, FlowMenuService } from '@renovars/core-ng';
import { MenuItem } from 'primeng/api';
import { CreateEventLeadModalComponent } from '../modals/create-event-lead-modal.component';
import { LeadApiService } from '../lead-api';
import { MoveLeadStateComponent } from '../modals/move-lead-state.component';
import { IUser } from '@renovars/common';
import { map, Observable } from 'rxjs';
import { PltUsersCacheService } from '../../../services/plt-users-cache.service';
import { EmptyLeadFilter } from './lead.filter.interface';

@Component({
  selector: 'lead-list',
  templateUrl: './lead-list.component.html',
  styles: ['p-sortable-columns { width: 15px; }'],
})
export class LeadListComponent {
  cmItems: MenuItem[] = [];
  items: Lead[];
  total: number;
  flow: Lead;
  selectedLead: Lead;
  labels = PltStatusLabels;
  codes = Object.values(PltLeadStatusCode);
  selectableUsers$: Observable<IUser[]>;
  filter = EmptyLeadFilter;
  offset = 0;
  limit = 10;
  sorting = { by: 'createdAt', order: -1 };
  constructor(
    private flowMenuService: FlowMenuService<typeof PltRoles, typeof PltLeadStatusCode, PltStatusLabels>,
    private cacheUserService: PltUsersCacheService,
    private pltFlowService: LeadApiService,
    private authService: AuthServices,
    private dialog: MatDialog,
    private router: Router,
  ) {
    this.selectableUsers$ = this.cacheUserService.getUsers();
  }
  load() {
    this.pltFlowService
      .browse({
        offset: this.offset,
        limit: this.limit,
        filter: this.filter,
        sort: [this.sorting],
      })
      .subscribe((res) => {
        this.items = res.items;
        this.total = res.total;
      });
  }
  onLazyLoad(event) {
    this.offset = event.first ?? 0;
    this.limit = event.rows ?? 10;
    this.sorting = {
      by: event?.sortField ?? 'createdAt',
      order: event?.sortOrder ?? -1,
    };
    this.load();
  }
  buildMenu(selectedLead: Lead) {
    if (!selectedLead) return;
    this.authService
      .user()
      .pipe(
        map((u) =>
          this.flowMenuService.createMenuVoice(selectedLead.status.code, getPltUserRole(u)).map((voice) => {
            const item: MenuItem = {
              label: PltStatusLabels[voice],
              icon: StatusIcon[voice],
              command: (event) => {
                if (!event.item.label) {
                  return;
                }
                const status = getEnumKeyByEnumValue(PltStatusLabels, event.item.label);
                //quando fisso effettivamente un appuntamento
                if (status.includes(PltLeadStatusCode.OK_SOPRALLUOGO)) {
                  this.dialog
                    .open(CreateEventLeadModalComponent, {
                      data: {
                        lead: selectedLead,
                        code: PltLeadStatusCode.OK_SOPRALLUOGO,
                        type: PltEventType.SOPRALLUOGO,
                      },
                    })
                    .afterClosed()
                    .subscribe(() => this.load());
                } else {
                  this.dialog
                    .open(MoveLeadStateComponent, {
                      data: {
                        lead: selectedLead,
                        code: status,
                      },
                    })
                    .afterClosed()
                    .subscribe(() => this.load());
                }
              },
            };
            return item;
          }),
        ),
      )
      .subscribe((items) => {
        this.cmItems = items;
        this.cmItems.push({
          separator: true,
        });
        this.cmItems.push({
          label: 'Dettaglio',
          icon: 'pi pi-check-circle',
          command: () => {
            this.router.navigateByUrl(`flows/details/upsert?id=${selectedLead._id}`);
          },
        });
        if (selectedLead.status?.metadata?.opportunityRef) {
          this.cmItems.push({
            label: 'Vai a Opportunità',
            icon: 'pi pi-check-circle',
            command: () => {
              this.router.navigateByUrl(`opportunity/details/upsert?id=${selectedLead.status.metadata.opportunityRef}`);
            },
          });
        }
      });
  }
  downloadCsv() {
    return this.pltFlowService
      .browseForCsv({
        offset: this.offset,
        limit: this.limit,
        filter: this.filter,
        sort: [
          {
            by: '_id',
            order: -1,
          },
        ],
      })
      .subscribe();
  }
  rangeChange(event: { start: Date; end: Date }) {
    this.filter.createdAt = event;
    this.load();
  }
  onRowSelect(event) {
    this.selectedLead = event.data;
    this.buildMenu(this.selectedLead);
  }
  onRowUnselect() {
    this.selectedLead = null;
  }
}
