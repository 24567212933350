import {
  AfterContentInit,
  Component,
  ContentChild,
  ContentChildren,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { IPaginateRequest } from '@renovars/common';
import { MenuItem, LazyLoadEvent } from 'primeng/api';
import { Table } from 'primeng/table';
import { DatatableHeaderDirective, DatatableBodyDirective, DatatableCellDirective } from './datatable.directive';
import { Subscription } from 'rxjs';

@Component({
  selector: 'fullstack-datatable',
  templateUrl: './datatable.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: [`./datatable.component.scss`],
})
export class DatatableComponent implements OnInit, AfterContentInit, OnDestroy {
  @ContentChild(DatatableHeaderDirective)
  headerTemplate: DatatableHeaderDirective;
  @ContentChild(DatatableBodyDirective) bodyTemplate: DatatableBodyDirective;
  @ContentChildren(DatatableCellDirective)
  myCells: QueryList<DatatableCellDirective>;

  @ViewChild('table') table: Table;

  @Input() globalFilterFields;
  @Input() options: { singleElem: boolean; lazy: boolean };
  @Input() paginator = true;
  @Input() list: Array<any>;
  @Input() selection: any;
  @Input() cmItems: MenuItem[];
  @Input() totalRecords: number;
  @Input() maxRows = 10;
  @Input() first = 0;
  @Input() sortField;
  @Input() sortOrder;
  @Input() scrollHeight = '65vh';
  @Input() paginateRequest: IPaginateRequest<any>;
  @Input() stileClass = 'p-datatable-sm';
  @Input() frozenColumns: string[] = [];
  @Output() selected = new EventEmitter();
  @Output() lazyLoad = new EventEmitter();
  @Input() clientExport = false;
  @Input() showColumnSelector = false;
  @Output() rowsChange: EventEmitter<number> = new EventEmitter<number>();
  @Input() set visibleColumns(visibleColumns: Array<string>) {
    this.setVisibleColumns(visibleColumns ?? []);
  }
  @Output() selectedColumnsChoosed: EventEmitter<Array<string>> = new EventEmitter<Array<string>>();
  @Output() rowSelect = new EventEmitter();
  @Output() rowUnselect = new EventEmitter();
  selectedRowIndex: number;
  selectedItem;
  _visibleColumns: Array<string> = [];
  availableColumns: Array<string> = [];
  cellsSub: Subscription;

  ngOnInit(): void {
    this.maxRows = this.maxRows || 10;
    this.totalRecords = 0;
  }

  ngAfterContentInit(): void {
    this.availableColumns = this.setAvailableColumns();
    this.setVisibleColumns(this._visibleColumns ?? []);
    this._visibleColumns = this._visibleColumns.length === 0 ? this.availableColumns : this._visibleColumns;
    this.cellsSub = this.myCells?.changes.subscribe(() => this.setVisibleCells());
  }

  selectRow(index) {
    this.selectedRowIndex = index;
  }

  onSelect(event) {
    this.selected.emit(event);
  }

  resetToFirstPage() {
    this.table.reset();
  }

  onLazyLoad(event: LazyLoadEvent) {
    if (this.paginateRequest) {
      this.paginateRequest.skip = event.first;
      this.paginateRequest.limit = event.rows;
      if (event.sortField) {
        this.paginateRequest.formOptions = {
          [event.sortField]: { order: event.sortOrder },
        };
      }
    }

    this.lazyLoad.emit(event);
  }

  isFrozen(header) {
    return this.frozenColumns.includes(header.field);
  }

  setVisibleCells() {
    this.myCells.forEach((cell) => {
      if (this._visibleColumns.length > 0 && !this._visibleColumns.includes(cell.name)) {
        cell.elRef.nativeElement.style.display = 'none';
      } else {
        cell.elRef.nativeElement.style.display = 'table-cell';
      }
    });
  }

  private setAvailableColumns() {
    return this.headerTemplate.columns.map((col) => col.name);
  }

  setVisibleColumns(columns: Array<string>) {
    this._visibleColumns = columns && columns.length > 0 ? columns : this.availableColumns;
    if (this.headerTemplate) {
      this.headerTemplate.columns = this.headerTemplate.columns.map((col) => ({
        ...col,
        visible: this._visibleColumns.includes(col.name),
      }));
      this.setVisibleCells();
    }
  }

  ngOnDestroy() {
    this.cellsSub.unsubscribe();
  }
  onRowSelect($event) {
    this.rowSelect.emit($event);
  }
  onRowUnselect($event) {
    this.rowUnselect.emit($event);
  }
}
