import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IPromotion } from '@renovars/common/plenitude';

@Component({
  selector: 'app-select-accessory-package-modal',
  templateUrl: './select-accessory-package-modal.component.html',
  styleUrls: ['./select-accessory-package-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class SelectAccessoryPackageModalComponent {
  accessoryPackages: IPromotion[] | undefined;

  selectedPackages: IPromotion[] | undefined;
  constructor(
    public dialogRef: MatDialogRef<SelectAccessoryPackageModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { selected: IPromotion[]; accessoryPackages: IPromotion[] },
  ) {}
  ngOnInit(): void {
    this.accessoryPackages = this.data.accessoryPackages;

    this.selectedPackages = this.data.selected;
  }
  confirm() {
    this.dialogRef.close({ selected: this.selectedPackages.map(r => { return { ...r, quantity: 1 } }) });
  }
}
