import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '../../../libs/fe/ui-ng/src/lib/ui.module';
import { DomainValuesModule } from '../../../libs/fe/core-ng/src/lib/features/domain-values';
import { PromotionsFormComponent } from './components/forms/promotions-form.component';
import { PromotionsDetailComponent } from './components/pages/detail/promotions-detail.component';
import { PromotionsListComponent } from './components/pages/list/promotions-list.component';
import { PromotionsPageComponent } from './components/pages/promotions-page.component';
import { PromotionsService } from './promotions.service';
import { PickListModule } from 'primeng/picklist';

const ROUTES: Routes = [
  {
    path: '',
    component: PromotionsPageComponent,
    children: [
      { path: '', component: PromotionsListComponent },
      { path: 'detail', component: PromotionsDetailComponent },
    ],
  },
];

@NgModule({
  declarations: [PromotionsPageComponent, PromotionsListComponent, PromotionsDetailComponent, PromotionsFormComponent],
  exports: [],
  imports: [
    UiModule,
    CommonModule,
    FormsModule,
    DomainValuesModule,
    ReactiveFormsModule,
    RouterModule.forChild(ROUTES),
    PickListModule,
  ],
  providers: [PromotionsService],
})
export class PromotionsModule {}
