import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'

@Component({
  selector: 'network-page',
  templateUrl: `./network-page.component.html`,
  encapsulation: ViewEncapsulation.None,
})
export class NetworkPageComponent implements OnInit {
  constructor(private dialog: MatDialog) {}

  ngOnInit() {}
}
