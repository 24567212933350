import { Component, ChangeDetectionStrategy, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { IToggleOption } from '@renovars/common';
import { FormElement } from '../core';

@Component({
  selector: 'renovars-input-toggle',
  templateUrl: './input-toggle.component.html',
  styleUrls: ['./input-toggle.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputToggleComponent),
      multi: true,
    },
  ],
})
export class InputToggleComponent extends FormElement {
  @Input() options: IToggleOption<any>[];
  value;
  formId = Math.random().toString(36).substring(5);
}
