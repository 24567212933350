import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomainValuesService } from '@renovars/fe-core-libs/services/domain-values.service';
import { map, mergeMap, switchMap, of, tap } from 'rxjs';
import { FilesService } from '@renovars/core-ng';
import { FileServerGroupType } from '@renovars/common';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'document-upload',
  templateUrl: './document-upload.component.html',
  styleUrls: ['./document-upload.component.scss'],
})
export class DocumentUploadComponent implements OnInit {
  idType = null;
  note: string = null;
  projectId: string = null;
  requiredState: string[] = [];
  docType = 'lead_documents_type';
  documentTypes$;

  constructor(
    public dialogRef: MatDialogRef<DocumentUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private domainValueService: DomainValuesService,
    private fileService: FilesService,
    private messageService: MessageService
  ) {}

  ngOnInit(): void {
    this.requiredState = this.data.requiredState;
    this.projectId = this.data.projectId;
    this.idType = this.data.idType;
    this.docType = this.data.docType;
    this.documentTypes$ = this.domainValueService
      .findByTypeWithMetadata(this.data.docType)
      .pipe(
        map((r) =>
          this.requiredState
            ? r.filter((d) => ((d.metadata as any)?.data?.requiredState || []).includes(this.requiredState))
            : r
        )
      );
  }

  close(value) {
    this.dialogRef.close(value);
  }

  uploadFile(files: FileList) {
    if (this.note && this.note.length >= 5) {
      this.fileService
        .uploadFiles(files)
        .pipe(
          switchMap((res) => of(...res)),
          mergeMap((id) =>
            this.fileService
              .update(id, {
                projectId: this.projectId,
                group: FileServerGroupType.LEAD_DOCUMENTS,
                type: this.idType,
                note: this.note,
              })
              .pipe(map(() => id))
          ),
          tap((id: string[]) => this.close(id[0]))
        )
        .subscribe();
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Devi inserire le note di almeno 5 caratteri',
      });
    }
  }
}
