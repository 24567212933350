import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fi/environment/environment';
import { IEstimate } from '@renovars/common/plenitude';

type EstimatesBrowseResponse = { items: IEstimate[]; total: number };

@Injectable()
export class EstimateService {
  constructor(private http: HttpClient) {}
  browse(filter: any) {
    return this.http.post<EstimatesBrowseResponse>(`${environment.apiGatewayURL}/estimates/browse`, { filter });
  }
  delete(id: string) {
    return this.http.delete<any>(`${environment.apiGatewayURL}/estimates/${id}`);
  }
  findById(id: string) {
    return this.http.get<IEstimate>(`${environment.apiGatewayURL}/estimates/${id}`);
  }
  save(dto: Omit<IEstimate, '_id'>) {
    return this.http.post<any>(`${environment.apiGatewayURL}/estimates`, dto);
  }
  update(id: string, dto) {
    return this.http.put<any>(`${environment.apiGatewayURL}/estimates/${id}`, dto);
  }

  export(id: string, isInstaller: boolean) {
    window.open(`${environment.apiGatewayURL}/estimates/export/${id}?isInstaller=${isInstaller}`);
  }

  exportContract(id: string) {
    window.open(`${environment.apiGatewayURL}/estimates/exportContract/${id}`);
  }

  exportBase64(id: string, isInstaller: boolean) {
    return this.http.get<{data: string}>(`${environment.apiGatewayURL}/estimates/exportBase64/${id}?isInstaller=${isInstaller}`);
  }
}
