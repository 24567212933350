import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductsService } from '../../../products.service';
import { IProduct } from '@renovars/common/plenitude';

@Component({
  selector: 'products-detail',
  template: `
    <ng-template header-body></ng-template>
    <div class="content">
      <form [formGroup]="form" (submit)="save()" novalidate>
        <products-form formControlName="productForm"></products-form>
        <div class="text-end mt-2">
          <button type="submit" class="btn btn-secondary rounded">Salva</button>
        </div>
      </form>
    </div>
  `,
})
export class ProductsDetailComponent implements OnInit {
  id;
  form = this.fb.group({
    productForm: this.fb.control(null, []),
  });

  constructor(
    protected fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private productApi: ProductsService,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((p) => {
      this.id = p.id;

      if (this.id) {
        this.productApi.findById(this.id).subscribe((res) => {
          this.form.get('productForm').patchValue(res);
        });
      }
    });
  }

  save() {
    if (this.form.valid) {
      const obj: IProduct = this.form.get('productForm').value;
      if (this.id) {
        this.productApi.update(this.id, obj).subscribe(() => this.router.navigate(['/settings/products']));
      } else {
        const { _id, ...rest } = obj;
        this.productApi.save(rest).subscribe(() => this.router.navigate(['/settings/products']));
      }
    }
  }
}
