<h2 mat-dialog-title>Dettaglio preventivo</h2>
<mat-dialog-content class="mat-typography content-estimate">
  <app-estimate-form
    [formControl]="formEstimate"
    [selectedAccessoryPromotions]="accessoryPromotions"
    [selectedMainPromotion]="mainPromotions[0]"
    [opportunity]="data.opportunity"
  ></app-estimate-form>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Chiudi</button>
  <button
    mat-button
    [disabled]="!formEstimate.valid || data?.estimate.state == 3 || data?.estimate.state == 4"
    (click)="save()"
    cdkFocusInitial
  >
    Modifica
  </button>
</mat-dialog-actions>
