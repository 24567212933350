import { Component, forwardRef, Input, NgZone, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import type { IAddress } from '@renovars/common';
import { interval, Subject } from 'rxjs';
import { debounce, mergeMap } from 'rxjs/operators';
import { GmapsService } from '../core/services/gmaps.service';
import { FormElement } from '../core/utils/form-element';
@Component({
  selector: 'fullstack-input-address-box',
  templateUrl: './input-address-box.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    /* { provide: NG_VALIDATORS, useExisting: InputAddressBoxComponent, multi: true }, */
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputAddressBoxComponent),
      multi: true,
    },
  ],
})
export class InputAddressBoxComponent extends FormElement implements OnInit {
  @Input() options: {
    label: String;
    placeholder: String;
    labelField: String;
    filtering: string;
  };
  @Input() withRadius = false;
  @Input() error: string = null;
  @Input() withLocality = false;

  list;
  isActive;
  subject: Subject<any> = new Subject();
  radius = 0;
  locality: string;
  isDisabled = false;

  constructor(private gmapsService: GmapsService, private _ngZone: NgZone, private dialog: MatDialog) {
    super();
  }

  ngOnInit(): void {
    this.subject
      .pipe(
        debounce(() => interval(1000)),
        mergeMap((searchText) => this.gmapsService.geocoding(searchText)),
      )
      .subscribe((res) => {
        this._ngZone.run(() => {
          this.list = [].concat(res);
          this.isActive = true;
        });
      });
  }

  onAddressChange(event) {
    if (event) {
      this.subject.next(event);
    } else {
      this._onChange(null);
    }
  }

  onRadiusChange(event) {
    if (this.value) {
      this.value = { ...this.value, radius: this.radius };
      this._onChange(this.value);
    }
  }

  onSelect(address: IAddress) {
    this.value = address;
    this.value.radius = this.radius ? this.radius : 0;
    this.isActive = false;
    this.locality = address.locality;
    this._onChange(this.value);
  }

  writeValue(obj: IAddress) {
    this.value = obj;
    this.locality = this.value?.locality;
    this.radius = obj && obj.radius ? obj.radius : null;
  }

  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }


  /* validate(control: AbstractControl): ValidationErrors {
    let check;
    if (this.list && this.value) {
      check = this.list.find((l) => l.formatted_address == this.value.formatted_address);
    }

    return { required: true };
  } */
}
