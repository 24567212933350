import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Opportinity, PltOpportunityStatusCode, PltStatusLabels } from '@renovars/common/plenitude';
import { OpportunityApiService } from '../opportunity-api.service';

@Component({
  selector: 'opportunity-move-state',
  template: `
    <h2 mat-dialog-title>
      Cambia stato dell'opportunità in:
      <span class="capitalize font-bold text-amber-400">{{ codeLabel[currentCode] }}</span>
    </h2>

    <mat-dialog-content class="mat-typography">
      <form #statusForm="ngForm">
        <div class="row">
          <div class="col-12">
            <fullstack-input-textarea [options]="{ label: 'Note', rows: 2 }" [(ngModel)]="note" name="note">
            </fullstack-input-textarea>
          </div>
        </div>
      </form>
    </mat-dialog-content>

    <mat-dialog-actions align="end">
      <button mat-button (click)="annulla()" >Annulla</button>

      <button mat-raised-button color="primary" (click)="save()">Salva</button>
    </mat-dialog-actions>
  `,
  styles: [],
})
export class OpportunityMoveStateComponent implements OnInit {
  currentCode: PltOpportunityStatusCode;
  StatusCodes: PltOpportunityStatusCode;
  codeLabel = PltStatusLabels;
  opportunity: Opportinity;
  note: string;
  constructor(
    public dialogRef: MatDialogRef<OpportunityMoveStateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { code: PltOpportunityStatusCode; opportunity: Opportinity },
    private flowService: OpportunityApiService,
  ) {}
  ngOnInit(): void {
    this.currentCode = this.data.code;
    this.opportunity = this.data.opportunity;
  }
  save() {
    this.flowService
      .moveState(this.opportunity._id, { code: this.currentCode, metadata: { note: this.note } })
      .subscribe(() => this.dialogRef.close(null));
  }
  close(event) {
    this.dialogRef.close(event);
  }
  annulla() {
    this.dialogRef.close(null);
  }
}
