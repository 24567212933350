import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { OpportunityApiService } from '@fi/app/features/plt-opportunity/opportunity-api.service';
import { IContact } from '@renovars/common';
import { Opportinity } from '@renovars/common/plenitude';
import { AuthServices, ContactsServices, validateBillingContact } from '@renovars/core-ng';
import { ConfirmModalComponent } from '@renovars/ui-ng';
import { MessageService } from 'primeng/api';
import { Observable, filter, pluck, switchMap, tap } from 'rxjs';

@Component({
  selector: 'buyer',
  templateUrl: './buyer.component.html',
  styleUrls: ['./buyer.component.scss'],
})
export class BuyerComponent implements OnInit {
  contacts$: Observable<IContact[]>;

  contactForm = this.fb.group({
    contact: new UntypedFormControl(null),
  });
  newContactForm = this.fb.group({
    contact: new UntypedFormControl(null),
  });

  selectedContact = null;
  isNewContact = false;
  roles = [];
  editMode = false;

  projectId: null;
  project: Opportinity;

  useLeadContact = false;

  constructor(
    protected contactsService: ContactsServices,
    protected activatedRoute: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    protected dialog: MatDialog,
    protected authService: AuthServices,
    private opportunityApi: OpportunityApiService,
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(
        tap((p) => {
          this.projectId = p.id;
        }),
        switchMap(() => this.authService.user()),
        tap((res) => (this.roles = res.roles)),
        switchMap(() => this.opportunityApi.getById(this.projectId)),
      )
      .subscribe((project) => {
        this.project = project;

        if (project.buyer) {
          this.editMode = true;
          this.contactsService.getContact(project.buyer).subscribe((contact) => {
            this.selectedContact = contact;
            this.contactForm.patchValue({ contact });
          });
        } else {
          this.dialog
            .open(ConfirmModalComponent, {
              data: {
                text: `Vuoi usare il contatto dell'opportunità ?`,
              },
            })
            .afterClosed()
            .pipe(filter((res) => res && res.confirmed))
            .subscribe((res) => {
              if (res) {
                this.contactsService.getContact(project.contact).subscribe((contact) => {
                  this.useLeadContact = true;
                  this.selectedContact = contact;
                  this.contactForm.patchValue({ contact });
                });
              }
            });
        }
      });
  }

  onSetContact(event) {
    this.isNewContact = false;
    this.selectedContact = event;
    this.contactForm.patchValue({ contact: event });
  }

  searchContact(searchString) {
    this.selectedContact = null;
    this.contacts$ = this.contactsService.searchByNominative(searchString).pipe(pluck('data'));
  }

  newContact() {
    this.isNewContact = true;
    this.selectedContact = null;
    this.newContactForm = this.fb.group({
      contact: new UntypedFormControl(null),
    });
  }

  save() {
    let contact: IContact = this.contactForm.getRawValue().contact;
    if (this.isNewContact) {
      contact = this.newContactForm.getRawValue().contact;
    } else {
      contact._id = contact['id'];
    }
    const validator = validateBillingContact(contact);
    if (true) {//validator.isValid) {
      this.opportunityApi.updateBuyer(this.projectId, contact).subscribe((res) => {
        console.log(res);
      });
    } else {
      this.messageService.add({
        severity: 'error',
        summary: 'Dati incompleti',
        detail: validator.reasons.join('\n'),
      });
    }
  }

  useAnotherContact() {
    this.useLeadContact = false;
    this.selectedContact = null;
  }

  get canEdit() {
    return true;
  }
}
