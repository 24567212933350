<div class="row">
  <div class="col-8">
    <fullstack-fullcalendar
      (viewChange)="viewChange($event)"
      (dateClick)="onDateClick($event)"
      [options]="{ context: 'MODAL', fromToday: true }"
    >
      <div *ngFor="let e of list" f-event [event]="getEvent(e)"></div>
      <div
        *ngFor="let a of selectedUserAvailability"
        f-event
        [event]="{
          title: 'Slot disponibile',
          daysOfWeek: [a.day],
          startTime: a.start.toString().padStart(2, '0') + ':00:00',
          endTime: a.end.toString().padStart(2, '0') + ':00:00',
          color: 'orange'
        }"
      ></div>
    </fullstack-fullcalendar>
  </div>
  <div class="col-4">
    <div class="row mt-1">
      <fullstack-input-select-box
        class="mt-1"
        [options]="{ label: 'Seleziona un utente' }"
        [ngModel]="paginateRequest.form.userId"
        (ngModelChange)="searchByUser($event)"
        [error]="!paginateRequest.form.userId ? 'Utente obbligatorio' : null"
      >
        <sb-option [key]="'id'" [value]="i" *ngFor="let i of storeManagers$ | async">{{
          i.fullName || i.firstName + ' ' + i.lastName
        }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div class="row mt-1">
      <fullstack-input-address-box
        class="mt-1"
        [options]="{ label: 'Inserisci il luogo dell\'appuntamento' }"
        [ngModel]="address"
        (ngModelChange)="searchByAddress($event)"
        [error]="!address ? 'Indirizzo obbligatorio' : null"
      >
      </fullstack-input-address-box>
    </div>
    <div class="row mt-1">
      <fullstack-input-box
        class="mt-1"
        [options]="{ label: 'link' }"
        [ngModel]="connectionInfo"
        (ngModelChange)="changeInfo($event)"
        *ngIf="medium === 1"
        [error]="medium === 1 && !connectionInfo ? 'Link obbligatorio' : null"
      >
      </fullstack-input-box>
    </div>
  </div>
</div>
