<div class="flex flex-col h-screen bg-white">
  <div class="grow">
    <form [formGroup]="form" class="flex flex-col gap-4 font-sans">
      <div
        class="rounded-lg p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-col gap-2"
      >
        <div class="flex justify-between">
          <div>
            <div class="gap-2">
              <h5 class="font-medium">Codice:</h5>
              <h5 class="font-mono font-semibold">{{ form.get('name').value }}</h5>
            </div>
          </div>
          <div>
            <h5 class="font-medium text-grey-600">Intestatario preventivo:</h5>
            <h5 class="font-semibold">
              {{ opportunity.cache.buyer | getFirstName }} {{ opportunity.cache.buyer | getLastName }}
            </h5>
            <h6 class="font-semibold">
              {{ opportunity.cache.buyer | getPhone }}
            </h6>
            <h6 class="font-semibold">
              {{ opportunity.cache.buyer | getEmail }}
            </h6>
            <h6 class="font-semibold">
              {{ opportunity?.cache?.lead?.addressOp?.formatted_address }}
            </h6>
          </div>
        </div>
      </div>

      <div
        class="rounded-lg p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-col gap-2 h-64 overflow-auto"
      >
        <div class="flex justify-between">
          <h6 class="font-medium">Pacchetto principale</h6>
          <button mat-raised-button color="primary" type="button" (click)="addPrimaryPackage()">
            <mat-icon>add</mat-icon>Aggiungi pacchetto
          </button>
        </div>

        <p-table
          #mainDt
          [globalFilterFields]="['name', 'description']"
          [value]="[selectedMainPromotion]"
          dataKey="_id"
          [tableStyle]="{ 'min-width': '50rem' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th>
                Nome
                <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
              </th>
              <th>
                Descrizione
                <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
              </th>
              <th>Costo pacchetto</th>
              <th>Quantità</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
            <tr>
              <td>{{ row?.name }}</td>
              <td>{{ row?.description }}</td>
              <td>
                {{
                  (row?.addOnCost || 0) +
                    (row?.installationCost || 0) +
                    (row?.materialsCost || 0) +
                    (row?.marketingCost || 0) +
                    (row?.marginality || 0) +
                    (row?.callcenterCost || 0)
                }}
              </td>
              <td>{{ row?.quantity }}</td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">Nessun pacchetto primario selezionato.</td>
            </tr>
          </ng-template>
        </p-table>

        <h6 class="font-medium flex justify-end">
          Totale pacchetto principale:
          {{ mainTotal || 0 | currency: 'EUR' }}
        </h6>
      </div>

      <div
        class="rounded-lg p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-col gap-2 h-72 overflow-auto"
      >
        <div class="flex justify-between">
          <h6 class="font-medium">Pacchetti accessori</h6>
          <button mat-raised-button color="primary" type="button" (click)="addAccessoryPackages()">
            <mat-icon>add</mat-icon>Aggiungi pacchetti
          </button>
        </div>

        <p-table
          #accessoriesDt
          [globalFilterFields]="['name', 'description']"
          [value]="selectedAccessoryPromotions"
          dataKey="_id"
          [tableStyle]="{ 'min-width': '50rem' }"
        >
          <ng-template pTemplate="caption">
            <div class="flex">
              <span class="p-input-icon-left ml-auto">
                <i class="pi pi-search"></i>
                <input
                  pInputText
                  type="text"
                  (input)="accessoriesDt.filterGlobal($event.target.value, 'contains')"
                  placeholder="Cerca pacchetto"
                />
              </span>
            </div>
          </ng-template>

          <ng-template pTemplate="header">
            <tr>
              <th>
                Nome
                <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
              </th>
              <th>
                Descrizione
                <p-columnFilter type="text" field="description" display="menu"></p-columnFilter>
              </th>
              <th>Costo pacchetto</th>
              <th>Quantità</th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-row>
            <tr>
              <td>{{ row?.name }}</td>
              <td>{{ row?.description }}</td>
              <td>
                {{
                  (row?.addOnCost || 0) +
                    (row?.installationCost || 0) +
                    (row?.materialsCost || 0) +
                    (row?.marketingCost || 0) +
                    (row?.callcenterCost || 0) +
                    (row?.marginality || 0)
                }}
              </td>
              <td [pEditableColumn]="row.quantity" pEditableColumnField="quantity">
                <p-cellEditor>
                  <ng-template pTemplate="input">
                    <input
                      pInputText
                      min="1"
                      type="number"
                      [(ngModel)]="row.quantity"
                      [ngModelOptions]="{ standalone: true }"
                      (change)="changeAccessoryPromotions()"
                    />
                  </ng-template>
                  <ng-template pTemplate="output">
                    {{ row.quantity || 1 | number }}
                  </ng-template>
                </p-cellEditor>
              </td>
            </tr>
          </ng-template>
          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="7">Nessun pacchetto accessorio selezionato.</td>
            </tr>
          </ng-template>
        </p-table>

        <h6 class="font-medium flex justify-end">Totale pacchetti accessori: {{ accessoryTotal | currency: 'EUR' }}</h6>
      </div>

      <div
        class="rounded-lg p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)] flex flex-col gap-2 mb-8"
      >
        <h6 class="font-medium">Seleziona modalità di pagamento</h6>
        <fullstack-input-select-box
          formControlName="financing"
          [options]="{ label: 'Metodo di pagamento:' }"
          [isSelectDisabled]="true"
        >
          <sb-option *ngFor="let val of paymentsTypes" [value]="val">{{ paymentsLabels[val] }}</sb-option>
        </fullstack-input-select-box>
        <fullstack-input-box
          *ngIf="form.get('financing').value == 'PAGAMENTO_UNICO'"
          [type]="'percent'"
          [options]="{ label: 'Percentuale di sconto' }"
          formControlName="discountPercent"
          (ngModelChange)="calculateTotal()"
        ></fullstack-input-box>
      </div>
    </form>
  </div>
  <div class="sticky bottom-0 z-50 p-6 shadow-[0px_-4px_15px_0px_#00000024] bg-gray-100">
    <div class="flex justify-between">
      <mat-slide-toggle class="float-right" [(ngModel)]="isSpyMode">Mostra dettaglio costi</mat-slide-toggle>

      <div>
        <h4 class="font-medium flex justify-end">Totale: {{ promotionsTotal | currency: 'EUR' }}</h4>

        <div *ngIf="isSpyMode">
          <hr />

          <h4 class="font-medium flex justify-end">Totale materiali: {{ materialsTotal | currency: 'EUR' }}</h4>
          <h4 class="font-medium flex justify-end">Totale installazione: {{ installationTotal | currency: 'EUR' }}</h4>
          <h4 class="font-medium flex justify-end">Totale marketing: {{ marketingTotal | currency: 'EUR' }}</h4>
          <h4 class="font-medium flex justify-end">Totale callcenter: {{ callcenterTotal | currency: 'EUR' }}</h4>
          <h4 class="font-medium flex justify-end">Totale marginalità: {{ marginalityTotal | currency: 'EUR' }}</h4>
          <hr />
        </div>
        <h4 class="font-medium flex justify-end mt-2" *ngIf="form.get('financing').value == 'PAGAMENTO_UNICO'">
          Sconto applicato:
          {{ (promotionsTotal * form.get('discountPercent').value) / 100 | currency: 'EUR' }}
        </h4>
        <h4 class="font-medium flex justify-end mt-2" *ngIf="form.get('financing').value == 'PAGAMENTO_UNICO'">
          Totale scontato:
          {{ promotionsTotal - (promotionsTotal * form.get('discountPercent').value) / 100 | currency: 'EUR' }}
        </h4>
      </div>
    </div>
  </div>
</div>
