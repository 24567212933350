import { NgModule } from '@angular/core';
import { MatListModule } from '@angular/material/list';
import { ContactModule } from '@renovars/core-ng';
import { TimelineModule } from 'primeng/timeline';
import { PipesModule } from '../pipes/pipes.module';
import { SharedModule } from '../shared.module';
import { BuyerComponent } from './buyer/buyer.component';
import { DocumentManagerComponent } from './document-manager/document-manager.component';
import { DocumentUploadComponent } from './document-upload/document-upload.component';
import { EstimatesComponent } from './estimates/estimates.component';
import { HistoryComponent } from './history.component';
import { PrivacyConsentsComponent } from './privacy-consents/privacy-consents.component';

const DECLARATIONS = [
  DocumentManagerComponent,
  DocumentUploadComponent,
  HistoryComponent,
  PrivacyConsentsComponent,
  EstimatesComponent,
  BuyerComponent,
];

@NgModule({
  declarations: DECLARATIONS,
  imports: [SharedModule, MatListModule, TimelineModule, PipesModule, ContactModule],
  providers: [],
  exports: [...DECLARATIONS],
})
export class SharedComponentsModule {}
