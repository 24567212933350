import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { concatAll, finalize, forkJoin, Observable } from 'rxjs';
import { IFileServerModel } from '../../../../../shared/common/src';
import { AppConfigService } from '../features';

@Injectable()
export class FilesService {
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService,
    private messageService: MessageService,
  ) {}

  download(fileId) {
    window.open(`${this.appConfigService.getApiProxiedURL()}/file-server/download/${fileId}`);
  }

  downloadAsAttachment(fileId, preview?: boolean) {
    window.open(`${this.appConfigService.getApiProxiedURL()}/file-server/attachment/${fileId}`);
  }
  downloadMultiple(fileIds: string[]) {
    const qs = fileIds.map((id) => `ids=${id}`).join('&');
    window.open(`${this.appConfigService.getApiProxiedURL()}/file-server/attachment/multiple/files?${qs}`);
  }

  search(metadata: any): Observable<any[]> {
    return this.http.post<any>(`${this.appConfigService.getApiProxiedURL()}/file-server/search`, metadata);
  }

  searchById(fileId: any): Observable<any> {
    return this.http.post<any>(`${this.appConfigService.getApiProxiedURL()}/file-server/searchById`, {
      fileId,
    });
  }

  uploadFile(file: File) {
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);
    return this.http.post<string[]>(`${this.appConfigService.getApiProxiedURL()}/file-server/`, formData);
  }

  uploadFiles(files: FileList) {
    const upload$: Observable<any>[] = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const formData: FormData = new FormData();
      formData.append('files', file, file.name);
      upload$.push(this.http.post(`${this.appConfigService.getApiProxiedURL()}/file-server/`, formData));
    }
    return forkJoin(upload$);
  }

  update(fileId, obj: any) {
    return this.http.put<any>(`${this.appConfigService.getApiProxiedURL()}/file-server/${fileId}`, obj);
  }

  delete(fileId) {
    return this.http.delete(`${this.appConfigService.getApiProxiedURL()}/file-server/${fileId}`);
  }

  sortBulk(filesWithPosition: any): Observable<IFileServerModel[]> {
    return this.http.post<IFileServerModel[]>(
      `${this.appConfigService.getApiProxiedURL()}/file-server/sort-bulk`,
      filesWithPosition,
    );
  }
}
