import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileServerGroupType } from '@renovars/common';
import { PltDomainValueType } from '@renovars/common/plenitude';
import { FilesService } from '@renovars/core-ng';
import { map, mergeMap, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-insert-signature-modal',
  templateUrl: './insert-signature-modal.component.html',
  styleUrls: ['./insert-signature-modal.component.scss'],
})
export class InsertSignatureModalComponent {
  signBlob
  projectId: string;
  estimateId: string;
  canSign = false;
  type = 40 //40 preventivo, 41 contratto
  constructor(
    public dialogRef: MatDialogRef<InsertSignatureModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {projectId: string, estimateId: string, type: number},
    private fileService: FilesService,

  ) {
    this.estimateId = this.data.estimateId;
    this.projectId = this.data.projectId;
    this.type = this.data.type || 40;

    this.fileService.search({
      projectId: this.projectId,
      estimateId: this.estimateId,
      type: this.type
    }).subscribe( res => {
      if(res.length === 0) {
        this.canSign = true;
      }
    })
  }

  signFile(blob) {
    this.signBlob = blob;
    this.save();
  }

  save() {
    let file = new File([this.signBlob], `sign-${this.estimateId}.png`, { type: 'image/png' });
    this.fileService.uploadFile(file).pipe(
      switchMap((res) => of(...res)),
      mergeMap((id) =>
        this.fileService
          .update(id, {
            projectId: this.projectId,
            estimateId: this.estimateId,
            group: FileServerGroupType.LEAD_DOCUMENTS,
            type: this.type,
            note: '',
          })
          .pipe(map(() => id))
      )).subscribe( _=> this.dialogRef.close())
  }
}
