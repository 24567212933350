import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LeadRoutingModule } from './lead-routing.module';
import { LeadListComponent } from './component/lead-list.component';
import { SharedModule } from '../../shared.module';
import { ContactModule, FlowClientModule } from '@renovars/core-ng';
import { LeadUpsertFormComponent } from './component/lead-upsert-form.component';
import { LeadApiService } from './lead-api';
import { CreateEventLeadModalComponent } from './modals/create-event-lead-modal.component';
import { MoveLeadStateComponent } from './modals/move-lead-state.component';
import { AssignLeadModalComponent } from './modals/assign-lead-modal.component';
import { LeadTabComponent } from './component/lead-tab.component';
import { SharedComponentsModule } from '../../components/components.module';
import { CreateOpportunityComponent } from './modals/create-opportunity.component';
import { PipesModule } from '../../pipes/pipes.module';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FormsModule } from '../../forms/forms.module';
import {
  PltLeadStatusCode,
  PltRoles,
  PltStatusLabels,
  ccOperatorAction,
  leadAdminAction,
} from '@renovars/common/plenitude';
import { ModalsModule } from '@fi/app/modals/modals.module';
import { MenuModule } from 'primeng/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { LeadProfileComponent } from './component/lead-profile.component';
@NgModule({
  declarations: [
    LeadListComponent,
    LeadUpsertFormComponent,
    CreateEventLeadModalComponent,
    MoveLeadStateComponent,
    AssignLeadModalComponent,
    LeadTabComponent,
    CreateOpportunityComponent,
    LeadProfileComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    LeadRoutingModule,
    ContactModule,
    FlowClientModule.register<typeof PltRoles, typeof PltLeadStatusCode, typeof PltStatusLabels>({
      labels: PltStatusLabels,
      sd: {
        is_admin: leadAdminAction,
        is_cc_operator: ccOperatorAction,
        is_technical_coordinator: {},
        is_installer: {},
        is_customer_satisfaction: {},
      },
    }),
    SharedComponentsModule,
    PipesModule,
    RadioButtonModule,
    FormsModule,
    ModalsModule,
    MenuModule,
    MatButtonModule,
    MatMenuModule,
  ],
  providers: [LeadApiService],
})
export class LeadModule {}
