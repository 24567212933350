import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BooleanAnswer, BooleanAnswerEnum } from '@renovars/common/facileimmobiliare';
import { FormElement } from '../core';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface ButtonOption {
  name: string;
  value: BooleanAnswer;
}

@Component({
  selector: 'fullstack-input-select-button',
  templateUrl: './input-select-button.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputSelectButtonComponent),
      multi: true,
    },
  ],
  styles: [
    `
          .p-button {
            padding: 0.3rem 1rem;
          }
        `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class InputSelectButtonComponent extends FormElement implements OnInit {
  @Input() options: { label: string; standardBoolean?: boolean };

  buttonOptions: ButtonOption[];

  constructor() {
    super();
  }

  ngOnInit(): void {
    const answers = [];
    answers.push(
      { name: 'Sí', value: true },
      { name: 'No', value: false },
      { name: 'n.d.', value: BooleanAnswerEnum.ND }
    );

    //IF YOU WANT A STANDARD BOOLEAN INPUT BOX
    if (this.options?.standardBoolean) {
      answers.splice(-1);
    }

    this.buttonOptions = answers;
  }
}
