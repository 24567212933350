export interface IPrivacy {
  marketing?: boolean;
  commercial?: boolean;
  profile?: boolean;
  otherSub?: boolean;

  all?: boolean;
  upselling?: boolean;
  source?: string;

  lastRequestedAt?: Date;
  lastRespondedAt?: Date;
  isHidden?: boolean;
  note?: string;
  surveyToken?: string;
  state?: PrivacyState;
}

export enum PrivacyState {
  'regolare' = 1,
  'negata' = 2,
  'in_attesa' = 3,
  'assente' = 4,
  'scaduta' = 5,
}

export const privacyStateLabels = [
  { label: 'Regolare', value: PrivacyState.regolare, color: 'green' },
  { label: 'Assente', value: PrivacyState.assente, color: 'orange' },
  { label: 'Negata', value: PrivacyState.negata, color: 'red' },
  { label: 'In attesa', value: PrivacyState.in_attesa, color: 'yellow' },
  { label: 'Scaduta', value: PrivacyState.scaduta, color: 'red' },
];
