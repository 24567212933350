<h2 mat-dialog-title></h2>
<mat-dialog-content class="mat-typography">
  <app-sign-surface *ngIf="canSign; else cantSign" (signed)="signFile($event)"></app-sign-surface>
  <ng-template #cantSign>
    <h4>Il {{ type == 40 ? 'preventivo' : 'contratto' }} è già stato firmato</h4>
    <p>Le firme dei {{ type == 40 ? 'preventivi' : 'contratti' }} sono nella sezione documenti</p>
  </ng-template>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Chiudi</button>
</mat-dialog-actions>
