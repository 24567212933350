import { Component, forwardRef, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IApplication, IRegistration } from '@renovars/common';

@Component({
  selector: 'fullstack-modal-input-registrazione',
  template: `
    <h2 mat-dialog-title>Registrazione</h2>
    <div mat-dialog-content>
      <form [formGroup]="form">
        <div class="row">
          <div class="col-12">
            <!--<fullstack-input-select-box
              [options]="{ label: 'Applicazione', labelField: 'name', valueField: 'id' }"
              [list]="applications"
            ></fullstack-input-select-box>-->
            <fullstack-input-select-box [options]="{ label: 'Applicazione' }">
              <sb-option [key]="'id'" [value]="i" *ngFor="let i of applications">{{ i.name }}</sb-option>
            </fullstack-input-select-box>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <!--<fullstack-input-select-box
              [options]="{ label: 'Ruolo' }"
              [list]="['admin', 'agente']"
            ></fullstack-input-select-box>-->
            <fullstack-input-select-box [options]="{ label: 'Ruolo' }">
              <sb-option [value]="i" *ngFor="let i of ['admin', 'agente']">{{ i }}</sb-option>
            </fullstack-input-select-box>
          </div>
        </div>
      </form>
    </div>
    <div mat-dialog-actions>
      <button mat-button (click)="annulla()" class="btn btn-secondary rounded me-3">Annulla</button>
      <button mat-button cdkFocusInitial class="btn btn-primary rounded">Salva</button>
    </div>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ModalInputRegistrazioneComponent),
      multi: true,
    },
  ],
})
export class ModalInputRegistrazioneComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<ModalInputRegistrazioneComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { registrazione: IRegistration; applications: IApplication[] },
    private fb: UntypedFormBuilder
  ) {}

  applications: IApplication[];
  application: IApplication;

  form = this.fb.group({
    id: null,
    applicationId: null,
    insertInstant: null,
    lastLoginInstant: null,
    lastUpdateInstant: null,
    name: null,
    roles: null,
    usernameStatus: null,
    verified: true,
  });

  ngOnInit(): void {
    this.applications = this.data.applications;
  }

  annulla(): void {
    this.dialogRef.close();
  }
}
