import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  ValidationErrors,
} from '@angular/forms';
import { ContactTypeEnum, IContactCompany, IContactIndividual } from '@renovars/common';
import { setFieldsProperties } from '@renovars/core-ng';

@Component({
  selector: 'fullstack-contact-form',
  template: `
    <fullstack-checkbox-button
      [options]="{ label: 'Tipo' }"
      [(ngModel)]="contactType"
      [disabled]="isDisabled"
      *ngIf="options.showCompany"
    >
      <checkbox [label]="'Persona Fisica'" [value]="ContactTypeEnum.INDIVIDUAL"></checkbox>
      <checkbox [label]="'Persona giuridica'" [value]="ContactTypeEnum.COMPANY"></checkbox>
    </fullstack-checkbox-button>

    <form [formGroup]="formIndividual" novalidate>
      <fullstack-contact-individual-form
        *ngIf="contactType === ContactTypeEnum.INDIVIDUAL"
        formControlName="contact"
        [formInfoState]="_formInfoState"
        [sources]="sources"
        [showSources]="options.showSources"
        [showBillingAddress]="options.showBillingAddress"
        [showMailType]="options.showMailType"
        [showPhoneType]="options.showPhoneType"
      >
      </fullstack-contact-individual-form>
    </form>
    <form [formGroup]="formCompany" novalidate>
      <fullstack-contact-company-form
        *ngIf="contactType === ContactTypeEnum.COMPANY"
        formControlName="contact"
        [formInfoState]="_formInfoState"
        [sources]="sources"
        [showSources]="options.showSources"
        [showMailType]="options.showMailType"
        [showPhoneType]="options.showPhoneType"
      >
      </fullstack-contact-company-form>
    </form>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ContactFormComponent),
      multi: true,
    },
  ],
})
export class ContactFormComponent implements ControlValueAccessor, OnInit {
  @Input() set formInfoState(formInfoState) {
    this._formInfoState = formInfoState;
    if (this.contactType === ContactTypeEnum.COMPANY) {
      setFieldsProperties(this._formInfoState, this.formCompany);
    }
    if (this.contactType === ContactTypeEnum.INDIVIDUAL) {
      setFieldsProperties(this._formInfoState, this.formIndividual);
    }
  }

  get formInfoState() {
    return this._formInfoState;
  }

  @Input() sources: string[];

  @Input() options = {
    showSources: true,
    showCompany: true,
    showBillingAddress: true,
    showPhoneType: true,
    showMailType: true,
  };

  contactType: string = ContactTypeEnum.INDIVIDUAL;
  ContactTypeEnum = ContactTypeEnum;
  isDisabled = false;

  _formInfoState: ValidationErrors = [];

  constructor(private fb: UntypedFormBuilder) {}

  formIndividual = this.fb.group({
    contact: new UntypedFormControl({ source: 'CV diretto' }, []),
  });

  formCompany = this.fb.group({
    contact: new UntypedFormControl({ source: 'CV diretto' }, []),
  });

  propagateChange: any = () => {};

  ngOnInit(): void {
    this.formIndividual.valueChanges.subscribe((val) => {
      this.propagateChange(val.contact);
    });
    this.formCompany.valueChanges.subscribe((val) => {
      this.propagateChange(val.contact);
    });
    // if (isEmpty(this.sources)) {
    //   this.sources = ['Sito', 'Email', 'Portale Allibo', 'Social', 'Cerved', 'Preventivi.it', 'Facile.it', 'CV diretto'];
    // }
  }

  /* Metodi per ReactiveForm */
  writeValue(value: IContactCompany | IContactIndividual) {
    if (value && value.type) {
      this.contactType = value.type;
      if (value.type === ContactTypeEnum.COMPANY) {
        this.formCompany.patchValue({ contact: value });
      }
      if (value.type === ContactTypeEnum.INDIVIDUAL) {
        this.formIndividual.patchValue({ contact: value });
      }
    }
  }

  registerOnChange(fn) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void): void {}

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
}
