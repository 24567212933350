import { AfterContentChecked, AfterContentInit, Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import type { ISite } from '@renovars/common';
import { FormElement } from '@renovars/ui-ng';
import { IFormBuilder, IFormGroup } from '@rxweb/types';
import { ISiteOptions, SITE_OPTIONS } from '../../site.module.options';

@Component({
  selector: 'facile-site-form',
  templateUrl: `./site-form.component.html`,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SiteFormComponent),
      multi: true,
    },
  ],
})
export class SiteFormComponent extends FormElement implements OnInit {
  form: IFormGroup<ISite>;
  fb: IFormBuilder = new UntypedFormBuilder();
  id: string;
  @Input() codes: { label: string; value: string }[];
  bcCodeVisible = true;
  phoneVisibile = true;
  areaVisibile = true;

  constructor(@Inject(SITE_OPTIONS) private options: ISiteOptions) {
    super();
    this.form = this.fb.group<ISite>({
      address: new UntypedFormControl(null, [Validators.required]),
      name: new UntypedFormControl(null, []),
      description: new UntypedFormControl(null, []),
      phone: new UntypedFormControl(null, []),
      bcCode: new UntypedFormControl(null, []),
      area: new UntypedFormControl(null, []),
      campaignCodes: new UntypedFormControl([], []),
    });
    this.bcCodeVisible = this.options?.exclude?.bcCode ? false : true;
    this.phoneVisibile = this.options?.exclude?.phone ? false : true;
    this.areaVisibile = this.options?.exclude?.area ? false : true;
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((v) => this._onChange(v));
  }
}
