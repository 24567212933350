<div class="card">
  <div class="action-buttons">
    <button mat-button (click)="accordion.openAll()">Espandi tutti</button>
    <button mat-button (click)="accordion.closeAll()">Comprimi tutti</button>
  </div>
  <mat-accordion multi>
    <mat-expansion-panel *ngFor="let t of documentStateTypes" hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <h6>{{ t.label }}</h6>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <section class="row-file" *ngFor="let filetype of getFilteredDocumentTypeByRequiredState(t.value)">
          <div class="d-flex justify-content-between">
            <div class="mb-2">
              <h6>
                {{ filetype.label }}
                <span *ngIf="filetype?.metadata?.data?.required == true">
                  <i class="fas fa-exclamation-circle me-2 required" title="Obbligatorio"></i
                ></span>
                <span *ngIf="filetype?.metadata?.data?.action" class="badge badge-secondary">{{
                  getActionlabel(filetype.metadata.data.action)
                }}</span>
              </h6>
              <p *ngIf="filetype?.metadata?.data?.note" class="text-muted">
                {{ filetype.metadata.data.note }}
              </p>
            </div>
            <div class="">
              <button mat-raised-button color="primary" (click)="uploadFile(t, filetype)">
                Carica documento
              </button>
            </div>
          </div>
          <div>
            <div class="files-by-type">
              <div
                *ngFor="let file of getFilteredFilesByCode(filetype.code)"
                class="d-flex justify-content-between"
              >
                <div>
                  <h7> {{ file.filename }}</h7>
                  <p class="text-muted">{{ file.createdAt | date: 'dd-MM-yyyy HH:mm' }}</p>
                  <p class="text-muted" *ngIf="file?.metadata?.note">{{ file.metadata.note }}</p>
                </div>
                <div class="action-buttons">
                  <div class="p-1 h4 text-center" style="width: 150px">
                    <button type="button" class="m-1" (click)="open(file.id)">
                      <i class="fas fa-external-link-alt"></i>
                    </button>
                    <button type="button" class="m-1" (click)="download(file.id)">
                      <i class="fas fa-download"></i>
                    </button>
                    <button type="button" class="m-1" (click)="delete(file.id)">
                      <i class="fa fa-times"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </section>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
