import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EstimateService } from '@fi/app/services/estimates.service';
import { IEstimate, IPromotion, Opportinity } from '@renovars/common/plenitude';

@Component({
  selector: 'app-update-estimate-modal',
  templateUrl: './update-estimate-modal.component.html',
  styleUrls: ['./update-estimate-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpdateEstimateModalComponent {
  formEstimate: FormControl = new FormControl();
  accessoryPromotions: (IPromotion & { quantity: number })[];
  mainPromotions: (IPromotion & { quantity: number })[];
  constructor(
    public dialogRef: MatDialogRef<UpdateEstimateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { estimate: IEstimate; opportunity: Opportinity },
    private estimateApi: EstimateService,
  ) {}
  ngOnInit(): void {
    const items = this.data.estimate.items;
    this.accessoryPromotions = (this.data.estimate.accessoryPromotions as IPromotion[]).map(r => {
      return {
        ...r,
        quantity: items.find(item => item.id === r._id)?.quantity
      } as (IPromotion & { quantity: number })
    });
    this.mainPromotions = (this.data.estimate.mainPromotions as IPromotion[]).map(r => {
      return {
        ...r,
        quantity: 1
      }
    });
    const estimate: Partial<IEstimate> = {
      ...this.data.estimate,
    }
    this.formEstimate.setValue({ ...estimate, mainPromotion: this.data.estimate.mainPromotions[0] });
  }

  save() {
    if (this.formEstimate.valid) {
      const estimate: Partial<IEstimate> = {
        accessoryPromotions: this.formEstimate.value?.accessoryPromotions.map(t => typeof t === 'string' ? t : t._id),
        mainPromotions: [typeof this.formEstimate.value?.mainPromotion === 'string' ? this.formEstimate.value?.mainPromotion : this.formEstimate.value?.mainPromotion._id],
        totalCost: this.formEstimate.value.totalCost,
        totalDiscounted: this.formEstimate.value.totalDiscounted,
        discountPercent: this.formEstimate.value.discountPercent,
        financing: this.formEstimate.value.financing,
        items: this.formEstimate.value?.items

      };

      this.estimateApi.update(this.data.estimate._id, estimate).subscribe();

      this.dialogRef.close(true);
    }
  }
}
