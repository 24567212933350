import { LeadFilter } from '@renovars/common/plenitude';

export const EmptyLeadFilter: LeadFilter = {
  assigned: '',
  createdBy: '',
  createdAt: null,
  'cache.contact.lastName': '',
  'cache.contact.firstName': '',
  'cache.contact.phone': '',
  'cache.addressOp.locality': '',
  'status.code': '',
  'status.user': '',
  'privacy.marketing': null,
  'privacy.commercial': null,
  'privacy.profile': null,
  'privacy.otherSub': null,
};
