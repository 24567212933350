import { Component, Input, OnInit, forwardRef } from '@angular/core';
import { FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PromotionsService } from '@fi/app/features/promotions/promotions.service';
import { SelectAccessoryPackageModalComponent } from '@fi/app/modals/select-accessory-package-modal/select-accessory-package-modal.component';
import { SelectPrimaryPackageModalComponent } from '@fi/app/modals/select-primary-package-modal/select-primary-package-modal.component';
import {
  EstimateItem,
  EstimateState,
  IProduct,
  IProductEstimate,
  IPromotion,
  Opportinity,
  PaymentsType,
  PaymentsTypeLabel,
  PromotionType,
} from '@renovars/common/plenitude';
import { FormElement } from '@renovars/ui-ng';
import { filter } from 'rxjs';

@Component({
  selector: 'app-estimate-form',
  templateUrl: './estimate-form.component.html',
  styleUrls: ['./estimate-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EstimateFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: EstimateFormComponent,
      multi: true,
    },
  ],
})
export class EstimateFormComponent extends FormElement implements OnInit {
  isSpyMode = false;
  allMainPromotions: IPromotion[];
  allAccessoryPromotions: IPromotion[];

  @Input() opportunity: Opportinity;
  @Input() selectedMainPromotion: IPromotion & { quantity: number };
  @Input() selectedAccessoryPromotions: (IPromotion & { quantity: number })[] = [];
  accessoryTotal: number = 0;
  mainTotal: number = 0;
  promotionsTotal: number = 0;

  materialsTotal: number = 0;
  installationTotal: number = 0;
  callcenterTotal: number = 0;
  marketingTotal: number = 0;
  marginalityTotal: number = 0;
  paymentsTypes = Object.keys(PaymentsType);
  paymentsLabels = PaymentsTypeLabel;

  form = new FormGroup({
    name: new FormControl('', [Validators.required]),
    mainPromotion: new FormControl('', [Validators.required]),
    accessoryPromotions: new FormControl(null),
    financing: new FormControl(null),
    state: new FormControl(EstimateState.DA_PRESENTARE),
    discountPercent: new FormControl(0),
    totalCost: new FormControl(0),
    totalDiscounted: new FormControl(0),
    items: new FormControl([]),
    createdAt: new FormControl(null),
  });

  constructor(
    private promotionApi: PromotionsService,
    private matDialog: MatDialog,
  ) {
    super();
  }

  ngOnInit(): void {
    this.promotionApi.browse(null).subscribe((allPromotions) => {
      this.allMainPromotions = allPromotions.items.filter((r) => r.type == PromotionType.PRINCIPALE);
      this.allAccessoryPromotions = allPromotions.items.filter((r) => r.type == PromotionType.ACCESSORIO);
    });
  }

  writeValue(obj: any): void {
    this.value = obj;
    if (this.form) {
      this.form.patchValue(this.value, { onlySelf: true });
    }
    this.changeMainPromotion();
    this.changeAccessoryPromotions();
  }

  changeMainPromotion() {
    this.mainTotal = 0;
    if (this.selectedMainPromotion) {
      this.mainTotal +=
        (this.selectedMainPromotion.addOnCost || 0) +
        (this.selectedMainPromotion.installationCost || 0) +
        (this.selectedMainPromotion.marketingCost || 0) +
        (this.selectedMainPromotion.callcenterCost || 0) +
        (this.selectedMainPromotion.marginality || 0) +
        (this.selectedMainPromotion.materialsCost || 0);
      this.form.patchValue({ mainPromotion: this.selectedMainPromotion._id });
    } else {
      this.form.patchValue({ mainPromotion: null });
    }

    this.calculateTotal();
  }

  changeAccessoryPromotions() {
    this.accessoryTotal = 0;
    this.selectedAccessoryPromotions.forEach(
      (r) =>
        (this.accessoryTotal +=
          ((r.addOnCost || 0) +
            (r.installationCost || 0) +
            (r.materialsCost || 0) +
            (r.marketingCost || 0) +
            (r.marginality || 0) +
            (r.callcenterCost || 0)) *
          r.quantity),
    );
    this.form.patchValue({ accessoryPromotions: this.selectedAccessoryPromotions.map((r) => r._id) });
    this.calculateTotal();
  }

  calculateTotal() {
    this.promotionsTotal = 0;

    this.materialsTotal = 0;
    this.installationTotal = 0;
    this.marketingTotal = 0;
    this.callcenterTotal = 0;
    this.marginalityTotal = 0;

    this.promotionsTotal = this.accessoryTotal + this.mainTotal;
    this.form.patchValue({ totalCost: this.promotionsTotal });
    this.form.patchValue({
      totalDiscounted: this.promotionsTotal - (this.promotionsTotal * this.form.get('discountPercent').value) / 100,
    });

    if (this.selectedMainPromotion) {
      this.materialsTotal += this.selectedMainPromotion.materialsCost || 0;
      this.installationTotal += this.selectedMainPromotion.installationCost || 0;
      this.marketingTotal += this.selectedMainPromotion.marketingCost || 0;
      this.callcenterTotal += this.selectedMainPromotion.callcenterCost || 0;
      this.marginalityTotal += this.selectedMainPromotion.marginality || 0;
    }
    this.selectedAccessoryPromotions.forEach((r) => {
      this.materialsTotal += (r.materialsCost || 0) * r.quantity;
      this.installationTotal += (r.installationCost || 0) * r.quantity;
      this.marketingTotal += (r.marketingCost || 0) * r.quantity;
      this.callcenterTotal += (r.callcenterCost || 0) * r.quantity;
      this.marginalityTotal += (r.marginality || 0) * r.quantity;
    });

    let items: EstimateItem[] = [];

    //calculate mainPromotions items
    if (this.selectedMainPromotion) {
      const productsEstimate: IProductEstimate[] = this.selectedMainPromotion.products.map((p) => {
        return {
          product: p.product as IProduct,
          quantity: p.quantity,
        };
      });
      items.push({
        quantity: 1,
        name: this.selectedMainPromotion.name,
        description: this.selectedMainPromotion.description,
        id: this.selectedMainPromotion._id,
        products: productsEstimate,
        type: PromotionType.PRINCIPALE,
        addOnCost: this.selectedMainPromotion.addOnCost || 0,
        materialsCost: this.selectedMainPromotion.materialsCost || 0,
        marketingCost: this.selectedMainPromotion.marketingCost || 0,
        installationCost: this.selectedMainPromotion.installationCost || 0,
        callcenterCost: this.selectedMainPromotion.callcenterCost || 0,
        marginality: this.selectedMainPromotion.marginality || 0,
        totalCost: this.mainTotal,
      });
    }

    //calculate accessoryPromotions items
    items.push(
      ...(this.selectedAccessoryPromotions || []).map((aP) => {
        return {
          name: aP.name,
          description: aP.description,
          quantity: aP.quantity,
          id: aP._id,
          products: aP.products.map((r) => {
            return {
              quantity: r.quantity,
              product: r.product,
            } as IProductEstimate;
          }),
          addOnCost: aP.addOnCost || 0,
          materialsCost: aP.materialsCost || 0,
          marketingCost: aP.marketingCost || 0,
          installationCost: aP.installationCost || 0,
          callcenterCost: aP.callcenterCost || 0,
          marginality: aP.marginality || 0,
          type: PromotionType.ACCESSORIO,
          totalCost:
            ((aP.addOnCost || 0) +
              (aP.installationCost || 0) +
              (aP.materialsCost || 0) +
              (aP.marketingCost || 0) +
              (aP.marginality || 0) +
              +(aP.callcenterCost || 0)) *
            aP.quantity,
        };
      }),
    );

    this.form.patchValue({ items });
  }

  addPrimaryPackage() {
    this.matDialog
      .open(SelectPrimaryPackageModalComponent, {
        panelClass: 'package-modal',
        data: {
          selected: this.selectedMainPromotion?._id,
          primaryPackages: this.allMainPromotions,
        },
      })
      .afterClosed()
      .pipe(filter((res) => res && res.selected))
      .subscribe((res) => {
        console.log(res);
        this.selectedMainPromotion = res.selected;
        console.log(this.selectedMainPromotion);

        this.changeMainPromotion();
      });
  }

  addAccessoryPackages() {
    this.matDialog
      .open(SelectAccessoryPackageModalComponent, {
        panelClass: 'package-modal',
        data: {
          selected: this.selectedAccessoryPromotions,
          accessoryPackages: this.allAccessoryPromotions,
        },
      })
      .afterClosed()
      .pipe(filter((res) => res && res.selected))
      .subscribe((res) => {
        console.log(res);
        this.selectedAccessoryPromotions = res.selected;
        this.changeAccessoryPromotions();
      });
  }
}
