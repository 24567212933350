import { ObjectDTO } from '@renovars/common';

export interface IAppointmentSearchDTO extends ObjectDTO {
  ids?: string[];
  firstName?: string;
  lastName?: string;
  contactId?: string;
  phone?: string;
  email?: string;
  address?: string;
  insertDateRange?: { start: Date; end: Date };
  oppInsertDateRange?: { start: Date; end: Date };
  eventStartRange?: { start: Date; end: Date };
  eventEndRange?: { start: Date; end: Date };
  eventType?: number;
  userId?: string;
  state?: number;
  disputeState?: number;
  insertUserId?: string;
  hidePersonalEvents?: boolean;
  showOnlyCreatedByMe?: boolean;
  showOnlyValidEvents?: boolean;
  cache?: any;
  network?: string;
  withAdminMsg?: boolean;
  utmCampaign?: string;
  utmSource?: string;
  source?: number;
  doneStateDate?: { start: Date; end: Date };
  canSyncGraph?: boolean;
  disputeAccepted?: boolean;
  isOpenhouseVisit?: boolean;
}
