<form [formGroup]="form" novalidate>
  <div class="row">
    <div class="col-12">
      <header class="page-header">
        <h2 class="tx-title-2">Dati anagrafici</h2>
      </header>
    </div>
  </div>

  <div class="row mt-3 gy-3">
    <div class="col-sm-12 col-md-4">
      <fullstack-input-box
        [options]="{ label: 'Nome *' }"
        formControlName="firstName"
        [error]="_formInfoState?.firstName?.error"
      ></fullstack-input-box>
    </div>
    <div class="col-sm-12 col-md-4">
      <fullstack-input-box
        [options]="{ label: 'Cognome *' }"
        formControlName="lastName"
        [error]="_formInfoState?.lastName?.error"
      ></fullstack-input-box>
    </div>
    <div class="col-sm-12 col-md-4">
      <fullstack-datepicker
        [options]="{ label: 'Data di nascita' }"
        formControlName="birthDate"
        [error]="_formInfoState?.birthDate?.error"
      >
      </fullstack-datepicker>
    </div>
    <div class="col-sm-12 col-md-4">
      <fullstack-input-box
        [options]="{ label: 'Luogo di nascita' }"
        formControlName="birthPlace"
        [error]="_formInfoState?.birthPlace?.error"
      ></fullstack-input-box>
    </div>
    <div class="col-sm-12 col-md-4">
      <fullstack-input-select-box
        [options]="{ label: 'Sesso' }"
        formControlName="gender"
        [error]="_formInfoState?.gender?.error"
      >
        <sb-option [value]="i" *ngFor="let i of ['Uomo', 'Donna']">{{ i }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div class="col-sm-12 col-md-4">
      <fullstack-input-box
        [options]="{ label: 'Codice fiscale' }"
        formControlName="fiscalCode"
        [error]="_formInfoState?.fiscalCode?.error"
      ></fullstack-input-box>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <header class="page-header">
        <h2 class="tx-title-2">Indirizzo di residenza</h2>
      </header>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-sm-12 col-md-12">
      <fullstack-input-address-box
        [options]="{ label: 'Indirizzo' }"
        formControlName="address"
        [error]="_formInfoState?.address?.error"
      >
      </fullstack-input-address-box>
    </div>
  </div>
  <div class="row" *ngIf="showBillingAddress">
    <div class="col-12">
      <header class="page-header">
        <h2 class="tx-title-2">Fatturazione</h2>
      </header>
    </div>
  </div>
  <div class="row mt-4" *ngIf="showBillingAddress">
    <div class="col-sm-12 col-md-12">
      <fullstack-input-address-box
        [options]="{ label: 'Indirizzo' }"
        formControlName="billingAddress"
        [error]="_formInfoState?.billingAddress?.error"
      >
      </fullstack-input-address-box>
    </div>
    <div class="col-sm-6 col-md-6">
      <fullstack-input-box
        [options]="{ label: 'Iban' }"
        formControlName="iban"
        [error]="_formInfoState?.iban?.error"
      ></fullstack-input-box>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <header class="page-header">
        <h2 class="tx-title-2">Contatti</h2>
      </header>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <fullstack-form-row-list [title]="'Email *'" formControlName="emails" [controlsFn]="emailsGroupControl">
        <ng-template #rowTemplate let-control>
          <div class="row mt-1" [formGroup]="control">
            <div class="col-md-3" *ngIf="showMailType">
              <fullstack-input-select-box [options]="{ label: 'Tipo' }" formControlName="type">
                <sb-option
                  [value]="i"
                  *ngFor="
                    let i of [
                      'Info',
                      'Vendite',
                      'Marketing',
                      'Acquisti',
                      'Direzione',
                      'Assistenza',
                      'Tecnico',
                      'Amministrazione',
                      'Altre',
                      'Pec'
                    ]
                  "
                >
                  {{ i }}
                </sb-option>
              </fullstack-input-select-box>
            </div>
            <div class="col-md-5">
              <fullstack-input-box [options]="{ label: 'Email' }" formControlName="email"></fullstack-input-box>
            </div>
            <div class="col-md-4" *ngIf="showMailType">
              <fullstack-input-box [options]="{ label: 'Note' }" formControlName="note"></fullstack-input-box>
            </div>
          </div>
        </ng-template>
      </fullstack-form-row-list>
    </div>

    <div class="col-12">
      <fullstack-form-row-list [title]="'Cellulare *'" formControlName="phones" [controlsFn]="phonesGroupControl">
        <ng-template #rowTemplate let-control>
          <div class="row mt-1" [formGroup]="control">
            <div class="col-md-3" *ngIf="showPhoneType">
              <fullstack-input-select-box [options]="{ label: 'Tipo' }" formControlName="type">
                <sb-option
                  [value]="i"
                  *ngFor="
                    let i of [
                      'Info',
                      'Vendite',
                      'Marketing',
                      'Acquisti',
                      'Direzione',
                      'Assistenza',
                      'Tecnico',
                      'Amministrazione',
                      'Altre',
                      'Pec'
                    ]
                  "
                >
                  {{ i }}
                </sb-option>
              </fullstack-input-select-box>
            </div>
            <div class="col-md-5">
              <fullstack-input-box [options]="{ label: 'Telefono' }" formControlName="phoneNumber">
              </fullstack-input-box>
            </div>
          </div>
        </ng-template>
      </fullstack-form-row-list>
    </div>
  </div>
  <div *ngIf="showSources">
    <div class="row">
      <div class="col-12">
        <header class="page-header">
          <h2 class="tx-title-2">Dettagli</h2>
        </header>
      </div>
    </div>
    <div class="row mt-4">
      <div class="col-sm-12 col-md-6">
        <fullstack-input-select-box
          [options]="{ label: 'Stato' }"
          formControlName="state"
          [error]="_formInfoState?.state?.error"
        >
          <sb-option [value]="i" *ngFor="let i of ['Da verificare', 'Verificato']">
            {{ i }}
          </sb-option>
        </fullstack-input-select-box>
      </div>
      <div class="col-sm-12 col-md-6">
        <fullstack-input-select-box
          [options]="{ label: 'Fonte Contatto *' }"
          formControlName="source"
          [error]="_formInfoState?.source?.error"
        >
          <sb-option [value]="i" *ngFor="let i of sources">
            {{ i }}
          </sb-option>
        </fullstack-input-select-box>
      </div>
    </div>
  </div>
</form>
