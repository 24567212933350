<h2 mat-dialog-title>Nuovo evento personale</h2>
<div mat-dialog-content>
  <div class="row mb-2">
    <div class="col-12">
      <fullstack-input-box [options]="{ label: 'Descrizione' }" [(ngModel)]="note"></fullstack-input-box>
    </div>
  </div>

  <div class="row mb-2">
    <div class="col-12">
      <fullstack-datepicker [options]="{ label: 'Giorno' }" [(ngModel)]="date"> </fullstack-datepicker>
    </div>
  </div>

  <div class="row mb-3">
    <div class="col-6">
      <label for="">Dalle ore</label>
      <select class="form-select" [(ngModel)]="slot.start" (change)="checkStartRange()">
        <option *ngFor="let hour of slot.startHours; let i = index" [value]="hour | number">
          {{ hour }}
        </option>
      </select>
    </div>
    <div class="col-6">
      <label for="">Alle ore</label>
      <select class="form-select" [(ngModel)]="slot.end" (change)="checkEndRange()">
        <option *ngFor="let hour of slot.endHours; let i = index" [value]="hour | number">
          {{ hour }}
        </option>
      </select>
    </div>
  </div>
</div>
<mat-dialog-actions align="end">
  <button mat-button class="me-2" mat-dialog-close>Annulla</button>
  <button mat-raised-button color="primary"  cdkFocusInitial (click)="save()">
    Conferma
  </button>
</mat-dialog-actions>
