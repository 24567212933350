import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { environment } from '../../environments/environment';
import { IEvent } from '@renovars/common';

@Injectable()
export class OpportunityEventService {
  environment = environment;
  constructor(private http: HttpClient) {}
  createOpportunityEvent(opportunityId: string, event: IEvent) {
    return this.http.post(`${environment.apiGatewayURL}/events/opportunities/${opportunityId}`, event);
  }
}
