import { Component, ViewEncapsulation } from '@angular/core';
import { SpinnerService } from './spinner.service';

@Component({
  selector: 'fullstack-spinner',
  template: `
    <div *ngIf="spinnerService.httpLoading" class="spinner-component">
      <div class="overlay">
        <div class="center">
          <mat-progress-spinner diameter="50" mode="indeterminate" color="primary"> </mat-progress-spinner>
        </div>
      </div>
    </div>
  `,
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
          .center {
            position: absolute;
            top: 50%;
            left: 50%;
            -moz-transform: translateX(-50%) translateY(-50%);
            -webkit-transform: translateX(-50%) translateY(-50%);
            transform: translateX(-50%) translateY(-50%);
          }
    
          .overlay {
            height: 100vh;
            width: 100%;
            background-color: rgba(0, 0, 0, 0.286);
            z-index: 2000;
            top: 0;
            left: 0;
            position: fixed;
          }
        `,
  ],
})
export class SpinnerComponent {
  constructor(public spinnerService: SpinnerService) {}
}
