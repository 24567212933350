import { IDomainValue } from '@renovars/common';

export enum PltDomainValueType {
  LEAD_DOCUMENTS_TYPE = 'lead_documents_type',
  SUPPlIER_DOCUMENT_TYPE = 'supplier_documents_type',
  SUPPLIER_INDIVIDUAL_DOCUMENT_TYPE = 'supplier_individual_documents_type',
  WARM_TYPE = 'warm_type',
  OWNER_TYPE = 'owner_type',
  INTEREST_TYPE = 'interest_type',
  PRODUCT_CATEGORY_TYPE = 'products_category_type',
  CALL_PREFERENCE_TYPE = 'call_preference_type',
}

export interface IPltDomainValue extends IDomainValue<PltDomainValueType> {}
