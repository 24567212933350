import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'confirm-modal',
  template: ` <h2 mat-dialog-title>{{ data.title }}</h2>
    <mat-dialog-content class="mat-typography">
      <div class="flex items-center">
        <mat-icon class="!w-10 !h-10 text-3xl scale-150">warning</mat-icon>

        {{ data.text }}
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <button mat-button type="button" data-dismiss="modal" (click)="dialogRef.close()">Annulla</button>
      <button
        mat-raised-button
        color="primary"
        type="button"
        data-dismiss="modal"
        [mat-dialog-close]="{ confirmed: true }"
      >
        Conferma
      </button>
    </mat-dialog-actions>`,
  styleUrls: ['./modals.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConfirmModalComponent {
  constructor(public dialogRef: MatDialogRef<ConfirmModalComponent>, @Inject(MAT_DIALOG_DATA) public data) {}
}
