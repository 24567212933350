import { IEvent } from '@renovars/common';

export enum FiEventType {
  SOPRALLUOGO = 1,
  VISITA_IMMOBILE = 2,
  CALL = 3,
  VIDEO_CALL = 4,
  GESTIONE = 14,
  SMS_WA = 6,
  INCONTRO = 9,
  VALUTAZIONE = 13,
  OPENHOUSE = 20,
  PERMUTA = 23,
}
export interface IFiEvent extends IEvent {
  project?: string;
  projectSale?: string;
  projectPurchase?: string;

  openHouseParentEvent?: string;
  rescheduleCount?: number;
  bypassReminder?: boolean;
  cache?: {
    project: any;
    projectSale: any;
    projectPurchase: any;
  };
}
