import { Component, forwardRef, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormElement } from '../core/utils/form-element';

@Component({
  selector: 'fullstack-input-textarea',
  templateUrl: './input-textarea.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputTextareaComponent),
      multi: true,
    },
  ],
})
export class InputTextareaComponent extends FormElement implements OnInit {
  @Input() options: { label: String; placeholder: String; rows: number; cols: number; readonly: false } = {
    label: null,
    placeholder: null,
    rows: 5,
    cols: 10,
    readonly: false,
  };

  ngOnInit(): void {}
}
