<div *ngIf="!useLeadContact">
  <fullstack-input-select-box
    *ngIf="!isNewContact && !editMode"
    class="col-4"
    [options]="{ label: 'Seleziona contatto esistente', autocomplete: true }"
    ngModel
    (inputChange)="searchContact($event)"
    (ngModelChange)="onSetContact($event)"
  >
    <sb-option [value]="i" *ngFor="let i of contacts$ | async"
      >{{ i | contactName }} (tel: {{ i.phones && i.phones.length > 0 ? i.phones[0].phoneNumber : '-' }}) (email:
      {{ i.emails && i.emails.length > 0 ? i?.emails[0].email : '' }})</sb-option
    ></fullstack-input-select-box
  >
  <div class="text-end" *ngIf="!editMode">
    <button class="btn btn-secondary" (click)="newContact()">Nuovo Contatto</button>
  </div>
</div>

<div *ngIf="useLeadContact" class="mt-2 mb-2 text-end">
  <button class="btn btn-secondary rounded" (click)="useAnotherContact()">
    Voglio usare un contatto esistente o crearne uno nuovo
  </button>
</div>

<form [formGroup]="contactForm" novalidate *ngIf="selectedContact">
  <fullstack-contact-form formControlName="contact" [options]="{ showSources: false, billingRequired: ' *' }">
  </fullstack-contact-form>
</form>

<form [formGroup]="newContactForm" novalidate *ngIf="isNewContact">
  <fullstack-contact-form formControlName="contact" [options]="{ showSources: false, billingRequired: ' *' }">
  </fullstack-contact-form>
</form>
<div class="flex justify-end mt-2">
  <button mat-raised-button color="primary" (click)="save()" [disabled]="!selectedContact && !isNewContact && !canEdit">
    Salva
  </button>
</div>
