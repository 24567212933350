export enum FileServerGroupType {
  USER_IMAGE = 'user_image',
  LEAD_IMAGES = 'lead_images',
  LEAD_DOCUMENTS = 'lead_documents',
  CONVERTED_PDF = 'converted_pdf',
  LEAD_IMAGES_360 = 'lead_images_360',
}

export enum FileServerType {
  PLANIMETRY = 6,
}
