<header class="page-header">
  <h2 class="tx-title-2">Consensi privacy</h2>
  <p class="text-gray-600">Impostazioni di accettazione privacy per il cliente.</p>
</header>

<form [formGroup]="form" class="font-sans" *ngIf="project">
  <div class="flex place-items-baseline">
    <h4 class="font-medium my-2 mr-4 text-gray-600">Stato corrente:</h4>
    <h6
      [ngClass]="{
      'text-yellow-600': privacyStateLabel.color === 'yellow',
      'text-red-600': privacyStateLabel.color === 'red',
      'text-green-600': privacyStateLabel.color === 'green',
   }"
    >
      {{ privacyStateLabel.label }}
    </h6>
  </div>
  <div>
    <div class="flex flex-row gap-2 mt-2" formGroupName="privacy">
      <div
        class="basis-1/4 block rounded-lg p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
      >
        <h5 class="font-medium">Marketing</h5>
        <p class="h-48 text-gray-600">
          Attività di marketing, nonché l’invio di newsletter e comunicazioni relativi ai servizi di Facile
          Ristrutturare e delle altre società del Gruppo Renovars
        </p>
        <mat-slide-toggle class="float-right" formControlName="marketing">Marketing</mat-slide-toggle>
      </div>

      <div
        class="basis-1/4 block rounded-lg p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
      >
        <h5 class="font-medium">Upselling</h5>
        <p class="h-48 text-gray-600">
          Attività di profilazione finalizzate a conoscere meglio i gusti e le preferenze degli utenti per orientare
          l’offerta di servizi
        </p>
        <mat-slide-toggle class="float-right" formControlName="upselling">Upselling</mat-slide-toggle>
      </div>

      <div
        class="basis-1/4 block rounded-lg p-6 shadow-[0_2px_15px_-3px_rgba(0,0,0,0.07),0_10px_20px_-2px_rgba(0,0,0,0.04)]"
      >
        <h5 class="font-medium">All</h5>
        <p class="h-48 text-gray-600">
          Consenso per attività svolte in regime di contitolarità con le altre società del Gruppo Renovars [Renovars
          S.p.a, Facile Ristrutturare S.p.a., Renovars Real estate S.r.l., Renovars Distirbution S.r.l., Comparafacile
          S.r.l., Advertoo S.r.l., Credito Facile Mediazione Creditizia S.r.l.]
        </p>
        <mat-slide-toggle class="float-right" formControlName="all">All</mat-slide-toggle>
      </div>
    </div>
    <div>
      <div class="flex gap-2 mt-4 place-items-center">
        <div class="basis-1/4">
          <h6 class="pl-6 font-medium text-gray-600">Data ultima richiesta inviata</h6>
          <h5 class="pl-6 font-medium">
            {{ (form.get('privacy').get('lastRequestedAt').value | date : 'dd-MM-yyyy HH:mm') || '-' }}
          </h5>
        </div>

        <div class="basis-1/4">
          <h6 class="pl-6 font-medium text-gray-600">Data ultima risposta ricevuta</h6>
          <h5 class="pl-6 font-medium">
            {{ (form.get('privacy').get('lastRespondedAt').value | date : 'dd-MM-yyyy HH:mm') || '-' }}
          </h5>
        </div>
        <div class="basis-1/4">
          <h6 class="pl-6 font-medium text-gray-600">Dati utilizzabili</h6>
          <h5 class="pl-6 font-medium text-green-600">
            {{ form.get('privacy').get('isHidden').value ? 'No' : 'Si' }}
          </h5>
        </div>
      </div>
    </div>
  </div>
  <div class="flex justify-between mt-2">
    <button mat-raised-button color="primary" (click)="requestPrivacySurvey()">Richiedi consensi al contatto</button>

    <button mat-raised-button color="primary" (click)="savePrivacyConsents()">Salva</button>
  </div>
</form>
