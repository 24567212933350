import { Opportinity, PltOpportunityStatusCode, PaymentsType } from '@renovars/common/plenitude';

export function filterMenu(
  selectedOpportunity: Opportinity,
): (value: string, index: number, array: string[]) => unknown {
  return (voice) => {
    if (selectedOpportunity.status.code == PltOpportunityStatusCode.APPUNTAMENTO_DA_FISSARE) {
      if (
        selectedOpportunity.cache?.lead.concern == PaymentsType.PAGAMENTO_RATE &&
        voice == PltOpportunityStatusCode.KO_ATTESA_PAGAMENTO
      )
        return false;
      if (
        selectedOpportunity.cache?.lead.concern == PaymentsType.PAGAMENTO_UNICO &&
        voice == PltOpportunityStatusCode.KO_FINANZIAMENTO
      )
        return false;
    }
    if (selectedOpportunity.status.code == PltOpportunityStatusCode.DA_INSTALLARE) {
      if (
        selectedOpportunity.cache?.lead.concern == PaymentsType.PAGAMENTO_RATE &&
        voice == PltOpportunityStatusCode.KO_ATTESA_PAGAMENTO
      )
        return false;
      if (
        selectedOpportunity.cache?.lead.concern == PaymentsType.PAGAMENTO_UNICO &&
        voice == PltOpportunityStatusCode.KO_FINANZIAMENTO
      )
        return false;
    }
    if (selectedOpportunity.status.code == PltOpportunityStatusCode.PENDING_ATTESA_DOCUMENTI) {
      if (
        selectedOpportunity.cache?.lead.concern == PaymentsType.PAGAMENTO_RATE &&
        [
          PltOpportunityStatusCode.KO_ATTESA_PAGAMENTO.toString(),
          PltOpportunityStatusCode.PENDING_ATTESA_PAGAMENTO.toString(),
        ].includes(voice)
      )
        return false;
      if (
        selectedOpportunity.cache?.lead.concern == PaymentsType.PAGAMENTO_UNICO &&
        [
          PltOpportunityStatusCode.KO_FINANZIAMENTO.toString(),
          PltOpportunityStatusCode.PENDING_ATTESA_FINANZIAMENTO.toString(),
        ].includes(voice)
      )
        return false;
    }
    return true;
  };
}
