<label class="card card--form">
  <span class="card-body">
    <span class="label-control">{{ options?.label }}</span>
    <small class="p-error block" *ngIf="error"> {{ error }}</small>
    <div class="d-flex flex-row justify-content-between align-items-center">
        <input
          class="form-control"
          type="text"
          [matDatepicker]="picker1"
          [ngModel]="value"
          (ngModelChange)="_onChange($event)"
          (change)="dateChange($event)"
          [disabled]="isDisabled || options.disabled"
          placeholder="{{ options?.placeholder }}"
        />
      <div [hidden]="isDisabled || options.disabled">
        <button type="button" (click)="picker1.open()" class="ml-2">
          <i class="far fa-calendar"></i>
        </button>
        <mat-datepicker #picker1></mat-datepicker>
      </div>
    </div>
  </span>
</label>
