import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UiModule } from '@renovars/ui-ng';
import { PipeLibsModule } from '../../pipes';
import { ContactsServices } from '../../services/contacts.services';
import { UtilsService } from '../../services/utils.services';
import { DomainValuesModule } from '../domain-values';
import { ContactComponent } from './components/contact.component';
import { ContactUpsertComponent } from './components/pages/upsert/contact-upsert.component';
import { ContactsTimelineContentComponent } from './components/timeline-content/contacts-timeline-content.component';
import { contactsRoutes } from './contact-routing.module';
import { ContactCompanyFormComponent } from './forms/contact-company-form.component';
import { ContactFormComponent } from './forms/contact-form.component';
import { ContactIndividualFormComponent } from './forms/contact-individual-form.component';

const COMPONENTS = [
  ContactComponent,
  ContactUpsertComponent,
  ContactFormComponent,
  ContactIndividualFormComponent,
  ContactCompanyFormComponent,
  ContactsTimelineContentComponent,
];

@NgModule({
  imports: [
    RouterModule.forChild(contactsRoutes),
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    UiModule,
    DomainValuesModule,
    PipeLibsModule,
  ],
  declarations: COMPONENTS,
  exports: COMPONENTS,
  providers: [ContactsServices, UtilsService],
})
export class ContactModule {}
