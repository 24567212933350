import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  DisputeStateEnum,
  IAppointmentSearchDTO,
  ICreatePersonalEvent,
  IEvent,
  IPaginateRequest,
  IPaginateResponse,
  mergeArrayCustomizer,
} from '@renovars/common';
import * as _ from 'lodash';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import {
  AppuntamentoModuleConfig,
  APPUNTAMENTO_CONFIG_PROVIDER,
} from '../features/appuntamento/module.options';
import { UtilsService } from './utils.services';
@Injectable()
export class EventService {
  constructor(
    private http: HttpClient,
    @Inject(APPUNTAMENTO_CONFIG_PROVIDER) private config: AppuntamentoModuleConfig,
    private utilsService: UtilsService,
    private messageService: MessageService
  ) {}

  create(obj: IEvent): Observable<any> {
    return this.http.post<any>(`${this.config.eventsEndpoint}`, obj);
  }

  search(paginateRequest: IPaginateRequest<IAppointmentSearchDTO>): Observable<IPaginateResponse<IEvent>> {
    return this.http.post<any>(`${this.config.eventsEndpoint}/search`, paginateRequest);
  }

  checkEventAvailability(obj: IEvent): Observable<any> {
    return this.http.post<any>(`${this.config.eventsEndpoint}/calculateEventAvailability`, obj);
  }

  get(id): Observable<IEvent> {
    return this.http.get<any>(`${this.config.eventsEndpoint}/${id}`);
  }

  update(id: string, obj: IEvent): Observable<any> {
    return this.get(id).pipe(
      map((res) => {
        const payload: IEvent = _.cloneDeep(_.mergeWith({}, res, obj, mergeArrayCustomizer));
        return payload;
      }),
      switchMap((payload) => this.http.put<any>(`${this.config.eventsEndpoint}/${id}`, payload))
    );
  }

  createPersonalEvent(obj: ICreatePersonalEvent): Observable<any> {
    return this.http.post<any>(`${this.config.eventsEndpoint}/createPersonalEvent`, obj);
  }
  delete(id: string): Observable<any> {
    return this.http.delete<any>(`${this.config.eventsEndpoint}/${id}`);
  }
  replaceEvent(id: string, obj: IEvent) {
    return this.http.post(`${this.config.eventsEndpoint}/replace/${id}`, obj);
  }

  disputeEventChangeStatus(eventId: string, newState: DisputeStateEnum): Observable<any> {
    return this.http
      .post(`${this.config.eventsEndpoint}/disputeEventChangeStatus`, {
        eventId,
        state: newState,
      })
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Contestazione',
            detail: 'Stato contestazione modificato correttamente',
          });
        })
      );
  }

  acceptDispute(eventId: string): Observable<any> {
    return this.http
      .post(`${this.config.eventsEndpoint}/acceptDispute`, {
        eventId,
      })
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Contestazione',
            detail: 'Contestazione accettata',
          });
        })
      );
  }

  rejectDispute(eventId: string): Observable<any> {
    return this.http
      .post(`${this.config.eventsEndpoint}/rejectDispute`, {
        eventId,
      })
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Contestazione',
            detail: 'Contestazione rifiutata',
          });
        })
      );
  }

  disputeEventNoteUpdate(eventId: string, note: string): Observable<any> {
    return this.http
      .post(`${this.config.eventsEndpoint}/disputeEventNoteUpdate`, {
        eventId,
        note,
      })
      .pipe(
        tap(() => {
          this.messageService.add({
            severity: 'success',
            summary: 'Contestazione',
            detail: 'Note aggiornate correttamente',
          });
        })
      );
  }
}
