import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Injectable,
  Input,
  LOCALE_ID,
  Output,
  PLATFORM_ID,
  ViewEncapsulation,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { FormElement } from '../core/utils/form-element';
import { DatepickerHeaderComponent } from './datepicker-header.component';
import dayjs from 'dayjs';

@Injectable()
export class LocalDateAdapter extends NativeDateAdapter {
  constructor(
    @Inject(LOCALE_ID) public locale: string,
    @Inject(PLATFORM_ID) platformId: never
  ) {
    super(locale, platformId);
  }

  getFirstDayOfWeek(): number {
    return 1;
  }
}

@Component({
  selector: 'fullstack-daterangepicker',
  templateUrl: './daterangepicker.component.html',
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DaterangepickerComponent),
      multi: true,
    },
    { provide: DateAdapter, useClass: LocalDateAdapter },
  ],
  styles: [
    `
      .mat-date-range-input-wrapper {
        width: 5em;
      }
      mat-datepicker-toggle {
        display: flex;
        height: 25px;
        width: 25px;

        > .mat-icon-button {
          height: 100%;

          > span > svg {
            height: 20px;
            width: 20px;
          }
        }
      }
    `,
  ],
})
export class DaterangepickerComponent extends FormElement {
  @Input() options: {
    label: string;
    placeholder: string;
    min?: Date;
    max?: Date;
  };
  @Output() rangeChange: EventEmitter<unknown> = new EventEmitter();
  start: Date;
  end: Date;
  readonly DatepickerHeaderComponent = DatepickerHeaderComponent;

  _rangeChange() {
    if (this.end) {
      this.rangeChange.emit({
        start: dayjs(this.start).startOf('day').toDate(),
        end: dayjs(this.end).endOf('day').toDate(),
      });
      this._onChange({
        end: dayjs(this.end).endOf('day').toDate(),
        start: dayjs(this.start).startOf('day').toDate(),
      });
    }
  }

  writeValue(obj: { start: Date; end: Date }): void {
    if (obj) {
      this.start = obj.start;
      this.end = obj.end;
    }
  }

  _clear() {
    this.start = undefined;
    this.end = undefined;
    this.rangeChange.emit();
  }
}
