import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { IApplication, IUser } from '@renovars/common';
import { AppConfigService } from '@renovars/fe-core-libs/features/app-config';
import { ApplicationsServices } from '@renovars/fe-core-libs/services';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import { Observable, zip } from 'rxjs';
import { map, skip, switchMap } from 'rxjs/operators';
@Component({
  selector: 'facile-anagrafica-upsert',
  template: `
    <form [formGroup]="form" novalidate>
      <div class="content">
        <fullstack-input-user
          [imageStorePath]="apiGatewayURL + '/file-server/download/'"
          formControlName="user"
        ></fullstack-input-user>
        <div class="row">
          <div
            class="col-6 my-1"
            pTooltip="Abilitato alla professione di Agente d'affari in mediazione"
          >
            <fullstack-input-check-box
              formControlName="isQualified"
              [options]="{ label: 'Abilitato' }"
            ></fullstack-input-check-box>
          </div>
          <div
            *permissions="['is_maintainer']"
            class="col-6 my-1"
            pTooltip="Imposta utenza come fittizia, non riceverà alert via email "
          >
            <fullstack-input-check-box
              formControlName="isDummy"
              [options]="{ label: 'Fittizia' }"
            ></fullstack-input-check-box>
          </div>
        </div>
        <div class="row">
          <div  class="col-6 my-1">
            <div class="flex w-100 items-center">
            <fullstack-input-box
                  class=" w-100"
                  formControlName="fiscalCode"
                  [options]="{ label: 'Codice fiscale' }"
              ></fullstack-input-box>
              <button mat-icon-button color="primary" (click)="updateFiscalCode()"><mat-icon>save</mat-icon> </button>
            </div>

          </div>
        </div>
        <fullstack-input-registrazioni
          *ngIf="id"
          [registrazioni]="user?.registrations"
          [applications]="applications$ | async"
          [applicationsCanAdd]="applications$ | async"
        >
        </fullstack-input-registrazioni>
      </div>
    </form>
  `,
  styles: [],
})
export class UtentiUpsertComponent implements OnInit {
  form = this.fb.group({
    user: new UntypedFormControl(null, []),
    isQualified: new UntypedFormControl(false, []),
    isDummy: new UntypedFormControl(false, []),
    fiscalCode: new UntypedFormControl('', []),
  });

  user: IUser;
  id: string = null;
  applications$: Observable<IApplication[]>;
  apiGatewayURL;

  constructor(
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private applications: ApplicationsServices,
    private usersService: UsersService,
    private appConfig: AppConfigService
  ) {}

  ngOnInit(): void {
    this.apiGatewayURL = this.appConfig.getApiGatewayURL();
    this.applications$ = this.applications.applications().pipe(
      map((apps) => {
        return apps
          ? apps.filter((app) => app.id === this.appConfig.getApplicationId())
          : [];
      })
    );

    this.activatedRoute.queryParams.subscribe((params) => {
      this.id = params.id;
      if (this.id) {
        zip([
          this.usersService.getUser(this.id),
          this.usersService.getUserMeta(this.id),
        ]).subscribe(([user, meta]) => {
          this.form.patchValue({ user: user, isQualified: meta?.isQualified, isDummy: meta?.isDummy, fiscalCode: meta?.fiscalCode });
          this.user = user;
        });
      }
    });
    this.form
      .get('isQualified')
      .valueChanges.pipe(
        skip(1),
        switchMap((value) =>
          this.usersService.updateUserMeta(this.id, { isQualified: value })
        )
      )
      .subscribe();
    this.form
      .get('isDummy')
      .valueChanges.pipe(
        skip(1),
        switchMap((value) =>
          this.usersService.updateUserMeta(this.id, { isDummy: value })
        )
      )
      .subscribe();
  }

  updateFiscalCode() {
    this.usersService.updateUserMeta(this.id, { fiscalCode: this.form.get('fiscalCode').value }).subscribe()
  }

  save() {}
}
