import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog'
import { ConfirmModalComponent } from './confirm-modal.component';

@Injectable()
export class ModalsService {
  constructor(private dialog: MatDialog) {}

  confirm(params: { title: string; text: string }) {
    return this.dialog.open(ConfirmModalComponent, {
      data: { ...params },
    });
  }
}
