<h2 mat-dialog-title>Seleziona un pacchetto principale</h2>
<mat-dialog-content class="mat-typography package-content">
  <div class="flex h-72 justify-center">
    <p-dropdown
      [options]="primaryPackages"
      [dataKey]="'_id'"
      [(ngModel)]="selectedPackage"
      optionLabel="name"
      [filter]="true"
      filterBy="name"
      [showClear]="true"
      placeholder="Seleziona un pacchetto"
    >
      <ng-template pTemplate="selectedItem">
        <div class="flex align-items-center gap-2" *ngIf="selectedPackage">
          <div>{{ selectedPackage.name }}</div>
          <div>{{ selectedPackage.description }}</div>
        </div>
      </ng-template>
      <ng-template let-package pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ package.name }}</div>
          <div>{{ package.description }}</div>
        </div>
      </ng-template>
    </p-dropdown>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Chiudi</button>
  <button mat-button (click)="confirm()" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
