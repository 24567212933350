<h3 mat-dialog-title>
  Appuntamento
  <b>
    {{ event?.cache?.contact?.businessName || event?.cache?.contact?.firstName + ' ' +
    event?.cache?.contact?.lastName }}
  </b>
</h3>
<div class="content" mat-dialog-content>
  <div class="row">
    <div class="col-6">
      <div><b>Utente: </b><span>{{event?.user | nameById | async }}</span></div>
      <div><b>Data: </b><span>{{event?.start | date : 'longDate'}}</span></div>
      <div>
        <b>Orario: </b
        ><span>{{event?.start | date : 'shortTime'}} - {{event?.end | date : 'shortTime'}}</span>
      </div>
      <div><b>Indirizzo: </b><span>{{event?.address?.formatted_address}}</span></div>
      <div><b>Note: </b><span>{{event?.note}}</span></div>
    </div>
    <div class="col-6"></div>
  </div>
</div>
<div mat-dialog-actions class="text-end">
  <button type="button" (click)="goToDetail(); close()" class="m-1 btn btn-secondary rounded">
    Dettaglio cliente
  </button>
  <!-- <button type="button" (click)="move()" class="m-1 btn btn-secondary rounded">Sposta</button> -->
  <button type="button" (click)="close()" class="m-1 btn btn-secondary rounded">Ok</button>
</div>
