import { IProduct, IPromotion, Opportinity, PaymentsType } from '@renovars/common/plenitude';

export interface IEstimate {
  _id: string;
  name: string;
  opportunity: string | Opportinity;
  description: string;
  mainPromotions: string[] | IPromotion[];
  accessoryPromotions: string[] | IPromotion[];
  financing: PaymentsType;
  state: EstimateState;
  forProduct: EstimateProducts;
  createdAt?: Date;
  updatedAt?: Date;
  discountPercent: number;
  items?: EstimateItem[];
  //calcolati
  totalCost?: number;
  totalDiscounted?: number;
}

export interface EstimateItem {
  id: string;
  name?: string;
  description?: string;
  quantity: number;
  type: number;
  addOnCost?: number;
  installationCost?: number;
  materialsCost?: number;
  marketingCost?: number;
  callcenterCost?: number;
  marginality?: number;
  totalCost: number;
  products?: IProductEstimate[];
}
export interface IProductEstimate {
  product: IProduct;
  quantity: number
}

export enum EstimateState {
  'DA_PRESENTARE' = 1,
  'PRESENTATO' = 2,
  'ACCETTATO' = 3,
  'RIFIUTATO' = 4,
}

export enum EstimateProducts {
  'CALDAIA' = 1,
  'CLIMA' = 2,
  'CALDAIA_CLIMA' = 3,
}
