<fullstack-page-layout [registration]="(user$ | async)?.registrations">
  <div sidebar>
    <fullstack-sidebar-menu
      [options]="{
        logoUrl: 'assets/img/logo.png',
        logoMinUrl: 'assets/img/logo-min.png'
      }"
      [user]="(user$ | async)?.given_name + ' ' + (user$ | async)?.family_name"
      (logout)="logout()"
    >
      <sidebar-item [label]="'Utenti'" [url]="'./users'" *permissions="['user_manager']" [icon]="'fa-users'">
        <sidebar-item [label]="'Lista'" [url]="'list'"></sidebar-item>
      </sidebar-item>
      <sidebar-item
        [label]="'Anagrafica'"
        [url]="'./contacts'"
        *permissions="['contact_manager']"
        [icon]="'fa-id-card'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Lead'"
        [url]="'./flows'"
        [icon]="'fa-phone'"
        *permissions="['is_cc_operator', 'is_technical_coordinator']"
      ></sidebar-item>
      <sidebar-item
        [label]="'Opportunità'"
        [url]="'./opportunity'"
        [icon]="'fa-user-circle'"
        *permissions="['is_installer', 'is_technical_coordinator']"
      ></sidebar-item>
      <sidebar-item
        [label]="'Network'"
        [url]="'./network'"
        *permissions="['network_manager']"
        [icon]="'fa-project-diagram'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Fornitori'"
        [url]="'./supplier'"
        *permissions="['network_manager']"
        [icon]="'fa-truck'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Appuntamenti'"
        [url]="'./events'"
        *permissions="['event_manager']"
        [icon]="'fa-calendar-alt'"
      ></sidebar-item>
      <sidebar-item
        [label]="'Calendario'"
        [url]="'./calendar'"
        *permissions="['event_manager']"
        [icon]="'fa-calendar-day'"
      ></sidebar-item>
      <sidebar-item [label]="'Impostazioni'" [url]="'./settings'" *permissions="['is_admin']" [icon]="'fa-cog'">
        <sidebar-item [label]="'Documenti'" [url]="'documents'"></sidebar-item>
        <sidebar-item [label]="'Sedi'" [url]="'sites'"></sidebar-item>
        <sidebar-item [label]="'Prodotti'" [url]="'products'"></sidebar-item>
        <sidebar-item [label]="'Pacchetti'" [url]="'promotions'"></sidebar-item>
      </sidebar-item>
    </fullstack-sidebar-menu>
  </div>
  <div content>
    <router-outlet></router-outlet>
  </div>
</fullstack-page-layout>
