import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { LeadApiService } from '@fi/app/features/lead/lead-api';
import { OpportunityApiService } from '@fi/app/features/plt-opportunity/opportunity-api.service';
import { privacyStateLabels } from '@renovars/common';
import { Lead, Opportinity } from '@renovars/common/plenitude';
import { switchMap } from 'rxjs';

@Component({
  selector: 'app-privacy-consents',
  templateUrl: './privacy-consents.component.html',
  styleUrls: ['./privacy-consents.component.scss'],
})
export class PrivacyConsentsComponent implements OnInit {
  isNewPrivacyMode = true;
  project: Lead;
  isLead = true;
  privacyStateLabels = privacyStateLabels;

  form = this.fb.group({
    privacy: this.fb.group({
      marketing: new UntypedFormControl(false, []),
      commercial: new UntypedFormControl(false, []),
      profile: new UntypedFormControl(false, []),
      otherSub: new UntypedFormControl(false, []),
      upselling: new UntypedFormControl(null, []),
      all: new UntypedFormControl(null, []),
      lastRequestedAt: new UntypedFormControl(undefined, []),
      lastRespondedAt: new UntypedFormControl(undefined, []),
      isHidden: new UntypedFormControl(undefined, []),
      state: new UntypedFormControl(undefined, []),
    }),
  });

  constructor(private fb: FormBuilder, private activeRoute: ActivatedRoute, private leadApiService: LeadApiService, private opportunityApiService: OpportunityApiService) {}

  ngOnInit(): void {

    this.activeRoute.data.subscribe(data => {
      this.isLead = data.type === 'lead';
      this.getData();
    })
  }

  getData() {
    if (this.isLead) {
      this.activeRoute.queryParams.pipe(switchMap((params) => this.leadApiService.getById(params.id))).subscribe((p) => {
        this.project = p;
        this.form.patchValue(this.project);
      });
    } else {
      this.activeRoute.queryParams.pipe(switchMap((params) => this.opportunityApiService.getById(params.id))).subscribe((p) => {
        this.project = p.cache.lead;
        this.form.patchValue(this.project);
      });
    }

  }

  savePrivacyConsents() {
    this.leadApiService
      .updateLead(this.project._id, {
        lead: {
          privacy: {
            all: this.form.get('privacy').get('all')?.value,
            marketing: this.form.get('privacy').get('marketing')?.value,
            upselling: this.form.get('privacy').get('upselling')?.value,
          },
        },
      })
      .subscribe();
  }

  requestPrivacySurvey() {
    this.form.get('privacy').get('lastRequestedAt').patchValue(new Date());
     this.leadApiService.requestPrivacyConsents(this.project._id).subscribe((r) => {
       this.project = r;
       this.form.patchValue(this.project);
     });
  }

  get privacyStateLabel() {
    return (
      this.privacyStateLabels.find((r) => r.value === this.form.get('privacy').get('state')?.value) || {
        label: 'Non ancora specificato',
        color: 'grey',
      }
    );
  }
}
