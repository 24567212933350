import { IProduct } from '../products';

export interface IPromoItem {
  product: string | IProduct;
  quantity: number;
}

export type IPromoItemPop = Omit<IPromoItem, 'product'> & { product: IProduct };
export interface IPromotion {
  _id: string;
  name: string;
  description: string;
  products: IPromoItem[];
  addOnCost: number;
  type: PromotionType;
  //calcolati
  installationCost?: number;
  materialsCost?: number;
  marketingCost?: number;
  callcenterCost?: number;
  marginality?: number;
}

export type IPromotionPop = Omit<IPromotion, 'products'> & { products: { product: IProduct; quantity: number }[] };

export enum PromotionType {
  PRINCIPALE = 1,
  ACCESSORIO = 2,
}
