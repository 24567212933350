<div id="container" class="container">
  <h2>Inserisci firma</h2>
  <canvas #drawingArea class="drawing-area" height="400" width="750"></canvas>

  <div class="flex">
    <button mat-raised-button color="primary" id="clear-button" class="clear-button" type="button" (click)="clear()">
      Cancella
    </button>
    <button mat-raised-button color="primary" class="clear-button" type="button" (click)="save()">
      Salva
    </button>
  </div>

</div>
