import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fi/environment/environment';
import { IProduct } from '@renovars/common/plenitude';
import { escapeRegExp } from 'lodash';

type ProductsBrowseResponse = { items: IProduct[]; total: number };
export type ProductBrowseFilter = { category: number; name: string; price: { from: number; to: number } };

@Injectable()
export class ProductsService {
  constructor(private http: HttpClient) {}
  browse(dto: { offset: number; limit: number; filter: ProductBrowseFilter; sort: { by: string; order: number } }) {
    const payload = {} as Record<string, unknown>;
    if (dto?.filter?.category) {
      payload.category = dto.filter.category;
    }
    if (dto?.filter?.name) {
      payload.name = {
        $regex: escapeRegExp(dto.filter.name),
      };
    }
    if (dto?.filter?.price) {
      payload.price = {
        $lte: dto.filter.price.to ?? 3000,
        $gte: dto.filter.price.from ?? 0,
      };
    }
    return this.http.post<ProductsBrowseResponse>(`${environment.apiGatewayURL}/products/browse`, {
      offset: dto?.offset ?? 0,
      limit: dto?.limit ?? 1000,
      filter: payload,
      sort: [dto?.sort ?? { by: '_id', order: -1 }],
    });
  }
  delete(id: string) {
    return this.http.delete<any>(`${environment.apiGatewayURL}/products/${id}`);
  }
  findById(id: string) {
    return this.http.get<IProduct>(`${environment.apiGatewayURL}/products/${id}`);
  }
  save(dto: Omit<IProduct, '_id'>) {
    return this.http.post<any>(`${environment.apiGatewayURL}/products`, dto);
  }
  update(id: string, dto) {
    return this.http.put<any>(`${environment.apiGatewayURL}/products/${id}`, dto);
  }
}
