import { IAddress, IContact, IContactCompany, IContactIndividual } from '@renovars/common';

export interface PltPrivacy {
  marketing: boolean;
  commercial: boolean;
  profile: boolean;
  otherSub: boolean;
}
export interface PltCommonLeadData {
  addressOp: IAddress;
  forProduct: string;
  callPreference: string;
  isOwner: boolean;
  centralizedWarming: boolean;
  mq: number;
  bathNumber: number;
  boilerLocation: string;
  fireplaceType: string;
  paymentMethod: string;
  notes: { note: string }[];
  privacy: PltPrivacy;
}
export interface CreatePltFlowDTO extends PltCommonLeadData {
  contact: IContact;
}

export interface CreatePltOpportunityFlowDTO {
  contact: string;
  lead: string;
}
export interface CreateOpportuntyFromContactDTO extends PltCommonLeadData {
  contact: IContactCompany | IContactIndividual;
  lead: string;
}
