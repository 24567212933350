import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IObjectList, ISource } from '@renovars/common';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../features';
import { CacheEnum, cacheResponse } from '../utils/cache.utils';

@Injectable()
export class SourcesServices {
  constructor(
    private http: HttpClient,
    private appConfigService: AppConfigService
  ) {}

  list(refresh?: boolean): Observable<ISource[]> {
    return cacheResponse(
      CacheEnum.SOURCE_CACHE,
      this.http
        .get<IObjectList>(`${this.appConfigService.getApiProxiedURL()}/sources`)
        .pipe(map((res) => res.data)),
      refresh
    );
  }

  get(id: string): Observable<ISource> {
    return this.http.get<ISource>(
      `${this.appConfigService.getApiProxiedURL()}/sources/${id}`
    );
  }

  save(source: ISource): Observable<any> {
    return this.http.post(
      `${this.appConfigService.getApiProxiedURL()}/sources`,
      source
    );
  }

  update(id: string, source: ISource): Observable<any> {
    return this.http.put(
      `${this.appConfigService.getApiProxiedURL()}/sources/${id}`,
      source
    );
  }

  delete(code: number): Observable<any> {
    return this.http.delete(
      `${this.appConfigService.getApiProxiedURL()}/sources/${code}`
    );
  }
}
