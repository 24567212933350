import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import {
  Lead,
  PaymentsTypeLabel,
  PltEventType,
  PltLeadStatusCode,
  PltRoles,
  PltStatusLabels,
  StatusIcon,
  getEnumKeyByEnumValue,
  getPltUserRole,
} from '@renovars/common/plenitude';
import { Observable, filter, map, switchMap } from 'rxjs';
import { LeadApiService } from '../lead-api';
import { MatDialog } from '@angular/material/dialog';
import { PltUsersCacheService } from '@fi/app/services/plt-users-cache.service';
import { FlowMenuService, AuthServices } from '@renovars/core-ng';
import { MenuItem } from 'primeng/api';
import { OpportunityApiService } from '../../plt-opportunity/opportunity-api.service';
import { CreateEventLeadModalComponent } from '../modals/create-event-lead-modal.component';
import { MoveLeadStateComponent } from '../modals/move-lead-state.component';

@Component({
  selector: 'plt-flow-tab',
  template: `
    <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">
        {{ lead?.cache?.contact?.firstName }}
        {{ lead?.cache?.contact?.lastName || lead?.cache?.contact?.businessName }}
      </h1>
      <div class="flex font-sans justify-between">
        <div>
          <div class="text-gray-600">
            <div class="flex text-sm gap-2">
              <p class="font-semibold my-0 ">Indirizzo installazione:</p>
              <p class="font-bold my-0 ">{{ lead?.addressOp?.formatted_address || '-' }}</p>
            </div>
            <div class="flex text-sm gap-2 ">
              <p class="font-semibold my-0 ">Pagamento:</p>
              <p class="font-bold my-0 ">{{ paymentLabels[lead?.concern] || '-' }}</p>
            </div>
            <div class="flex text-sm gap-2 ">
              <p class="font-semibold my-0 ">Fascia oraria:</p>
              <p class="font-bold my-0 ">{{ lead?.callPreference || '-' }}</p>
            </div>
          </div>
        </div>
        <div class="text-gray-600">
          <div class="flex  justify-between text-sm gap-2 items-center">
            <p class="font-semibold my-0 ">Lead:</p>
            <p class="font-semibold my-0 tx-title-4 capitalize w-100 text-right">
              {{ labels[lead?.status?.code] || '-' }}
            </p>

            <button mat-button [matMenuTriggerFor]="menuLead" [disabled]="leadStateList && leadStateList.length == 0">
              <i class="fas fa-edit"></i>
            </button>
            <mat-menu #menuLead="matMenu">
              <button mat-menu-item *ngFor="let state of leadStateList" (click)="state.command(state)">
                {{ state.label }}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template header-menu>
      <fullstack-tabs>
        <tab [label]="'Dettaglio'" [url]="'./upsert'"></tab>
        <tab *ngIf="id | async" [label]="'Profilo'" [url]="'./profile'"></tab>
        <tab *ngIf="id | async" [label]="'Consensi Privacy'" [url]="'./privacy'"></tab>
        <tab *ngIf="id | async" [label]="'Storico'" [url]="'./history'"></tab>
      </fullstack-tabs>
    </ng-template>
    <div class="m-2">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      .mat-mdc-menu-item-text {
        text-transform: capitalize;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class LeadTabComponent implements OnInit {
  id: Observable<boolean>;
  lead: Lead;
  leadStateList;
  labels = PltStatusLabels;
  paymentLabels = PaymentsTypeLabel;
  constructor(
    private route: ActivatedRoute,
    private leadApiSevice: LeadApiService,
    private flowLeadMenuService: FlowMenuService<typeof PltRoles, typeof PltLeadStatusCode, PltStatusLabels>,

    private pltFlowService: OpportunityApiService,
    private cacheUserService: PltUsersCacheService,
    private authService: AuthServices,
    private dialog: MatDialog,
  ) {}
  ngOnInit(): void {
    this.id = this.route.queryParamMap.pipe(map((params) => params.has('id')));

    this.getData();
  }

  getData() {
    this.route.queryParamMap
      .pipe(
        filter((p) => p.has('id')),
        switchMap((params) => this.leadApiSevice.getById(params.get('id'))),
      )
      .subscribe((lead) => {
        this.lead = lead;
        this.buildLeadMenu(this.lead);
      });
  }

  buildLeadMenu(selectedLead: Lead) {
    if (!selectedLead) return;
    this.authService
      .user()
      .pipe(
        map((u) =>
          this.flowLeadMenuService.createMenuVoice(selectedLead.status.code, getPltUserRole(u)).map((voice) => {
            const item: MenuItem = {
              label: PltStatusLabels[voice],
              icon: StatusIcon[voice],
              command: (event: any) => {
                if (!event.label) {
                  return;
                }
                const status = getEnumKeyByEnumValue(PltStatusLabels, event.label);
                //quando fisso effettivamente un appuntamento
                if (status.includes(PltLeadStatusCode.OK_SOPRALLUOGO)) {
                  this.dialog
                    .open(CreateEventLeadModalComponent, {
                      data: {
                        lead: selectedLead,
                        code: PltLeadStatusCode.OK_SOPRALLUOGO,
                        type: PltEventType.SOPRALLUOGO,
                      },
                    })
                    .afterClosed()
                    .subscribe((_) => this.getData());
                } else {
                  this.dialog
                    .open(MoveLeadStateComponent, {
                      data: {
                        lead: selectedLead,
                        code: status,
                      },
                    })
                    .afterClosed()
                    .subscribe((_) => this.getData());
                }
              },
            };
            return item;
          }),
        ),
      )
      .subscribe((items) => {
        this.leadStateList = items;
      });
  }
}
