<header class="page-header">
  <h2 class="tx-title-2">Preventivi</h2>
  <div class="flex justify-between">
    <p class="text-gray-600">Lista e gestione preventivi</p>

    <div
      pTooltip="Puoi creare preventivi solo dopo aver definito un intestario e aver impostato un metodo di pagamento dai dettagli del lead"
    >
      <button
        mat-raised-button
        color="primary"
        (click)="createEstimate()"
        [disabled]="!opportunity?.buyer || !opportunity?.cache?.lead.concern"
      >
        <mat-icon>add</mat-icon>
        Nuovo preventivo
      </button>
    </div>
  </div>
</header>

<fullstack-datatable [list]="estimates" [cmItems]="cmItems" (selected)="selected = $event">
  <div t-header>
    <div t-column [name]="'Nome'"></div>
    <div t-column [name]="'Metodo pagamento'"></div>
    <div t-column [name]="'Stato'"></div>
    <div t-column [name]="'Totale'"></div>
  </div>

  <ng-template t-body let-row>
    <td class="font-mono">{{ row?.name }}</td>
    <td>{{ paymentTypeLabels[row?.financing] }}</td>
    <td>{{ row?.state | estimateState }}</td>
    <td>{{ row?.totalDiscounted || row?.totalCost || 0 | currency: 'EUR' }}</td>
  </ng-template>
</fullstack-datatable>
