<ng-template header-body>
  <div class="text-end align-items-center">
    <button class="btn btn-header" [routerLink]="['./detail']">
      <i class="fas fa-plus cl-medium fa-1x"></i>
    </button>
  </div>
</ng-template>

<fullstack-datatable
  [list]="sources"
  [cmItems]="cmItems"
  (selected)="selected = $event"
>
  <div t-header>
    <div t-column [name]="'Codice'" width="40px">
    </div>
    <div t-column [name]="'Nome'">
    </div>
    <div t-column [name]="'Tipo'">
    </div>
    <div t-column [name]="'Gruppo'">
    </div>
    <div t-column [name]="'Campagna'">
    </div>
    <div t-column [name]="'Campagna attiva'">
    </div>
  </div>

  <ng-template t-body let-row>
    <td class="text-center">{{ row?.code }}</td>
    <td>{{ row?.label }}</td>
    <td>{{ row?.type | domainValue : "source_lead_type" | async }}</td>
    <td>{{ row?.group | domainValue : "source_group_type" | async }}</td>
    <td>{{ row?.campaign }}</td>
    <td>{{ row?.isCampaignActive }}</td>
  </ng-template>
</fullstack-datatable>
