export enum PltLeadStatusCode {
  DA_CONTATTARE = 'DA_CONTATTARE', //OK
  DA_RICHIAMARE = 'DA_RICHIAMARE', //OK
  DUPLICATO_RIFIUTATO = 'DUPLICATO_RIFIUTATO', //nuovo
  DUPLICATO_ACCETTATO = 'DUPLICATO_ACCETTATO', //nuovo
  KO_SOLO_INFO = 'KO_SOLO_INFO',
  KO_CITTA = 'KO_CITTA',
  KO_CREDITO = 'KO_CREDITO',
  OK_SOPRALLUOGO = 'OK_SOPRALLUOGO', //azione
  IN_OPPORTUNITA = 'IN_OPPORTUNITA', //stato nuovo da usare quando viene creata un opportunità
}

export enum PltOpportunityStatusCode {
  SOPRALLUOGO_FISSATO = 'SOPRALLUOGO_FISSATO', //OK
  SOPRALLUOGO_EFFETTUATO = 'SOPRALLUOGO_EFFETTUATO', //OK
  PROPOSTA = 'PROPOSTA',
  TRATTATIVA = 'TRATTATIVA',
  KO_TECNICO = 'KO_TECNICO', //OK
  KO_PREZZO = 'KO_PREZZO', //OK
  KO_RIPENSAMENTO = 'KO_RIPENSAMENTO', //OK
  PENDING_ATTESA_DOCUMENTI = 'PENDING_ATTESA_DOCUMENTI', //nuovo
  PENDING_ATTESA_PAGAMENTO = 'PENDING_ATTESA_PAGAMENTO', //nuovo
  PENDING_ATTESA_FINANZIAMENTO = 'PENDING_ATTESA_FINANZIAMENTO', //nuovo
  KO_MANCANZA_DOCUMENTI = 'KO_MANCANZA_DOCUMENTI', //OK
  KO_ATTESA_PAGAMENTO = 'KO_ATTESA_PAGAMENTO', //OK
  KO_FINANZIAMENTO = 'KO_FINANZIAMENTO', //OK
  KO_REPERIBILITA_OPPORTUNITA = 'KO_REPERIBILITA_OPPORTUNITA', //OK
  APPUNTAMENTO_DA_FISSARE = 'APPUNTAMENTO_DA_FISSARE',
  DA_INSTALLARE = 'DA_INSTALLARE',
  INSTALLAZIONE_OK_DOCUMENTI_PENDING = 'INSTALLAZIONE_OK_DOCUMENTI_PENDING',
  INSTALLAZIONE_OK_DOCUMENTI_OK = 'INSTALLAZIONE_OK_DOCUMENTI_OK',
  PRATICA_CHIUSA = 'PRATICA_CHIUSA',
}

export enum PltStatusLabels {
  DA_CONTATTARE = 'Da contattare', //OK
  DA_RICHIAMARE = 'Da richiamare', //OK
  DUPLICATO_RIFIUTATO = 'Duplicato rifiutato', //nuovo
  DUPLICATO_ACCETTATO = 'Duplicato accettato', //nuovo
  KO_SOLO_INFO = 'Ko solo info', //OK
  KO_CITTA = 'Ko città', //OK
  KO_CREDITO = 'Ko credito', //OK
  OK_SOPRALLUOGO = 'Ok sopralluogo',
  SOPRALLUOGO_FISSATO = 'Sopralluogo fissato',
  KO_TECNICO = 'Ko tecnico', //OK
  KO_PREZZO = 'Ko prezzo', //OK
  KO_RIPENSAMENTO = 'Ko ripensamento', //OK
  SOPRALLUOGO_EFFETTUATO = 'Sopralluogo effettuato', //ok
  PREVENTIVO_PRESENTATO = 'Preventivo presentato',
  PROPOSTA = 'Proposta',
  TRATTATIVA = 'In trattativa',
  IN_OPPORTUNITA = 'In opportunità',
  PENDING_ATTESA_DOCUMENTI = 'Attesa documenti', //nuovo
  PENDING_ATTESA_PAGAMENTO = 'Attesa pagamento', //nuovo
  PENDING_ATTESA_FINANZIAMENTO = 'Attesa finanziamento', //nuovo
  KO_ATTESA_DOCUMENTI = 'Ko attesa documenti',
  KO_MANCANZA_DOCUMENTI = 'Ko mancanza documenti',
  KO_ATTESA_PAGAMENTO = 'Ko attesa pagamento',
  KO_FINANZIAMENTO = 'Ko finanziamento',
  KO_REPERIBILITA_OPPORTUNITA = 'Ko reperibilità opportunità',
  APPUNTAMENTO_DA_FISSARE = 'Installazione da fissare',
  DA_INSTALLARE = 'Da installare',
  INSTALLAZIONE_OK_DOCUMENTI_PENDING = 'Installazione ok attesa documenti',
  INSTALLAZIONE_OK_DOCUMENTI_OK = 'Installazione ok documenti ok ',
  PRATICA_CHIUSA = 'Pratica chiusa',
}

export enum StatusIcon {
  DA_CONTATTARE = 'pi pi-check-circle',
  DA_RICHIAMARE = 'pi pi-check-circle',
  DUPLICATO_RIFIUTATO = 'pi pi-ban',
  DUPLICATO_ACCETTATO = 'pi pi-ban',
  KO_SOLO_INFO = 'pi pi-ban',
  KO_CITTA = 'pi pi-ban',
  KO_CREDITO = 'pi pi-ban',
  OK_SOPRALLUOGO = 'pi pi-check-circle',
  SOPRALLUOGO_FISSATO = 'pi pi-calendar',
  KO_TECNICO = 'pi pi-ban',
  KO_PREZZO = 'pi pi-ban',
  KO_RIPENSAMENTO = 'pi pi-ban',
  SOPRALLUOGO_EFFETTUATO = 'pi pi-check-circle',
  PROPOSTA = 'pi pi-check-circle',
  TRATTATIVA = 'pi pi-check-circle',
  IN_OPPORTUNITA = 'pi pi-check-circle',
  PENDING_ATTESA_DOCUMENTI = 'pi pi-check-circle',
  PENDING_ATTESA_PAGAMENTO = 'pi pi-check-circle',
  PENDING_ATTESA_FINANZIAMENTO = 'pi pi-check-circle',
  KO_ATTESA_DOCUMENTI = 'pi pi-ban',
  KO_MANCANZA_DOCUMENTI = 'pi pi-ban',
  KO_ATTESA_PAGAMENTO = 'pi pi-ban',
  KO_FINANZIAMENTO = 'pi pi-ban',
  KO_REPERIBILITA_OPPORTUNITA = 'pi pi-ban',
  APPUNTAMENTO_DA_FISSARE = 'pi pi-check-circle',
  DA_INSTALLARE = 'pi pi-calendar',
  INSTALLAZIONE_OK_DOCUMENTI_PENDING = 'pi pi-check-circle',
  INSTALLAZIONE_OK_DOCUMENTI_OK = 'pi pi-check-circle',
  PRATICA_CHIUSA = 'pi pi-check-circle',
}
