export interface IProduct {
  _id: string;
  name: string;
  description: string;
  category: number;
  price: number;
}

export enum ProductCategory {
  'MATERIALI' = 1,
  'INSTALLAZIONE' = 2,
  'MARKETING' = 3,
  'CALLCENTER' = 4,
  'MARGINALITA' = 5,
}
