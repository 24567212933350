import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PromotionsService } from '../../../promotions.service';
import { IProduct, IPromoItem, IPromotion } from '@renovars/common/plenitude';
import { ProductsService } from '@fi/app/features/products/products.service';
import { map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'promotions-detail',
  templateUrl: './promotions-detail.component.html',
})
export class PromotionsDetailComponent implements OnInit {
  id: string;
  form = this.fb.group({
    promoForm: this.fb.control(null, []),
  });
  sourceProducts: IProduct[];
  targetProducts: IProduct[];

  selectedItems: IPromoItem[];
  availableItems: IPromoItem[];

  constructor(
    protected fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private promoApi: PromotionsService,
    private productsApi: ProductsService,
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((p) => {
      this.id = p.id;
      if (this.id) {
        this.promoApi
          .findById(this.id)
          .pipe(
            tap((res) => {
              this.form.get('promoForm').patchValue(res);
            }),
            switchMap((promotion) =>
              this.productsApi.browse(null).pipe(map((productsRes) => ({ promotion, allProducts: productsRes.items }))),
            ),
          )
          .subscribe(({ promotion, allProducts }) => {
            this.selectedItems = promotion.products;

            this.targetProducts = allProducts.filter((prod) =>
              promotion.products.map((r) => r.product).includes(prod._id),
            );
            this.sourceProducts = allProducts.filter(
              (prod) => !this.selectedItems.map((p) => (p.product as IProduct)._id).includes(prod._id),
            );

            this.availableItems = this.sourceProducts.map((r) => {
              return {
                product: r,
                quantity: 1,
              };
            });
          });
      } else {
        this.selectedItems = [];
        this.productsApi.browse(null).subscribe((value) => {
          this.sourceProducts = value.items;

          this.availableItems = this.sourceProducts.map((r) => {
            return {
              product: r,
              quantity: 1,
            };
          });
        });
      }
    });
  }

  save() {
    if (!this.form.valid) return;
    const obj: IPromotion = this.form.get('promoForm').value;
    obj.products = this.selectedItems;
    if (this.id) {
      this.promoApi.update(this.id, obj).subscribe(() => this.router.navigate(['/settings/promotions']));
    } else {
      const { _id, ...rest } = obj;
      this.promoApi.save(rest).subscribe(() => this.router.navigate(['/settings/promotions']));
    }
  }

  voidDblClick(event: Event) {
    event.stopPropagation();
  }
}
