import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { UiModule } from '../../../libs/fe/ui-ng/src/lib/ui.module';
import { DomainValuesModule } from '../../../libs/fe/core-ng/src/lib/features/domain-values';
import { ProductsFormComponent } from './components/forms/products-form.component';
import { ProductsDetailComponent } from './components/pages/detail/products-detail.component';
import { ProductsListComponent } from './components/pages/list/products-list.component';
import { ProductPageComponent } from './components/pages/products-page.component';
import { ProductsService } from './products.service';

const ROUTES: Routes = [
  {
    path: '',
    component: ProductPageComponent,
    children: [
      { path: '', component: ProductsListComponent },
      { path: 'detail', component: ProductsDetailComponent },
    ],
  },
];

@NgModule({
  declarations: [ProductPageComponent, ProductsListComponent, ProductsDetailComponent, ProductsFormComponent],
  exports: [],
  imports: [
    UiModule,
    CommonModule,
    FormsModule,
    DomainValuesModule,
    ReactiveFormsModule,
    RouterModule.forChild(ROUTES),
  ],
  providers: [ProductsService],
})
export class ProductsModule {}
