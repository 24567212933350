import { Component, OnInit, forwardRef } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {
  BoilerAnswer,
  BoilerAnswerLabel,
  BoilerStartAnswer,
  BoilerStartAnswerLabel,
  FloorAnswer,
  FloorAnswerLabel,
  IsIndipendentAnswer,
  IsIndipendentAnswerLabel,
  MqAnswer,
  MqAnswerLabel,
  Profile,
  RadiatorsAnswer,
  RadiatorsAnswerLabel,
  RoomsAnswer,
  RoomsAnswerLabel,
} from '@renovars/common/plenitude';
import { FormElement } from '@renovars/ui-ng';

@Component({
  selector: 'profile-form',
  template: `
    <form [formGroup]="profileForm" novalidate>
      <div class="row mt-4">
        <div class="col-sm-6 col-md-6 pt-4">
          <fullstack-input-select-box
            formControlName="propietario"
            [options]="{ label: 'Lei è il proprietario dell\\'immobile ?' }"
          >
            <sb-option [value]="true">Si</sb-option>
            <sb-option [value]="false">No</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-6 col-md-6 pt-4">
          <fullstack-input-select-box
            formControlName="indipendente"
            [options]="{ label: 'E\\' una casa indipendente o un condominio ?' }"
          >
            <sb-option [value]="isIndipendetAnswer.casa_indipendente">{{
              isIndipendetAnswerLabel[isIndipendetAnswer.casa_indipendente]
            }}</sb-option>
            <sb-option [value]="isIndipendetAnswer.condominio">{{
              isIndipendetAnswerLabel[isIndipendetAnswer.condominio]
            }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-6 col-md-6 pt-4">
          <fullstack-input-select-box formControlName="piano" [options]="{ label: 'A che piano ?' }">
            <sb-option [value]="floorAnswer.terra">{{ floorAnsewerLabel.terra }}</sb-option>
            <sb-option [value]="floorAnswer.primo">{{ floorAnsewerLabel.primo }}</sb-option>
            <sb-option [value]="floorAnswer.secondo">{{ floorAnsewerLabel.secondo }}</sb-option>
            <sb-option [value]="floorAnswer.terzo">{{ floorAnsewerLabel.terzo }}</sb-option>
            <sb-option [value]="floorAnswer.quarto">{{ floorAnsewerLabel.quarto }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-6 col-md-6 pt-4">
          <fullstack-input-select-box
            formControlName="balconi"
            [options]="{
              label: 'Ci sono balconi ?'
            }"
          >
            <sb-option [value]="true">Si</sb-option>
            <sb-option [value]="false">No</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-12 col-md-12 pt-4">
          <fullstack-input-select-box
            formControlName="mq"
            [options]="{ label: 'Quanti metri quadri è il suo immobile ?' }"
          >
            <sb-option [value]="mqAnswer.minore_di_120mq">{{ mqAnswerLabel[mqAnswer.minore_di_120mq] }}</sb-option>
            <sb-option [value]="mqAnswer.maggiore_di_180mq">{{ mqAnswerLabel[mqAnswer.maggiore_di_180mq] }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-12 col-md-12 pt-4">
          <fullstack-input-multiple-select-box
            [options]="{ label: 'Di quante camere è composto l\\'immobile ?' }"
            formControlName="camere"
            [values]="roomsAnswer"
            [key]="'value'"
          >
            <sb-option *ngFor="let t of roomsAnswer" [value]="t.value">{{ t.label }}</sb-option>
          </fullstack-input-multiple-select-box>
        </div>
        <div class="col-sm-6 col-md-6 pt-4">
          <fullstack-input-select-box
            formControlName="posizione_caldaia"
            [options]="{ label: 'La caldaia è installata dentro o fuori ?' }"
          >
            <sb-option [value]="boilerAnswer.dentro">{{ boilerAnswerLabel[boilerAnswer.dentro] }}</sb-option>
            <sb-option [value]="boilerAnswer.fuori">{{ boilerAnswerLabel[boilerAnswer.fuori] }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-6 col-md-6 pt-4">
          <fullstack-input-select-box
            formControlName="accensione_caldaia"
            [options]="{ label: 'Come accende i termosifoni dall\\'interno della casa o direttamente dalla caldaia ?' }"
          >
            <sb-option [value]="boilerStartAnswer.caldaia">{{
              boilerStartAnswerLabel[boilerStartAnswer.caldaia]
            }}</sb-option>
            <sb-option [value]="boilerStartAnswer.dentro">{{
              boilerStartAnswerLabel[boilerStartAnswer.dentro]
            }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-6 col-md-6 pt-4">
          <fullstack-input-select-box
            formControlName="termosifoni"
            [options]="{ label: 'Quanti termosifoni ha in casa ( esclusi i bagni ) ?' }"
          >
            <sb-option [value]="radiatorsAnswer.minore_di_4_termosifoni">{{
              radiatorsAnswerLabel[radiatorsAnswer.minore_di_4_termosifoni]
            }}</sb-option>
            <sb-option [value]="radiatorsAnswer.maggiore_di_5_termosifoni">{{
              radiatorsAnswerLabel[radiatorsAnswer.maggiore_di_5_termosifoni]
            }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-6 col-md-6 pt-4">
          <fullstack-input-select-box
            formControlName="climatizzatori_installati"
            [options]="{ label: 'Ha in regalo un climatizzare, ha già altri climatizzatori installati ?' }"
          >
            <sb-option [value]="true">Si</sb-option>
            <sb-option [value]="false">No</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-12 col-md-12 pt-4">
          <fullstack-input-multiple-select-box
            [options]="{ label: 'Quanti climatizzatori ha, in quali camere ?' }"
            formControlName="camere_climatizzatori"
            [values]="roomsAnswer"
            [key]="'value'"
          >
            <sb-option *ngFor="let t of roomsAnswer" [value]="t.value">{{ t.label }}</sb-option>
          </fullstack-input-multiple-select-box>
        </div>
      </div>
    </form>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProfileFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ProfileFormComponent,
      multi: true,
    },
  ],
})
export class ProfileFormComponent extends FormElement implements OnInit {
  profileForm: UntypedFormGroup;
  isIndipendetAnswer = IsIndipendentAnswer;
  isIndipendetAnswerLabel = IsIndipendentAnswerLabel;
  floorAnswer = FloorAnswer;
  floorAnsewerLabel = FloorAnswerLabel;
  mqAnswer = MqAnswer;
  mqAnswerLabel = MqAnswerLabel;
  boilerAnswer = BoilerAnswer;
  boilerAnswerLabel = BoilerAnswerLabel;
  boilerStartAnswer = BoilerStartAnswer;
  boilerStartAnswerLabel = BoilerStartAnswerLabel;
  radiatorsAnswer = RadiatorsAnswer;
  radiatorsAnswerLabel = RadiatorsAnswerLabel;
  roomsAnswer = [
    {
      label: RoomsAnswerLabel[RoomsAnswer.cucina_salone],
      value: RoomsAnswer.cucina_salone,
    },
    {
      label: RoomsAnswerLabel[RoomsAnswer.salone],
      value: RoomsAnswer.salone,
    },
    {
      label: RoomsAnswerLabel[RoomsAnswer.camera_patronale],
      value: RoomsAnswer.camera_patronale,
    },
    {
      label: RoomsAnswerLabel[RoomsAnswer.camera_2],
      value: RoomsAnswer.camera_2,
    },
    {
      label: RoomsAnswerLabel[RoomsAnswer.camera_3],
      value: RoomsAnswer.camera_3,
    },
    {
      label: RoomsAnswerLabel[RoomsAnswer.camera_4],
      value: RoomsAnswer.camera_4,
    },
  ];
  constructor(private fb: FormBuilder) {
    super();
  }
  ngOnInit(): void {
    //Profile
    this.profileForm = this.fb.group({
      propietario: new UntypedFormControl(),
      indipendente: new UntypedFormControl(),
      piano: new UntypedFormControl(),
      balconi: new UntypedFormControl(),
      mq: new UntypedFormControl(),
      camere: new UntypedFormControl(),
      posizione_caldaia: new UntypedFormControl(),
      accensione_caldaia: new UntypedFormControl(),
      termosifoni: new UntypedFormControl(),
      climatizzatori_installati: new UntypedFormControl(),
      camere_climatizzatori: new UntypedFormControl(),
    });
    this.profileForm.valueChanges.subscribe((value) => this.propagateChange(value));
  }
  propagateChange = (_: Profile) => {};
  writeValue(obj: Profile): void {
    this.profileForm.patchValue(obj);
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
}
