import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import * as _ from 'lodash';

@Pipe({ name: 'historyDates' })
export class HistoryDatesPipe implements PipeTransform {
  transform(value: any[], criteria: any) {
    let res: any[] = [];
    if (value && criteria) {
      res = value.filter((v) => {
        let cKeys = Object.keys(criteria);
        return cKeys.every((ck) => {
          if (v.newState) {
            return _.get(v.newState, ck) == criteria[ck];
          }
        });
      });
    }

    return res.reduce((a, b) => a + dayjs(b.insertDate).format('LL'), '');
  }
}
