<div [formGroup]="form">
  <div>
    <div class="row">
      <div class="col-12">
        <header class="page-header">
          <h2 class="tx-title-2">Dati della sede</h2>
        </header>
      </div>
    </div>
    <div class="row pt-4">
      <div class="col-sm-6">
        <fullstack-input-box [options]="{ label: 'Nome Sede' }" formControlName="name"></fullstack-input-box>
      </div>
      <div class="col-sm-6">
        <fullstack-input-address-box [options]="{ label: 'Indirizzo' }" formControlName="address">
        </fullstack-input-address-box>
      </div>
      <div class="col-sm-6 pt-2">
        <fullstack-input-box
          [options]="{ label: 'Descrizione' }"
          formControlName="description"
        ></fullstack-input-box>
      </div>
      <div class="col-sm-6 pt-2" *ngIf="phoneVisibile">
        <fullstack-input-box [options]="{ label: 'Telefono' }" formControlName="phone"></fullstack-input-box>
      </div>
      <div class="col-sm-6 pt-2" *ngIf="bcCodeVisible">
        <fullstack-input-box
          [options]="{ label: 'Oggetto di costo BC' }"
          formControlName="bcCode"
        ></fullstack-input-box>
      </div>
      <div class="col-sm-6 pt-2" *ngIf="areaVisibile">
        <fullstack-input-select-box
          [options]="{ label: 'Area' }"
          formControlName="area"
        >
        <sb-option  *ngFor="let number of [1,2,3,4,5,6]" [value]="number">{{number}}</sb-option>

      </fullstack-input-select-box>
      </div>
      <div class="col-sm-12 pt-2">
        <fullstack-input-multiple-select-box
        [options]="{ label: 'Codice Campagna:' }"
        formControlName="campaignCodes"
        [values]="codes"
        [key]="'label'">
          <sb-option *ngFor="let t of codes" [value]="t.value">{{ t.value }}</sb-option>
        </fullstack-input-multiple-select-box>
      </div>
    </div>
  </div>
</div>
