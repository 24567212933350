import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CommonRoles, IAddress, IUser } from '@renovars/common';
import { Lead, PltLeadStatusCode, PltRoles } from '@renovars/common/plenitude';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import { concatAll, filter, Observable, toArray } from 'rxjs';
import { LeadApiService } from '../lead-api';
import { MoveLeadStateComponent } from './move-lead-state.component';

@Component({
  selector: 'assign-lead-modal',
  template: `
    <div mat-dialog-content>
      <div class="mb-2 tx-title-1">Assegna il lead</div>
      <div class="row">
        <div class="col">
          <fullstack-input-select-box [options]="{ label: 'Ricerca per utente' }" [(ngModel)]="selected">
            <sb-option [key]="'id'" [value]="i" *ngFor="let i of ccOperators$ | async">{{
              i.fullName || i.firstName + ' ' + i.lastName
            }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col">
          <fullstack-input-address-box [options]="{ label: 'Luogo appuntamento' }" [(ngModel)]="address">
          </fullstack-input-address-box>
        </div>
        <div class="col">
          <fullstack-datepicker
            [(ngModel)]="date"
            [options]="{ label: 'Data appuntamento' }"
          ></fullstack-datepicker>
        </div>

        <div class="col-12 mt-1">
          <fullstack-input-textarea [options]="{ label: 'Note', rows: 2 }" [(ngModel)]="note">
          </fullstack-input-textarea>
        </div>
      </div>
      <div class="text-end mt-2">
        <button type="button" class="btn btn-default rounded m-1" (click)="annulla()">Annulla</button>

        <button type="submit" class="btn btn-warning rounded m-1" (click)="save()">Assegna</button>
      </div>
    </div>
  `,
  styles: [],
})
export class AssignLeadModalComponent implements OnInit {
  lead: Lead;
  note: string;
  date: Date;
  ccOperators$: Observable<IUser[]>;
  selected: string;
  address: IAddress;
  constructor(
    public dialogRef: MatDialogRef<MoveLeadStateComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { code: PltLeadStatusCode; lead: Lead },
    private flowService: LeadApiService,
    private usersService: UsersService
  ) {}
  ngOnInit(): void {
    this.lead = this.data.lead;
    this.ccOperators$ = this.usersService
      .getUsersListDownline([CommonRoles.IS_ADMIN, PltRoles.is_cc_operator], [])
      .pipe(
        concatAll(),
        filter((u) => u.registrations.some((reg) => reg.roles.includes(PltRoles.is_technical_coordinator))),
        toArray()
      );
  }
  save() {
    const payload = {
      assigned: this.selected,
      date: this.date,
      address: this.address,
      note: this.note,
    };
    this.flowService.assignLead(this.lead._id, payload).subscribe(() => this.close(null));
  }
  close(event) {
    this.dialogRef.close(event);
  }
  annulla() {
    this.dialogRef.close(null);
  }
}
