import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../../environments/environment';
import { PltCalendarComponent } from './plt-calendar.component';
import { SharedModule } from '../../shared.module';
import { PltCalendarRoutingModule } from './plt-calendar-routing.module';
import { PltEventModal } from './plt-event-modal';
import { PipesModule } from '../../pipes/pipes.module';
import { SharedCoreModule, CalendarioModule } from '@renovars/core-ng';

@NgModule({
  declarations: [PltCalendarComponent, PltEventModal],
  imports: [
    CommonModule,
    SharedModule,
    SharedCoreModule,
    PltCalendarRoutingModule,
    PipesModule,
    CalendarioModule.forRoot({
      eventModalClass: PltEventModal,
      eventsEndpoint: `${environment.apiGatewayURL}/events`,
    }),
  ],
})
export class PltCalendarModule {}
