import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { IObjectList } from '@renovars/common';
import { IFiDomainValue } from '@renovars/common/facileimmobiliare';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomainValuesOptions, DOMAIN_VALUES_CONFIG_PROVIDER } from '../features/domain-values/module.options';
import { CacheEnum, cacheResponse } from '../utils/cache.utils';

@Injectable()
export class DomainValuesService {
  constructor(private http: HttpClient, @Inject(DOMAIN_VALUES_CONFIG_PROVIDER) private config: DomainValuesOptions) {}

  list(): Observable<any[]> {
    return cacheResponse(
      CacheEnum.DOMAIN_VALUES_CACHE,
      this.http.get<IObjectList>(`${this.config.apiApplicationURL}/domainvalues`).pipe(map((res) => res.data)),
    );
  }

  findByTypeWithMetadata(type: string): Observable<any[]> {
    return this.http
      .post<IObjectList>(`${this.config.apiApplicationURL}/domainvalues/findByTypeWithMetadata`, { type })
      .pipe(map((r) => r.data));
  }

  findByTypeAndCodeWithMetadata(type: string, code: number): Observable<any> {
    return this.http
      .post<IFiDomainValue>(`${this.config.apiApplicationURL}/domainvalues/findByTypeAndCodeWithMetadata`, {
        type,
        code,
      })
      .pipe(map((r) => r));
  }

  updateMetadataByTypeAndCode(type: string, code: number, data: unknown): Observable<any> {
    return this.http
      .put<IFiDomainValue>(`${this.config.apiApplicationURL}/domainvalues/updateMeta`, { type, code, data })
      .pipe(map((r) => r));
  }
}
