import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IUpdateLead } from '@renovars/common/plenitude';
import { filter, map, switchMap } from 'rxjs';
import { OpportunityApiService } from '../opportunity-api.service';
import { environment } from '@fi/environment/environment';

@Component({
  selector: 'opportunity-from-new-contact-form',
  template: `
    <form [formGroup]="form" novalidate>
      <fullstack-contact-form
        formControlName="contact"
        [formInfoState]="form.get('contact').errors"
        [options]="{ showCompany: true, showBillingAddress: false }"
      >
      </fullstack-contact-form>
      <small *ngIf="form.get('contact').errors" style="color: red"> Almeno un telefono o una email </small>
      <lead-form formControlName="lead"></lead-form>
    </form>
    <div class="row mt-4">
      <div class="col-sm-12 text-end">
        <button type="button" class="btn btn-primary rounded me-4" [routerLink]="['/flows']">Annulla</button>
        <button type="button" class="btn btn-secondary rounded" (click)="update()">Salva</button>
      </div>
    </div>
  `,
  styles: [],
})
export class OpportunityUpsertFormComponent implements OnInit {
  environment = environment;
  form: UntypedFormGroup;
  leadId: string;
  contactId: string;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private pltOpportunityService: OpportunityApiService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      contact: new UntypedFormControl(null, []),
      lead: new UntypedFormControl(null, []),
    });
    this.route.queryParams
      .pipe(
        filter((params) => params && params.id),
        map((params) => params.id),
        switchMap((id) => this.pltOpportunityService.getById(id)),
      )
      .subscribe((opportunity) => {
        if (opportunity) {
          this.form.get('contact').patchValue(opportunity.cache.contact);
          this.form.get('lead').patchValue(opportunity.cache.lead);
          this.leadId = opportunity.lead;
          this.contactId = opportunity.contact;
        }
      });
  }
  update() {
    const payload: IUpdateLead = {
      contact: { ...this.form.get('contact').value, _id: this.contactId },
      lead: this.form.get('lead').value,
    };
    this.pltOpportunityService.updateLead(this.leadId, payload).subscribe();
  }
  goToList() {
    this.router.navigate(['flows']);
  }
}
