import { animate, state, style, transition, trigger } from '@angular/animations';
import { DataSource } from '@angular/cdk/collections';
import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { IAccordionTableElement } from '@renovars/common';

@Component({
  selector: 'renovars-accordion-table',
  templateUrl: './accordion-table.component.html',
  styleUrls: ['./accordion-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AccordionTableComponent {
  @Input() dataSource: DataSource<IAccordionTableElement[]>;
  @Input() columns: string[];

  expandedElement: IAccordionTableElement | null;
}
