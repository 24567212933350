import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IUpdateLead } from '@renovars/common/plenitude';
import { contactFormValidator } from '@renovars/core-ng';
import { isEmpty, merge } from 'lodash';
import { filter, of, skipWhile, switchMap, tap } from 'rxjs';
import { LeadApiService } from '../lead-api';
import { environment } from '@fi/environment/environment';

export const leadFormValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const errors = {};
  const message = 'Il campo è obbilgatorio';
  if (control && control.value) {
    if (!control.value.addressOp) {
      merge(errors, { addressOp: { required: true, error: message } });
    }
    if (!control.value.forProduct) {
      merge(errors, { forProduct: { required: true, error: message } });
    }
    if (!control.value.callPreference) {
      merge(errors, { callPreference: { required: true, error: message } });
    }
  }
  return isEmpty(errors) ? null : errors;
};

@Component({
  selector: 'lead-upsert-form',
  template: `
    <form [formGroup]="form" novalidate>
      <fullstack-contact-form
        formControlName="contact"
        [formInfoState]="form.get('contact').errors"
        [options]="{ showCompany: true, showBillingAddress: false }"
      >
      </fullstack-contact-form>
      <small *ngIf="form.get('contact').errors" style="color: red"> Almeno un telefono o una email </small>
      <lead-form formControlName="lead"></lead-form>
    </form>
    <div class="row mt-4">
      <div class="col-sm-12 text-end">
        <button type="button" class="btn btn-primary rounded me-4" [routerLink]="['/flows']">Annulla</button>
        <button type="button" class="btn btn-secondary rounded" (click)="save()" [disabled]="!form.valid">Salva</button>
        <button class="btn btn-secondary  rounded ms-4" (click)="goToDetail()" *ngIf="duplicateId">
          Link all'originale
        </button>
      </div>
    </div>
  `,
  styles: [],
})
export class LeadUpsertFormComponent implements OnInit {
  environment = environment;
  form: UntypedFormGroup;
  id: string;
  contactId: string;
  duplicateId: string;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private pltFlowService: LeadApiService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      contact: new UntypedFormControl(null, [contactFormValidator]),
      lead: new UntypedFormControl(null, []),
    });
    this.route.queryParamMap
      .pipe(
        filter((params) => params.has('id')),
        switchMap((params) => this.pltFlowService.getById(params.get('id'))),
        skipWhile((f) => !f),
        tap((f) => (this.id = f._id ?? null)),
      )
      .subscribe((lead) => {
        this.contactId = lead.contact;
        this.form.get('contact').setValue(lead.cache.contact);
        this.form.get('lead').setValue(lead);
      });
  }
  save() {
    if (this.id) {
      const payload: IUpdateLead = {
        contact: { ...this.form.get('contact').value, _id: this.contactId },
        lead: this.form.get('lead').value,
      };
      this.pltFlowService.updateLead(this.id, payload).subscribe(console.log);
    } else {
      const payload = { contact: this.form.get('contact').value, ...this.form.get('lead').value };
      this.pltFlowService.createFlow(payload).subscribe((value) => {
        if (value.status === 'OK') {
          this.goToList();
        } else if (value.status === 'DUPLICATE') {
          this.duplicateId = value.duplicate;
        }
      });
    }
  }
  goToList() {
    this.router.navigate(['flows']);
  }
  goToDetail() {
    this.pltFlowService.recallLead(this.duplicateId).subscribe((result) => {
      if (result) {
        this.router.navigateByUrl(`flows/details/upsert?id=${this.duplicateId}`);
        this.duplicateId = null;
      }
    });
  }
}
