export enum FiPermissions {
  LEAD_MANAGER = 'lead_manager',
  DASHBOARD_MANAGER = 'dashboard_manager',
  DASHBOARD_CALLCENTER_MANAGER = 'dashboard_callcenter_manager',
  CONTACT_MANAGER = 'contact_manager',
  OPPORTUNITY_MANAGER = 'opportunity_manager',
  EVENT_MANAGER = 'event_manager',
  NETWORK_MANAGER = 'network_manager',
  USER_MANAGER = 'user_manager',
  CAN_READ_USERS_LIST = 'can_read_users_list',
  ACCOUNTING_MANAGER = 'accounting_manager',
  PURCHASE_MANAGER = 'purchase_manager',
}
