const appointmentDisputeTemplate = /*html*/ `<fullstack-header *ngIf="!headerLess"></fullstack-header>

  <ng-template *ngIf="!headerLess" header-body>

    <h1 class="tx-title-1">Contestazioni</h1>

    <fullstack-checkbox-button
      [ngModel]="paginateRequest.form.disputeState || null"
      (ngModelChange)="paginateRequest.form.disputeState = $event ? [$event] : null; search()"
    >
      <checkbox *ngFor="let state of disputeStatesList" [label]="state.label" [value]="state.code"></checkbox>
      <checkbox [label]="'Tutti'" [value]="null"></checkbox>
    </fullstack-checkbox-button>

    <!-- <h2 class="tx-subtitle mb-0"></h2> -->
    <div class="text-end align-items-center">
    <button class="btn btn-header" (click)="downloadCsv('event')">
      <i class="fas fa-download cl-medium fa-1x"></i>
    </button>
    </div>
  </ng-template>

  <div class="d-flex flex-row-reverse p-2">
  <fullstack-input-select-box
    [options]="{ label: 'Network' }"
    ngModel
    (ngModelChange)="setNetworkFilter($event); loadAgentList($event)"
  >
    <sb-option [key]="'id'" [value]="elem" *ngFor="let elem of networkList$ | async"
      >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
    >
  </fullstack-input-select-box>
</div>
<fullstack-datatable
    [options]="{lazy : true}"
    (lazyLoad)="onLazyLoad($event)"
    [list]="eventsList?.data"
    [totalRecords]="eventsList?.total"
    (selected)="selected = $event; onSelect()"
    [cmItems]="cmItems"
    [paginateRequest]="paginateRequest"
    [showColumnSelector]="true"
    [visibleColumns]="visibleColumns"
    (selectedColumnsChoosed)="saveSelectedColumn($event)"
    (rowsChange)="saveRowCount($event)"
    [maxRows]="maxRows"
  >
    <div t-header>
      <!--<div t-column [name]="'Nominativo'" [width]="'250px'">
        <fullstack-input-select-box
          *t-filter
          [options]="{autocomplete : true}"
          ngModel
          (ngModelChange)="paginateRequest.form.contactId = $event; search()"
          (inputChange)="searchContact($event)"
        >
          <sb-option [value]="i.id" *ngFor="let i of contacts$ | async"
            >{{ i | contactName}}</sb-option
          >
        </fullstack-input-select-box>
      </div>-->
      <div t-column [name]="'Nome'" field="cache.contact.firstName">
          <fullstack-input-box *t-filter (keyup.enter)="search()" [(ngModel)]="paginateRequest.form.firstName">
          </fullstack-input-box>
      </div>
      <div t-column [name]="'Cognome'" field="cache.contact.lastName">
        <fullstack-input-box *t-filter (keyup.enter)="search()" [(ngModel)]="paginateRequest.form.lastName">
        </fullstack-input-box>
      </div>
      <div t-column [name]="'Data inserimento'" field="createdAt">
        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.insertDateRange = $event; search()">
        </fullstack-daterangepicker>
      </div>
      <div t-column [name]="'Accettata'" field="dispute.accepted">

        <fullstack-input-select-box
        *t-filter
        [options]="{autocomplete : true}"
        ngModel
        (ngModelChange)="paginateRequest.form.disputeAccepted = $event; search()"
        (inputChange)="searchContact($event)"
      >
        <sb-option [value]="true">Si</sb-option>
        <sb-option [value]="false">No</sb-option>
      </fullstack-input-select-box>

      </div>
      <div t-column [name]="'Note contestazione'">
      </div>
      <div t-column [name]="'Telefono'">
        <fullstack-input-box
          *t-filter
          (keyup.enter)="search()"
          [(ngModel)]="paginateRequest.form.phone"
        >
        </fullstack-input-box>
      </div>
      <div t-column [name]="'Email'">
        <fullstack-input-box
          *t-filter
          (keyup.enter)="search()"
          [(ngModel)]="paginateRequest.form.email"
        >
        </fullstack-input-box>
      </div>
      <div t-column [name]="'Indirizzo'">
        <fullstack-input-box
          *t-filter
          (keyup.enter)="search()"
          [(ngModel)]="paginateRequest.form.address"
        >
        </fullstack-input-box>
      </div>
      <div t-column [name]="'Data inizio'" field="start">
        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.eventStartRange = $event; search()">
        </fullstack-daterangepicker>
      </div>
      <div t-column [name]="'Data fine'">
        <fullstack-daterangepicker *t-filter (rangeChange)="paginateRequest.form.eventEndRange = $event; search()">
        </fullstack-daterangepicker>
      </div>
      <div t-column [name]="'Utente'">
        <fullstack-input-select-box
          *t-filter
          [ngModel]="paginateRequest.form.userId"
          (ngModelChange)="paginateRequest.form.userId = $event; search()"
        >
          <sb-option [key]="'id'" [value]="elem" *ngFor="let elem of agentList$ | async"
            >{{ elem?.firstName }} {{ elem?.lastName }}</sb-option
          >
        </fullstack-input-select-box>
      </div>
      <div t-column [name]="'Esito contestazione'">
        <fullstack-input-select-box
          *t-filter
          [ngModel]="paginateRequest.form.disputeState"
          (ngModelChange)="paginateRequest.form.disputeState = $event; search()"
          >
          <sb-option [value]=null>Tutti</sb-option>
          <sb-option [key]="'code'" [value]="i" *ngFor="let i of disputeStatesList">{{ i.label }}</sb-option>
        </fullstack-input-select-box>
      </div>
      <div t-column [name]="'Fonte'">
          <fullstack-input-select-box
          *t-filter
          [options]="{autocomplete : false}"
          [ngModel]="paginateRequest.form.cache"
          (ngModelChange)="paginateRequest.form.cache = {
            projectSale: {
              data: {
                source: $event
              }
            }
          }; search()">
        <sb-option
          [key]="'code'"
          [value]="i"
          *ngFor="let i of domainValuesList$ | async | domainValuesFilter: 'source_lead_type'"
        >{{ i.label }}</sb-option>
        </fullstack-input-select-box>
      </div>
    </div>

    <ng-template t-body let-row>
      <!--<td t-cell [name]="'Nominativo'">{{row.contact | contactName}}</td>-->
      <td t-cell [name]="'Nome'">{{ row.cache?.contact?.firstName }}</td>
      <td t-cell [name]="'Cognome'">{{ row.cache?.contact?.lastName }}</td>
      <td t-cell [name]="'Data inserimento'">{{ row?.createdAt | date : 'short'}}</td>
      <td t-cell [name]="'Accettata'" class="justify-content-center">
        <i *ngIf="row.dispute?.accepted" class="fa fa-check" style="color: green"></i>
        <i *ngIf="row.dispute?.accepted == false" class="fa fa-times" style="color: red"></i>
      </td>
      <td t-cell [name]="'Note contestazione'" [pTooltip]="row?.dispute?.note">{{ row?.dispute?.note }}</td>
      <td t-cell [name]="'Telefono'">
        {{(row?.contact?.phones && row?.contact.phones.length > 0) ? row?.contact?.phones[0].phoneNumber :
        ''}}
      </td>
      <td t-cell [name]="'Email'">
        {{(row?.contact?.emails && row?.contact.emails.length > 0 )? row?.contact?.emails[0].email : ''}}
      </td>
      <td t-cell [name]="'Indirizzo'">{{row?.address?.formatted_address}}</td>
      <td t-cell [name]="'Data inizio'">{{ row?.start | date : 'short'}}</td>
      <td t-cell [name]="'Data fine'">{{ row?.end | date : 'short'}}</td>
      <td t-cell [name]="'Utente'">{{ row?.user?.firstName}} {{ row?.user?.lastName}}</td>
      <td t-cell [name]="'Esito contestazione'">{{ (row?.dispute?.state | domainValue: 'event_dispute_state' | async) }}</td>
      <td t-cell [name]="'Fonte'">{{ row?.cache?.project?.data?.source || row?.cache?.projectSale?.data?.source | sourceType | async | domainValue : 'source_lead_type' | async}}</td>
    </ng-template>
  </fullstack-datatable>`;

export default appointmentDisputeTemplate;
