import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { documentActionLabels, FileServerGroupType } from '@renovars/common';
import { FilesService } from '@renovars/core-ng';
import { DomainValuesService } from '@renovars/fe-core-libs/services/domain-values.service';
import { ConfirmModalComponent } from '@renovars/ui-ng';
import { combineLatest, filter, Subject, tap } from 'rxjs';
import { DocumentUploadComponent } from '../document-upload/document-upload.component';

@Component({
  selector: 'document-manager',
  templateUrl: './document-manager.component.html',
  styleUrls: ['./document-manager.component.scss'],
})
export class DocumentManagerComponent implements OnInit {
  @ViewChild(MatAccordion) accordion: MatAccordion;
  @Input() projectId: string;
  @Input() docType = 'lead_documents_type';
  @Output() fileUploaded = new EventEmitter();

  documentActionLabels = documentActionLabels;
  documentMeta = [];

  @Input() documentStateTypes = [];
  files: any[]; //TODO FILESERVER INTERFACEs
  private destroy$: Subject<void> = new Subject();

  constructor(
    private dialog: MatDialog,
    private filesService: FilesService,
    private domainValueService: DomainValuesService,
  ) {}

  ngOnInit(): void {
    this.loadFiles();
    this.domainValueService.findByTypeWithMetadata(this.docType).subscribe((res) => (this.documentMeta = res));

    this.documentStateTypes = this.documentStateTypes;
  }

  uploadFile(metadata, fileType) {
    this.dialog
      .open(DocumentUploadComponent, {
        height: '330px',
        width: '460px',
        data: {
          requiredState: metadata.value,
          projectId: this.projectId,
          idType: fileType.code,
          docType: this.docType,
        },
      })
      .afterClosed()
      .pipe(
        filter((res) => res),
        tap((id) => this.fileUploaded.emit(id)),
      )
      .subscribe((_) => this.loadFiles());
  }

  getFilteredDocumentTypeByRequiredState(requiredState: string) {
    return this.documentMeta.filter(
      (r) =>
        (r.metadata as any)?.data?.requiredState === requiredState ||
        ((r.metadata as any)?.data?.requiredState || []).includes(requiredState),
    );
  }

  getFilteredFilesByCode(code: number) {
    return this.files ? this.files.filter((f) => f.metadata?.type == code) : [];
  }

  getActionlabel(type: string) {
    return this.documentActionLabels.find((d) => d.value === type)?.label;
  }

  loadFiles() {
    combineLatest([
      this.domainValueService.findByTypeWithMetadata(this.docType),
      this.filesService.search({ projectId: this.projectId, group: FileServerGroupType.LEAD_DOCUMENTS }),
    ]).subscribe(([domainvalues, files]) => {
      this.files = files.map((file) => {
        return {
          ...file,
          metadata: {
            ...file.metadata,
            additionalMetadata: domainvalues.find((d) => d.code === file.metadata.type),
          },
        };
      });
    });
  }

  delete(fileId: string) {
    this.dialog
      .open(ConfirmModalComponent, {
        data: {
          text: `Confermi di voler eliminare questo elemento?`,
        },
      })
      .afterClosed()
      .pipe(filter((res) => res && res.confirmed))
      .subscribe((_) => {
        this.filesService.delete(fileId).subscribe(() => this.loadFiles());
      });
  }

  open(fileId: string) {
    this.filesService.download(fileId);
  }

  download(fileId: string) {
    this.filesService.downloadAsAttachment(fileId);
  }
}
