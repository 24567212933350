import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import {
  Opportinity,
  PaymentsTypeLabel,
  PltEventType,
  PltOpportunityStatusCode,
  PltRoles,
  PltStatusLabels,
  StatusIcon,
  getEnumKeyByEnumValue,
  getPltUserRole,
} from '@renovars/common/plenitude';
import { AuthServices, FlowMenuService } from '@renovars/core-ng';
import { MenuItem } from 'primeng/api';
import { Observable, map, switchMap } from 'rxjs';
import { OpportunityCreateEventModalComponent } from '../modals/opportunity-create-event-modal.component';
import { OpportunityMoveStateComponent } from '../modals/opportunity-move-state.component';
import { OpportunityApiService } from '../opportunity-api.service';
import { filterMenu } from '../filter-menu.utils';

@Component({
  selector: 'opportunity-tab',
  template: `
    <fullstack-header></fullstack-header>
    <ng-template header-body>
      <h1 class="tx-title-1">
        {{ opportunity?.cache?.contact?.firstName }}
        {{ opportunity?.cache?.contact?.lastName || opportunity?.cache?.contact?.businessName }}
      </h1>
      <div class="flex font-sans justify-between">
        <div>
          <div class="text-gray-600">
            <div class="flex text-sm gap-2">
              <p class="font-semibold my-0 ">Indirizzo installazione:</p>
              <p class="font-bold my-0 ">{{ opportunity?.cache?.lead?.addressOp?.formatted_address || '-' }}</p>
            </div>
            <div class="flex text-sm gap-2 ">
              <p class="font-semibold my-0 ">Pagamento:</p>
              <p class="font-bold my-0 ">{{ paymentLabels[opportunity?.cache?.lead?.concern] || '-' }}</p>
            </div>
            <div class="flex text-sm gap-2 ">
              <p class="font-semibold my-0 ">Fascia oraria:</p>
              <p class="font-bold my-0 ">{{ opportunity?.cache?.lead?.callPreference || '-' }}</p>
            </div>
          </div>
        </div>
        <div class="text-gray-600">
          <div class="flex  justify-between text-sm gap-2 items-center">
            <p class="font-semibold my-0 ">Lead:</p>
            <p class="font-semibold my-0 tx-title-4 capitalize w-100 text-right">
              {{ labels[opportunity?.cache?.lead?.status?.code] || '-' }}
            </p>

            <button mat-button [matMenuTriggerFor]="menu" [disabled]="true">
              <i class="fas fa-edit"></i>
            </button>
          </div>
          <div class="flex  justify-between text-sm gap-2 items-center">
            <p class="font-semibold my-0 ">Opportunità:</p>
            <p class="font-semibold my-0 tx-title-4 capitalize w-100 text-right">
              {{ labels[opportunity?.status?.code] || '-' }}
            </p>
            <button
              mat-button
              [matMenuTriggerFor]="menuOpp"
              [disabled]="opportunityStateList && opportunityStateList.length == 0"
            >
              <i class="fas fa-edit"></i>
            </button>
            <mat-menu #menuOpp="matMenu">
              <button mat-menu-item *ngFor="let state of opportunityStateList" (click)="state.command(state)">
                {{ state.label }}
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template header-menu>
      <fullstack-tabs>
        <tab [label]="'Dettaglio'" [url]="'./upsert'"></tab>
        <tab *ngIf="id | async" [label]="'Profilo'" [url]="'./profile'"></tab>
        <tab *ngIf="id | async" [label]="'Consensi Privacy'" [url]="'./privacy'"></tab>
        <tab *ngIf="id | async" [label]="'Documenti'" [url]="'./documents'"></tab>
        <tab *ngIf="id | async" [label]="'Intestatario'" [url]="'./buyer'"></tab>
        <tab *ngIf="(id | async) && canCreateEstimates" [label]="'Preventivi'" [url]="'./estimates'"></tab>
        <tab *ngIf="id | async" [label]="'Storico'" [url]="'./history'"></tab>
      </fullstack-tabs>
    </ng-template>
    <div class="m-2">
      <router-outlet></router-outlet>
    </div>
  `,
  styles: [
    `
      .mat-mdc-menu-item-text {
        text-transform: capitalize;
      }
    `,
  ],
  encapsulation: ViewEncapsulation.None,
})
export class OpportunityTabComponent implements OnInit {
  id: Observable<boolean>;
  canCreateEstimates: boolean = false;
  opportunity: Opportinity;
  labels = PltStatusLabels;
  opportunityStateList;
  paymentLabels = PaymentsTypeLabel;
  leadStateItems = [
    {
      label: 'Options',
      items: [
        {
          label: 'Update',
          icon: 'pi pi-refresh',
          command: () => {
            //this.update();
          },
        },
        {
          label: 'Delete',
          icon: 'pi pi-times',
          command: () => {
            //this.delete();
          },
        },
      ],
    },
    {
      label: 'Navigate',
      items: [
        {
          label: 'Angular',
          icon: 'pi pi-external-link',
          url: 'http://angular.io',
        },
        {
          label: 'Router',
          icon: 'pi pi-upload',
          routerLink: '/fileupload',
        },
      ],
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private flowMenuService: FlowMenuService<typeof PltRoles, typeof PltOpportunityStatusCode, PltStatusLabels>,
    private authService: AuthServices,
    private dialog: MatDialog,
    private apiOpportunity: OpportunityApiService,
  ) {}

  ngOnInit(): void {
    this.id = this.route.queryParamMap.pipe(map((params) => params.has('id')));

    this.getData();
  }

  getData() {
    this.route.queryParams
      .pipe(switchMap((params) => this.apiOpportunity.getById(params.id)))
      .subscribe((opportunity) => {
        this.opportunity = opportunity;
        this.canCreateEstimates = opportunity.history.some(
          (h) => h.code === PltOpportunityStatusCode.SOPRALLUOGO_EFFETTUATO,
        );
        this.buildOpportunityMenu(this.opportunity);
      });
  }
  buildOpportunityMenu(selectedOpportunity: Opportinity) {
    if (!selectedOpportunity) return;
    this.authService
      .user()
      .pipe(
        map((u) =>
          this.flowMenuService
            .createMenuVoice(selectedOpportunity.status.code, getPltUserRole(u))
            .filter(filterMenu(selectedOpportunity))
            .map((voice) => {
              const item: MenuItem = {
                label: PltStatusLabels[voice],
                icon: StatusIcon[voice],
                command: (event: any) => {
                  if (!event.label) {
                    return;
                  }

                  const status = getEnumKeyByEnumValue(PltStatusLabels, event.label);
                  if (status.includes(PltOpportunityStatusCode.DA_INSTALLARE)) {
                    this.dialog
                      .open(OpportunityCreateEventModalComponent, {
                        data: {
                          opportunity: selectedOpportunity,
                          type: PltEventType.INSTALLAZIONE,
                        },
                      })
                      .afterClosed()
                      .subscribe((_) => this.getData());
                  } else {
                    this.dialog
                      .open(OpportunityMoveStateComponent, {
                        data: {
                          opportunity: selectedOpportunity,
                          code: status,
                        },
                      })
                      .afterClosed()
                      .subscribe((_) => this.getData());
                  }
                },
              };
              return item;
            }),
        ),
      )
      .subscribe((items) => {
        this.opportunityStateList = items;
      });
  }
}
