import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'add-note-modal',
  templateUrl: `add-note-modal.html`,
  styleUrls: ['add-note-modal.scss'],
})
export class AddNoteModal implements OnInit {
  fb = new UntypedFormBuilder();
  form: FormGroup;

  constructor(public dialogRef: MatDialogRef<AddNoteModal>, @Inject(MAT_DIALOG_DATA) public data) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      note: new UntypedFormControl(this.data.note, [Validators.required, Validators.minLength(5)]),
    });
  }

  close() {
    this.dialogRef.close();
  }

  confirm() {
    const v = this.form.value;
    this.dialogRef.close({ note: v.note });
  }
}
