import { ObjectDTO } from '@renovars/common';

export interface IFlowSearchDTO extends ObjectDTO {
  ids?: string[];
  firstName?: string;
  lastName?: string;
  insertDateRange?: { start: Date; end: Date };
  createdDateRange?: { start: Date; end: Date };
  phone?: string;
  address?: string;
  province?: string;
}
