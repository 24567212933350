<h2 mat-dialog-title>Seleziona i pacchetti accessori</h2>
<mat-dialog-content class="mat-typography package-content">
  <div class="flex h-72 justify-center">
    <p-multiSelect
      [options]="accessoryPackages"
      [(ngModel)]="selectedPackages"
      defaultLabel="Select a Country"
      optionLabel="name"
      dataKey="_id"
    >
      <ng-template let-value pTemplate="selectedItems">
        <div class="flex align-items-center gap-2" *ngFor="let option of selectedPackages">
          <div>{{ option.name }}</div>
          <div>{{ option.description }}</div>
        </div>
        <div *ngIf="!selectedPackages || selectedPackages.length === 0">Seleziona pacchetti</div>
      </ng-template>
      <ng-template let-package pTemplate="item">
        <div class="flex align-items-center gap-2">
          <div>{{ package.name }}</div>
          <div>{{ package.description }}</div>
        </div>
      </ng-template>
    </p-multiSelect>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>Chiudi</button>
  <button mat-button (click)="confirm()" cdkFocusInitial>Ok</button>
</mat-dialog-actions>
