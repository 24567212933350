import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { LeadApiService } from '../lead-api';
import { ActivatedRoute } from '@angular/router';
import { Observable, map, switchMap, tap } from 'rxjs';

@Component({
  selector: 'app-lead-profile',
  template: `
    <form [formGroup]="form">
      <profile-form formControlName="profile" />
      <div class="row mt-4">
        <div class="col-sm-12 text-end">
          <button type="button" class="btn btn-primary rounded me-4" [routerLink]="['/flows']">Annulla</button>
          <button type="submit" class="btn btn-secondary rounded" (click)="save()" [disabled]="!form.valid">
            Salva
          </button>
        </div>
      </div>
    </form>
  `,
  styles: [],
})
export class LeadProfileComponent implements OnInit {
  form: UntypedFormGroup;
  id$: Observable<string>;
  constructor(
    private fb: FormBuilder,
    private readonly leadApi: LeadApiService,
    private route: ActivatedRoute,
  ) {
    this.form = this.fb.group({
      profile: new UntypedFormControl(),
    });
  }
  ngOnInit(): void {
    this.id$ = this.route.queryParamMap.pipe(map((params) => params.has('id') && params.get('id')));
    this.id$
      .pipe(
        switchMap((id) =>
          this.leadApi.getById(id).pipe(
            tap((lead) => this.form.patchValue({ profile: lead.profile })),
            map(() => id),
          ),
        ),
      )
      .subscribe();
  }
  save() {
    this.id$.pipe(switchMap((id) => this.leadApi.patchProfile(id, this.form.get('profile').value))).subscribe();
  }
}
