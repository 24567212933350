import { Inject, Injectable } from '@angular/core';
import { StateDefinition } from '@renovars/common/plenitude';

@Injectable()
export class FlowMenuService<R, S, L> {
  constructor(@Inject('FLOW_OPTION') private readonly opt: { sd: StateDefinition<R, S>; labels: L }) {}
  private getChoise(code: keyof S, role: keyof R) {
    return this.opt.sd[role][code as string] ?? [];
  }
  createMenuVoice(code: keyof S, role: keyof R): string[] {
    return this.getChoise(code, role);
  }
}
