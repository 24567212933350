import { Component, Input, OnInit } from '@angular/core';

export interface IChatboxMessage {
  text: string;
  date?: Date;
  author?: string;
  isSender?: boolean;
}
@Component({
  selector: 'renovars-chatbox',
  templateUrl: './chatbox.component.html',
  styleUrls: ['./chatbox.component.scss']
})
export class ChatboxComponent implements OnInit {
  @Input() messages: IChatboxMessage[];

  constructor() { }

  ngOnInit(): void {
  }

}
