import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PltAppointmentComponent } from './plt-appointment.component';
const appointmentRoutes: Routes = [
  {
    path: '',
    component: PltAppointmentComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(appointmentRoutes)],
  exports: [RouterModule],
})
export class PltAppointmentRoutingModule {}
