<form [formGroup]="form" novalidate>
  <div class="row">
    <div *ngIf="imageStorePath" class="col-sm-3">
      <div class="row">
        <div class="col-12">
          <header class="page-header border-bottom-0">
            <h2 class="tx-title-3">Immagine di profilo</h2>
          </header>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-12">
          <i
            *ngIf="!profileImage"
            class="image-circle fas fa-user"
            style="font-size: 120px; color: darkslategray"
          ></i>
          <img
            *ngIf="profileImage"
            class="image-circle"
            [src]="imageStorePath + '/' + profileImage.id"
          />
        </div>
      </div>
      <div class="row text-center">
        <div class="col">
          <input
            type="file"
            id="file-upload"
            style="display: none"
            (change)="onImgProfileSelect($event)"
            accept="image/*"
          />
          <button
            type="button"
            class="m-1 btn btn-secondary rounded"
            (click)="onImgProfileDelete()"
          >
            Elimina
          </button>
          <label class="m-1 btn btn-secondary rounded m-0" for="file-upload"
            >Carica</label
          >
        </div>
      </div>
    </div>
    <div class="col-sm-9">
      <div class="row">
        <div class="col-12">
          <header class="page-header border-bottom-0">
            <h2 class="tx-title-3">Dati anagrafici</h2>
          </header>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6 mt-1">
          <fullstack-input-box
            [options]="{ label: 'Nome' }"
            formControlName="firstName"
          ></fullstack-input-box>
        </div>
        <div class="col-sm-6 mt-1">
          <fullstack-input-box
            [options]="{ label: 'Cognome' }"
            formControlName="lastName"
          ></fullstack-input-box>
        </div>
        <div class="col-sm-6 mt-1">
          <fullstack-input-box
            [options]="{ label: 'Username' }"
            formControlName="username"
          ></fullstack-input-box>
        </div>
        <div class="col-sm-6 mt-1">
          <fullstack-datepicker
            [options]="{ label: 'Data di nascita' }"
            formControlName="birthDate"
          ></fullstack-datepicker>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <header class="page-header border-bottom-0">
        <h2 class="tx-title-3">Contatti</h2>
      </header>
    </div>
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6">
      <fullstack-input-box
        [options]="{ label: 'Email' }"
        formControlName="email"
      ></fullstack-input-box>
    </div>
    <div class="col-sm-12 col-md-6">
      <fullstack-input-box
        [options]="{ label: 'Cellulare' }"
        formControlName="mobilePhone"
      ></fullstack-input-box>
    </div>
  </div>
</form>
