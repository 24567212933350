import type { IAddress } from "@renovars/common";

export interface Field {
  label: string;
  field?: string;
  sort?: 0 | -1 | 1;
  type?: ColType;
  additionalInfo?: (val: any) => {};
  format?: (val: any) => {};
}

export enum ColType {
  SMALL = 'small',
  BIG = 'big',
  ADDITIONAL_INFO = 'additional-info',
  TAG = 'tag',
  CHECK = 'check',
}

export interface MenuItem {
  label: string;
  url?: string;
  children?: Array<MenuItem>;
}
export interface IEntityMapItem {
  name?: string;
  contentInfoWindowHTML?: string;
  address?: IAddress;
}
