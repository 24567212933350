import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { LeadApiService } from '../features/lead/lead-api';
import { PltStatusLabels } from '@renovars/common/plenitude';
import { OpportunityApiService } from '../features/plt-opportunity/opportunity-api.service';

@Component({
  selector: 'history',
  template: `
    <div class="row">
      <div class="col">
        <header class="page-header">
          <h2 class="tx-title-2">Storico cambio stato</h2>
        </header>
        <p-timeline [value]="histories" align="alternate">
          <ng-template pTemplate="marker" let-event>
            <span class="custom-marker p-shadow-2" *ngIf="event?.code">
              <i class="pi pi-circle-off"></i>
            </span>
          </ng-template>
          <ng-template pTemplate="content" let-event>
            <div class="tx-thin text-primary">{{ event?.date | date : 'dd-MM-yyyy HH:mm' }}</div>
            <div class="tx-thin text-primary text-bold">{{ labels[event?.code] }}</div>
            <div class="tx-thin text-info">{{ event?.user | nameById | async }}</div>
            <div class="tx-thin text-info" *ngIf="event?.metadata?.note">{{ event?.metadata?.note }}</div>
          </ng-template>
        </p-timeline>
      </div>
      <div class="col">
        <header class="page-header">
          <h2 class="tx-title-2">Storico cambio privacy</h2>
        </header>
        <p-timeline [value]="timelines" align="alternate">
          <ng-template pTemplate="marker" let-event>
            <span class="custom-marker p-shadow-2">
              <i class="pi pi-circle-off"></i>
            </span>
          </ng-template>
          <ng-template pTemplate="content" let-event>
            <div class="tx-thin text-primary">{{ event?.insertDate | date : 'dd-MM-yyyy HH:mm' }}</div>
            <div class="tx-thin text-info">{{ event?.userId | nameById | async }}</div>
            <div
              class="tx-thin {{ event?.newState?.privacy?.marketing ? 'text-info' : 'text-warning' }}"
              *ngIf="event?.newState?.privacy?.marketing !== null"
            >
              {{ event?.newState?.privacy?.marketing ? 'Flag marketing accettato' : 'Flag marketing non accettato' }}
            </div>
            <div
              class="tx-thin {{ event?.newState?.privacy?.upselling ? 'text-info' : 'text-warning' }}"
              *ngIf="event?.newState?.privacy?.upselling !== null"
            >
              {{ event?.newState?.privacy?.upselling ? 'Flag upselling accettato' : 'Flag upselling non accettato' }}
            </div>
            <div
              class="tx-thin {{ event?.newState?.privacy?.all ? 'text-info' : 'text-warning' }}"
              *ngIf="event?.newState?.privacy?.all !== null"
            >
              {{ event?.newState?.privacy?.all ? 'Flag all accettato' : 'Flag all non accettato' }}
            </div>
          </ng-template>
        </p-timeline>
      </div>
    </div>
  `,
  styles: [],
})
export class HistoryComponent implements OnInit {
  id: string;
  histories: any[];
  timelines: any[];
  labels = PltStatusLabels;
  constructor(
    private route: ActivatedRoute,
    private pltFlowService: LeadApiService,
    private opportunity: OpportunityApiService,
  ) {}

  ngOnInit(): void {
    this.route.queryParamMap
      .pipe(
        switchMap((params) =>
          params.has('id')
            ? this.route.snapshot.data['type'] === 'lead'
              ? this.pltFlowService.getById(params.get('id'))
              : this.opportunity.getById(params.get('id'))
            : of(null),
        ),
      )
      .subscribe((items) => {
        this.histories = items.history;
        this.timelines = (<any>items)._history.filter((h) => h.newState?.privacy);
      });
  }
}
