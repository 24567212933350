import { Component, forwardRef, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { ISite } from '@renovars/common';
import {
  IPltDomainValue,
  Lead,
  PaymentsType,
  PaymentsTypeLabel,
  PltDomainValueType,
  UpdateLeadProperty,
} from '@renovars/common/plenitude';
import { SiteService } from '@renovars/core-ng';
import { DomainValuesService } from '@renovars/fe-core-libs/services/domain-values.service';
import { FormElement } from '@renovars/ui-ng';
import { concatAll, filter, Observable, toArray } from 'rxjs';

@Component({
  selector: 'lead-form',
  template: `
    <form [formGroup]="leadForm" novalidate>
      <div class="row mt-4">
        <div class="col-12 my-4">
          <header class="page-header">
            <h2 class="tx-title-2">Dati lead</h2>
          </header>
        </div>
        <div class="col-sm-3 col-md-3">
          <fullstack-input-address-box
            formControlName="addressOp"
            [options]="{ label: 'Città', placeholder: leadForm.get('addressOp')?.value?.locality }"
          >
          </fullstack-input-address-box>
        </div>
        <div class="col-sm-3 col-md-3">
          <fullstack-input-select-box
            formControlName="forProduct"
            [options]="{ label: 'Interessato a' }"
            [error]="leadForm.get('forProduct').errors"
          >
            <sb-option [value]="domain.code" *ngFor="let domain of interest$ | async">{{ domain.label }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-3 col-md-3">
          <fullstack-input-select-box
            formControlName="concern"
            [options]="{ label: 'Offerta interesse' }"
            [error]="leadForm.get('concern').errors"
          >
            <sb-option *ngFor="let pay of paymentTypes" [value]="pay">{{ paymentLabels[pay] }}</sb-option>
          </fullstack-input-select-box>
        </div>
        <div class="col-sm-3 col-md-3">
          <fullstack-input-select-box
            formControlName="callPreference"
            [options]="{ label: 'Diponibilità' }"
            [error]="leadForm.get('callPreference').errors"
          >
            <sb-option [value]="domain.label" *ngFor="let domain of callPref$ | async">{{ domain.label }}</sb-option>
          </fullstack-input-select-box>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-12 col-md-12">
          <fullstack-input-box formControlName="trackingUrl" [options]="{ label: 'Tracking url', disabled: true }">
          </fullstack-input-box>
        </div>
        <div class="col-sm-12">
          <fullstack-form-row-list [title]="'Note'" formControlName="notes" [controlsFn]="notesGroupControl">
            <ng-template #rowTemplate let-control>
              <div class="row mt-1" [formGroup]="control">
                <div class="col-12">
                  <fullstack-input-box [options]="{ label: 'Nota' }" formControlName="note"> </fullstack-input-box>
                </div>
              </div>
            </ng-template>
          </fullstack-form-row-list>
        </div>
      </div>
    </form>
  `,
  styles: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LeadFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: LeadFormComponent,
      multi: true,
    },
  ],
})
export class LeadFormComponent extends FormElement implements OnInit {
  leadForm: UntypedFormGroup;
  sites$: Observable<ISite[]>;
  paymentTypes = Object.keys(PaymentsType);
  paymentLabels = PaymentsTypeLabel;
  interest$: Observable<IPltDomainValue[]>;
  callPref$: Observable<IPltDomainValue[]>;
  owner$: Observable<IPltDomainValue[]>;
  warm$: Observable<IPltDomainValue[]>;
  propagateChange = (_: Lead) => {
    //
  };
  constructor(
    private fb: FormBuilder,
    private siteService: SiteService,
    private domainValuesService: DomainValuesService,
  ) {
    super();
  }
  ngOnInit(): void {
    this.leadForm = this.fb.group({
      addressOp: new UntypedFormControl(null, []),
      callPreference: new UntypedFormControl(null, []),
      forProduct: new UntypedFormControl(null, []),
      concern: new UntypedFormControl(null, []),
      trackingUrl: new UntypedFormControl(null, []),
      notes: new UntypedFormControl([], []),
      privacy: this.fb.group({
        marketing: new FormControl<boolean>(false, []),
        all: new FormControl<boolean>(false, []),
        upselling: new FormControl<boolean>(false, []),
      }),
    });
    this.sites$ = this.siteService.findAll();
    this.leadForm.valueChanges.subscribe((value) => this.propagateChange(value));
    const domains = this.domainValuesService.list();
    this.interest$ = domains.pipe(
      concatAll(),
      filter((v) => v.type === PltDomainValueType.INTEREST_TYPE),
      toArray(),
    );
    this.callPref$ = domains.pipe(
      concatAll(),
      filter((v) => v.type === PltDomainValueType.CALL_PREFERENCE_TYPE),
      toArray(),
    );
    this.owner$ = domains.pipe(
      concatAll(),
      filter((v) => v.type === PltDomainValueType.OWNER_TYPE),
      toArray(),
    );
    this.warm$ = domains.pipe(
      concatAll(),
      filter((v) => v.type === PltDomainValueType.WARM_TYPE),
      toArray(),
    );
  }
  writeValue(obj: UpdateLeadProperty): void {
    this.leadForm.patchValue(obj);
  }
  registerOnChange(fn) {
    this.propagateChange = fn;
  }
  notesGroupControl() {
    return this.fb.group({
      note: new UntypedFormControl(null, []),
    });
  }
}
