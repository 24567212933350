import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { ProductBrowseFilter, ProductsService } from '../../../products.service';
import { IProduct, ProductCategory } from '@renovars/common/plenitude';
import { DomainValuesService } from '@renovars/fe-core-libs/services/domain-values.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '@renovars/ui-ng';
import { debounceTime, filter, of, switchMap } from 'rxjs';

@Component({
  selector: 'products-list-component',
  templateUrl: `./products-list.component.html`,
})
export class ProductsListComponent implements OnInit {
  cmItems: MenuItem[] = [
    {
      label: 'Dettaglio',
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        this.gotoDetail();
      },
    },
    {
      label: 'Elimina',
      icon: 'pi pi-fw pi-trash',
      command: () => {
        this.delete();
      },
    },
  ];
  products: IProduct[];
  selected: IProduct = null;
  domainValuesList$;
  categoies$ = of(
    Object.values(ProductCategory)
      .filter((v) => Number(v) >= 0)
      .map((v) => ({ label: ProductCategory[v], value: v })),
  );
  total = 0;
  offset = 0;
  limit = 10;
  sort = null;
  filter: ProductBrowseFilter = {
    category: null,
    name: null,
    price: null,
  };

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private productApi: ProductsService,
    private domainValueService: DomainValuesService,
  ) {}

  ngOnInit(): void {
    this.domainValuesList$ = this.domainValueService.list();
  }
  onLazyLoad(event) {
    this.offset = event.first ?? 0;
    this.limit = event.rows ?? 10;
    this.sort = {
      by: event.sortField ?? 'name',
      order: event.sortOrder ?? 1,
    };
    this.refreshList();
  }

  refreshList() {
    this.productApi
      .browse({ offset: this.offset, limit: this.limit, filter: this.filter, sort: this.sort })
      .subscribe((res) => {
        this.products = res.items;
        this.total = res.total;
      });
  }

  gotoDetail() {
    this.router.navigate([`./detail`], {
      queryParams: { id: this.selected._id },
      relativeTo: this.activatedRoute,
    });
  }

  delete() {
    this.dialog
      .open(ConfirmModalComponent, {
        data: {
          text: `Sei sicuro di voler eliminare questo prodotto ?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((res) => res && res.confirmed),
        switchMap((_) => this.productApi.delete(this.selected._id)),
      )
      .subscribe(() => this.refreshList());
  }
  load() {
    this.productApi
      .browse({ offset: this.offset, limit: this.limit, filter: this.filter, sort: this.sort })
      .pipe(debounceTime(1000))
      .subscribe((res) => {
        this.products = res.items;
        this.total = res.total;
      });
  }
}
