<div class="row">
  <div class="col-12">
    <header class="page-header border-bottom-0">
      <h2 class="tx-title-3">Registrazioni</h2>
    </header>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <fullstack-datatable
      [list]="registrazioni"
      [cmItems]="cmItems"
      (selected)="selected = $event"
      [paginator]="false"
    >
      <div t-header>
        <div t-column [name]="'Applicazione'">
        </div>
        <div t-column [name]="'Ruolo'">
        </div>
        <div t-column [name]="'Attivo'">
        </div>
        <div t-column [name]="'Data inserimento'">
        </div>
        <div t-column [name]="'Data ultimo login'">
        </div>
      </div>

      <ng-template t-body let-row>
        <td>{{ row?.name }}</td>
        <td>{{ row?.roles ? row?.roles : '' }}</td>
        <td>{{ row?.verified }}</td>
        <td>{{ row?.insertInstant | date: 'dd-MM-yyyy HH:mm' }}</td>
        <td>{{ row?.lastLoginInstant | date: 'dd-MM-yyyy HH:mm' }}</td>
      </ng-template>
    </fullstack-datatable>
  </div>
</div>
<div class="row mt-3" *ngIf="applicationsRemainToAdd && applicationsRemainToAdd?.length > 0">
  <div class="col-sm-12">
    <button type="button" class="btn btn-secondary rounded" (click)="openModalRegistrazione(null)">
      Aggiungi registrazione
    </button>
  </div>
</div>
