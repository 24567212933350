import { Injectable } from '@angular/core';
import * as dayjs from 'dayjs';
import { concatAll, filter, map, Observable, switchMap, tap, toArray, zip } from 'rxjs';
import * as XLSX from 'xlsx';
import { PltUsersCacheService } from './plt-users-cache.service';
import { isLead, Lead, Opportinity, PltStatusLabels } from '@renovars/common/plenitude';
import { isIndividual } from '@renovars/common';

const jsonToXlsx = (jsonData: any) => {
  const wb = XLSX.utils.book_new();
  const newWs = XLSX.utils.json_to_sheet(jsonData);
  XLSX.utils.book_append_sheet(wb, newWs);
  const rawExcel = XLSX.write(wb, { type: 'array', bookType: 'xlsx' });
  return rawExcel;
};
@Injectable()
export class CsvUtilsService {
  constructor(private cacheUsersService: PltUsersCacheService) {}
  private mapAssigned(value: string[]) {
    return this.cacheUsersService.getUsers().pipe(
      concatAll(),
      filter((u) => value.includes(u.id)),
      map((u) => `${u.firstName} ${u.lastName}`),
      toArray(),
      map((names) => names.join(', ')),
    );
  }
  mapCsv(listService: Observable<(Lead | Opportinity)[]>, mapKey: string) {
    return listService.pipe(
      concatAll(),
      switchMap((v) => this.csvFactory(v, mapKey)),
      toArray(),
      tap((mappedValues) => this.downloadFile(mappedValues, mapKey)),
    );
  }

  private downloadFile(data: any, mapKey?: string) {
    const replacer = (value) => (value === null || value === undefined ? '' : value);
    const header = Object.keys(data[0]);
    const csv = data.map((row) => header.map((fieldName) => JSON.stringify(row[fieldName], replacer)).join(','));
    csv.unshift(header.join(','));
    const a = document.createElement('a');
    const blobData = jsonToXlsx(data);
    const blob = new Blob([blobData], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,',
    });
    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = mapKey ? mapKey + '.xlsx' : dayjs().format('DD/MM/YYYY') + '.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  private csvFactory(row: Lead | Opportinity, mapKey: string) {
    if (mapKey === 'lead') {
      return zip(this.mapAssigned(row.assigned), this.mapAssigned([row.status.user])).pipe(
        map(([assigned, user]) => {
          if (!row?.cache?.contact) return;
          return {
            creatoIl: row?.createdAt,
            cognome: isIndividual(row?.cache?.contact)
              ? row?.cache?.contact?.lastName
              : row?.cache?.contact?.businessName,
            telefono: row.cache.contact.phones[0].phoneNumber,
            stato: PltStatusLabels[row.status.code],
            fonte_utm: isLead(row) ? row?.tracking?.utmSource : null,
            campagna_utm: isLead(row) ? row?.tracking?.utmCampaign : null,
            assegnatari: assigned,
            utente: user,
          };
        }),
        filter(Boolean),
      );
    } else if (mapKey === 'opportunity') {
      return zip(this.mapAssigned(row.assigned), this.mapAssigned([row.status.user])).pipe(
        map(([assigned, user]) => {
          if (!row?.cache?.contact) return;
          return {
            creatoIl: row?.createdAt,
            cognome: isIndividual(row?.cache?.contact)
              ? row?.cache?.contact?.lastName
              : row?.cache?.contact?.businessName,
            telefono:
              row?.cache?.contact?.phones.length > 0 &&
              row?.cache?.contact?.phones[0] &&
              row?.cache?.contact?.phones[0]?.phoneNumber
                ? row?.cache?.contact?.phones?.map((p) => p?.phoneNumber)?.join(' ')
                : null,
            stato: PltStatusLabels[row.status.code],
            fonte_utm: isLead(row) ? row?.tracking?.utmSource : row?.cache?.lead?.tracking?.utmSource,
            campagna_utm: isLead(row) ? row?.tracking?.utmCampaign : row?.cache?.lead?.tracking?.utmCampaign,
            assegnatari: assigned,
            utente: user,
          };
        }),
        filter(Boolean),
      );
    }
  }
}
