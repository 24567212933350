import { Component, Inject, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EstimateService } from '@fi/app/services/estimates.service';
import { IEstimate, Opportinity } from '@renovars/common/plenitude';
import dayjs from 'dayjs';

@Component({
  selector: 'app-create-estimate-modal',
  templateUrl: './create-estimate-modal.component.html',
  styleUrls: ['./create-estimate-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateEstimateModalComponent implements OnInit {
  formEstimate: FormControl = new FormControl();

  constructor(
    public dialogRef: MatDialogRef<CreateEstimateModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { opportunity: Opportinity },
    private estimateApi: EstimateService,
  ) {}
  ngOnInit(): void {
    const date = dayjs().year().toString();
    const estimateName = `PR_${this.data.opportunity._id.substring(6, 0)}_${(Math.random() + 1)
      .toString(36)
      .substring(6)}_${date}`.toUpperCase();
    this.formEstimate.setValue({ name: estimateName, financing: this.data.opportunity.cache.lead.concern });
  }

  save() {
    if (this.formEstimate.valid) {
      const estimate: IEstimate = {
        ...this.formEstimate.value,
        mainPromotions: [this.formEstimate.value?.mainPromotion],
        opportunity: this.data.opportunity._id,
        items: this.formEstimate.value?.items,
        createdAt: new Date(),
      };

      this.estimateApi.save(estimate).subscribe();

      this.dialogRef.close(true);
    }
  }
}
