import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipeLibsModule, SharedCoreModule } from '@renovars/core-ng';
import { UiModule } from '@renovars/ui-ng';
@NgModule({
  exports: [CommonModule, FormsModule, ReactiveFormsModule, PipeLibsModule, UiModule, SharedCoreModule],
  providers: [],
})
export class SharedModule {}
