import { Pipe, PipeTransform } from '@angular/core';
import { APPLICATION_ID, IContact, IContactIndividual, IUser } from '@renovars/common';
import { EstimateProducts, EstimateState, PltRolesLabel } from '@renovars/common/plenitude';
import { concatAll, filter, map, Observable, toArray } from 'rxjs';
import { environment } from '../../environments/environment';
import { PltUsersCacheService } from '../services/plt-users-cache.service';

@Pipe({ name: 'getRoles' })
export class GetRolesPipe implements PipeTransform {
  transform(user: IUser) {
    if (user && user.registrations) {
      const reg = user.registrations.find((r) => r.applicationId == environment.applicationId);
      const roles = reg.roles.filter((r) => r.startsWith('is_'));

      return roles;
    }
  }
}

@Pipe({
  name: 'nameById',
})
export class NameByIdPipe implements PipeTransform {
  constructor(private cacheUsersService: PltUsersCacheService) {}
  transform(value: string): Observable<string> {
    return this.cacheUsersService.getUsers().pipe(
      concatAll(),
      filter((u) => u.id === value),
      map((u) => `${u.firstName} ${u.lastName}`),
    );
  }
}
@Pipe({
  name: 'assigned',
})
export class AssignedPipe implements PipeTransform {
  constructor(private cacheUsersService: PltUsersCacheService) {}
  transform(value: string[], tooltip: boolean) {
    return this.cacheUsersService.getUsers().pipe(
      concatAll(),
      filter((u) => value.includes(u.id)),
      map(
        (u) =>
          `${u.firstName} ${u.lastName} ${
            tooltip
              ? u.registrations
                  .find((reg) => reg.applicationId === APPLICATION_ID.CLIMASTACK)
                  .roles.map((role) => (PltRolesLabel[role] ? PltRolesLabel[role] : null))
                  .join(': ')
              : ''
          }`,
      ),
      toArray(),
      map((names) => names.join(', ')),
    );
  }
}

@Pipe({
  name: 'getPhone',
})
export class GetPhonePipe implements PipeTransform {
  transform(contact: IContact): string {
    return !contact || !contact.phones || contact.phones.length === 0 || !contact.phones[0]
      ? ''
      : contact.phones[0].phoneNumber;
  }
}
@Pipe({
  name: 'getEmail',
})
export class GetEmailPipe implements PipeTransform {
  transform(contact: IContact): string {
    return !contact || !contact.emails || contact.emails.length === 0 || !contact.emails[0]
      ? ''
      : contact.emails[0].email;
  }
}

@Pipe({
  name: 'getFirstName',
})
export class GeFirstNamePipe implements PipeTransform {
  transform(contact: IContactIndividual): string {
    return contact?.firstName;
  }
}
@Pipe({
  name: 'getLastName',
})
export class GetLastNamePipe implements PipeTransform {
  transform(contact: IContactIndividual): string {
    return contact?.lastName;
  }
}

@Pipe({
  name: 'estimateState',
})
export class EstimateStatePipe implements PipeTransform {
  transform(state: EstimateState): string {
    switch (state) {
      case EstimateState.DA_PRESENTARE:
        return 'Da presentare';
      case EstimateState.PRESENTATO:
        return 'Presentato';
      case EstimateState.ACCETTATO:
        return 'Accettato';
      case EstimateState.RIFIUTATO:
        return 'Rifiutato';
    }
  }
}
@Pipe({
  name: 'estimateProducts',
})
export class EstimateProductsPipe implements PipeTransform {
  transform(type: EstimateProducts): string {
    switch (type) {
      case EstimateProducts.CALDAIA:
        return 'Caldaia';
      case EstimateProducts.CLIMA:
        return 'Clima';
      case EstimateProducts.CALDAIA_CLIMA:
        return 'Caldaia/Clima';
    }
  }
}
