import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { IClimaEvent } from '@renovars/common/plenitude';

@Component({
  selector: 'plt-event-modal',
  templateUrl: `plt-event-modal.html`,
  styles: [``],
})
export class PltEventModal implements OnInit {
  event: IClimaEvent;

  constructor(
    public dialogRef: MatDialogRef<PltEventModal>,
    @Inject(MAT_DIALOG_DATA) public data: { event: IClimaEvent },
    private router: Router,
  ) {
    this.event = data.event;
  }

  ngOnInit(): void {}

  goToDetail() {
    this.router.navigateByUrl(`/opportunity/details/upsert?id=${this.event.opportunity}`);
  }

  close() {
    this.dialogRef.close();
  }
  // move() {
  //   this.dialogRef.close({ move: true, event: this.event });
  // }
}
