import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { PromotionsService } from '../../../promotions.service';
import { IProduct, IPromotion } from '@renovars/common/plenitude';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmModalComponent } from '@renovars/ui-ng';
import { filter, switchMap } from 'rxjs';

@Component({
  selector: 'products-list-component',
  templateUrl: `./promotions-list.component.html`,
})
export class PromotionsListComponent implements OnInit {
  cmItems: MenuItem[] = [
    {
      label: 'Dettaglio',
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        this.gotoDetail();
      },
    },
    {
      label: 'Elimina',
      icon: 'pi pi-fw pi-trash',
      command: () => {
        this.delete();
      },
    },
  ];
  promotions: IPromotion[];
  selected: IPromotion = null;
  total = 0;
  offset = 0;
  limit = 10;

  constructor(
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private promoApi: PromotionsService,
  ) {}

  ngOnInit(): void {
    this.refreshList();
  }
  onLazyLoad(event) {
    this.offset = event.first ?? 0;
    this.limit = event.rows ?? 10;
    this.refreshList();
  }

  refreshList() {
    this.promoApi.browse({ offset: this.offset, limit: this.limit }).subscribe((res) => {
      this.promotions = res.items;
      this.total = res.total;
    });
  }

  gotoDetail() {
    this.router.navigate([`./detail`], {
      queryParams: { id: this.selected._id },
      relativeTo: this.activatedRoute,
    });
  }

  delete() {
    this.dialog
      .open(ConfirmModalComponent, {
        data: {
          text: `Sei sicuro di voler eliminare questo pacchetto ?`,
        },
      })
      .afterClosed()
      .pipe(
        filter((res) => res && res.confirmed),
        switchMap((_) => this.promoApi.delete(this.selected._id)),
      )
      .subscribe(() => this.refreshList());
  }
}
