import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { APPLICATION_ID, ISite, IUser, LabelRoles } from '@renovars/common';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import dayjs from 'dayjs';
import { MenuItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UTENTI_CONFIG_PROVIDER, UtentiModuleOptions } from '../module.options';

@Component({
  selector: 'facile-utenti',
  template: `
    <fullstack-header></fullstack-header>

    <ng-template header-body>
      <h1 class="tx-title-1">Utenti</h1>
      <h2 class="tx-subtitle mb-0">Lista</h2>
    </ng-template>

    <ng-template header-body>
      <div class="text-end align-items-center">
        <button class="btn btn-header" (click)="download()">
          <i class="fas fa-download cl-medium fa-1x"></i>
        </button>
      </div>
    </ng-template>

    <fullstack-datatable
      [list]="users$ | async"
      [cmItems]="cmItems"
      (selected)="selected = $event"
      [clientExport]="false"
      [showColumnSelector]="true"
      [visibleColumns]="visibleColumns"
      (selectedColumnsChoosed)="saveSelectedColumn($event)"
      (rowsChange)="saveRowCount($event)"
      [maxRows]="maxRows"
    >
      <div t-header>
        <div t-column [name]="'Nome'" field="firstName" clientFilter="firstName"></div>
        <div t-column [name]="'Cognome'" field="lastName" clientFilter="lastName"></div>
        <div t-column [name]="'Email'" field="email" clientFilter="email"></div>
        <div t-column [name]="'Telefono'" field="mobilePhone" clientFilter="mobilePhone"></div>
        <div t-column [name]="'Ruolo'" field="roles" clientFilter="roles"></div>
        <div t-column [name]="'Attivo'" field="active"></div>
        <div t-column [name]="'Ultimo Login'" field="lastLoginInstant"></div>
        <div t-column [name]="'Nome Sede'" field="site.name" clientFilter="site.name"></div>
        <div
          t-column
          [name]="'Provincia Sede'"
          field="site.address.province"
          clientFilter="site.address.province"
        ></div>
        <div t-column [name]="'Area Sede'" field="site.area" clientFilter="site.area"></div>
        <div t-column [name]="'BC Id'" field="bcId" clientFilter="bcId"></div>
        <div t-column [name]="'Codice fiscale'" field="fiscalCode" clientFilter="fiscalCode"></div>
      </div>

      <ng-template t-body let-row>
        <td t-cell [name]="'Nome'">{{ row?.firstName }}</td>
        <td t-cell [name]="'Cognome'">{{ row?.lastName }}</td>
        <td t-cell [name]="'Email'">{{ row?.email }}</td>
        <td t-cell [name]="'Telefono'">{{ row?.mobilePhone }}</td>
        <td t-cell [name]="'Ruolo'">{{ row?.roles }}</td>
        <td t-cell [name]="'Attivo'">{{ row?.active ? 'Attivo' : 'Non attivo' }}</td>
        <td t-cell [name]="'Ultimo Login'">{{ row?.lastLoginInstant | date : 'short' }}</td>
        <td t-cell [name]="'Nome Sede'">{{ row?.site?.name }}</td>
        <td t-cell [name]="'Provincia Sede'">{{ row?.site?.address?.province }}</td>
        <td t-cell [name]="'Area Sede'">{{ row?.site?.area }}</td>
        <td t-cell [name]="'BC Id'">{{ row?.bcId }}</td>
        <td t-cell [name]="'Codice fiscale'">{{ row?.fiscalCode }}</td>
      </ng-template>
    </fullstack-datatable>
  `,
})
export class UtentiComponent implements OnInit {
  cmItems: MenuItem[] = [
    {
      label: 'Dettaglio',
      icon: 'pi pi-fw pi-pencil',
      command: () => {
        this.gotoDetail();
      },
    },
  ];

  users$: Observable<ViewUser[]>;
  selected: ViewUser = null;
  visibleColumns: Array<string> = [];
  tableRef = 'UtentiComponent';
  maxRows: number = 10;
  loading: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private usersService: UsersService,
    @Inject(UTENTI_CONFIG_PROVIDER)
    public utentiModuleOption: UtentiModuleOptions,
  ) {}

  ngOnInit(): void {
    this.users$ = this.usersService.excludeNetworkUsers$(['can_read_users_list']).pipe(
      map((us) => {
        return us?.map(
          (
            u: IUser & {
              site: ISite;
              bcId: string;
              fiscalCode: string;
              isQualified: boolean;
              isDummy: boolean;
              eventsCountDelta: number;
            },
          ) =>
            ({
              id: u.id,
              firstName: u.firstName,
              lastName: u.lastName,
              email: u.email,
              mobilePhone: u.mobilePhone,
              roles: u.registrations
                ? this.buildRoles(u.registrations.find((reg) => reg.applicationId === APPLICATION_ID.CLIMASTACK)?.roles)
                : [],
              active: u.active,
              lastLoginInstant: dayjs(u.lastLoginInstant).toDate(),
              site: u.site,
              bcId: u.bcId,
              fiscalCode: u.fiscalCode,
              isQualified: u.isQualified,
              isDummy: u.isDummy,
              eventsCountDelta: u.eventsCountDelta,
            } as ViewUser),
        );
      }),
    );
  }

  gotoDetail() {
    this.router.navigate([`../upsert/main-info`], {
      queryParams: { id: this.selected.id },
      relativeTo: this.activatedRoute,
    });
  }
  private buildRoles(roles: string[]) {
    return roles
      ? roles
          .map((r) => (LabelRoles[r] ? LabelRoles[r] : null))
          .filter((role) => !!role)
          .join(', ')
      : '';
  }

  saveSelectedColumn(selectedColumns: Array<string>) {
    //TODO USER PREF
  }
  saveRowCount(rows: number) {
    //TODO USER PREF
  }
  download() {
    //TODO DOWNLOAD
  }
}
type ViewUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  roles: string;
  lastLoginInstant: Date;
  site: ISite;
  bcId: string;
  fiscalCode: string;
  isQualified: boolean;
  isDummy: boolean;
};
