import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import type { IAddress } from '@renovars/common';
import { Observable, Observer } from 'rxjs';

declare let google;
@Injectable()
export class GmapsService {
  geocoder = new google.maps.Geocoder();

  constructor(private httpClient: HttpClient) {}

  public geocodingFromAddressObj(address: IAddress) {
    const addressElem = [
      address.route,
      address.street_number,
      address.locality,
      address.province,
      address.postalCode,
      address.country,
    ];

    const addressString = addressElem.join(' ');
    return this.geocoding(addressString);
  }

  public geocoding(address: string): Observable<Array<IAddress>> {
    return new Observable((observe) => {
      this.geocoder.geocode({ address: address, language: 'it', region: 'IT-it' }, (results, status) => {
        if (status === 'OK') {
          let addr;
          if (results && results[0]) {
            addr = results.map((r) => this.getAddressFromGmapsComponent(r));
          }
          observe.next(addr);
        } else {
          console.error(status);
        }
      });
    });
  }

  private getAddressFromGmapsComponent(place) {
    const address: IAddress = {};
    const addressComponents: Array<any> = place.address_components;

    address.street_number = this.getAddressComponent(addressComponents, 'street_number').long_name || null;
    address.route = this.getAddressComponent(addressComponents, 'route').long_name || null;
    address.locality =
      this.getAddressComponent(addressComponents, 'locality').long_name ||
      this.getAddressComponent(addressComponents, 'administrative_area_level_3').long_name ||
      null;
    address.country = this.getAddressComponent(addressComponents, 'country').long_name || null;
    address.short_country = this.getAddressComponent(addressComponents, 'country').short_name || null;
    address.province = this.getAddressComponent(addressComponents, 'administrative_area_level_2').short_name || null;
    address.postalCode = this.getAddressComponent(addressComponents, 'postal_code').long_name || null;
    address.region = this.getAddressComponent(addressComponents, 'administrative_area_level_1').long_name || null;
    address.formatted_address = place.formatted_address || null;
    address.coords = [place.geometry.location.lat(), place.geometry.location.lng()];

    return address;
  }

  private getAddressComponent(addressComponents, gmapComponentName) {
    return (
      addressComponents.find((curr) => {
        return curr.types[0] === gmapComponentName;
      }) || {}
    );
  }

  getBase64StaticMapImage(coords: number[], zoom: '13' | '16' = '16'): Observable<string> {
    const gMapsKey = 'key=AIzaSyDQeZpo_tHnSfT9clnpOByVEXPH9KLPhdk';
    const marker = '&markers=color:red%7C' + coords[0] + ',' + coords[1];
    const url =
      'https://maps.googleapis.com/maps/api/staticmap?center=' +
      coords[0] +
      ',' +
      coords[1] +
      '&zoom=' +
      zoom +
      '&size=800x300&' +
      marker +
      '&' +
      gMapsKey +
      '&callback=Function.prototype';
    return new Observable((observer: Observer<string>) => {
      const img = new Image();
      img.crossOrigin = 'Anonymous';
      img.src = url;
      if (!img.complete) {
        // This will call another method that will create image from url
        img.onload = () => {
          observer.next(this.getBase64Image(img));
          observer.complete();
        };
        img.onerror = (err) => {
          observer.error(err);
        };
      } else {
        observer.next(this.getBase64Image(img));
        observer.complete();
      }
    });
  }

  getBase64Image(img: HTMLImageElement) {
    // We create a HTML canvas object that will create a 2d image
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    // This will draw image
    ctx.drawImage(img, 0, 0);
    // Convert the drawn image to Data URL
    return canvas.toDataURL('image/png');
  }
}
