<label class="card card--form">
  <span class="card-body" style="min-width: 200px">
    <span class="label-control">{{ options?.label }}</span>
    <div class="d-flex flex-row justify-content-between align-items-center">
      <div>
        <mat-date-range-input
          [rangePicker]="picker"
          [min]="options?.min"
          [max]="options?.max"
        >
          <input
            matStartDate
            [(ngModel)]="start"
            placeholder="{{ options?.placeholder }}"
          />
          <input matEndDate [(ngModel)]="end" (dateChange)="_rangeChange()" />
        </mat-date-range-input>
      </div>
      <div>
        <button (click)="_clear()" type="button">
          <i class="fas fa-eraser"></i>
        </button>
        <button (click)="picker.open()" class="ml-2" type="button">
          <i class="far fa-calendar"></i>
        </button>
        <mat-date-range-picker
          #picker
          [calendarHeaderComponent]="DatepickerHeaderComponent"
        ></mat-date-range-picker>
      </div>
    </div>
  </span>
</label>
