import { PltLeadStatusCode, PltOpportunityStatusCode } from '../enums';
import { StateTransition } from '../interfaces';

const koContatto = [PltLeadStatusCode.KO_SOLO_INFO, PltLeadStatusCode.KO_CITTA, PltLeadStatusCode.KO_CREDITO];

export const ccOperatorAction: StateTransition<typeof PltLeadStatusCode> = {
  DA_CONTATTARE: [PltLeadStatusCode.OK_SOPRALLUOGO, PltLeadStatusCode.DA_RICHIAMARE, ...koContatto],
  DA_RICHIAMARE: [PltLeadStatusCode.OK_SOPRALLUOGO, ...koContatto],
  DUPLICATO_ACCETTATO: [PltLeadStatusCode.DA_RICHIAMARE],
  DUPLICATO_RIFIUTATO: [PltLeadStatusCode.DA_RICHIAMARE],
  KO_SOLO_INFO: [PltLeadStatusCode.DA_RICHIAMARE],
  KO_CITTA: [PltLeadStatusCode.DA_RICHIAMARE],
  KO_CREDITO: [PltLeadStatusCode.DA_RICHIAMARE],
  OK_SOPRALLUOGO: [PltLeadStatusCode.IN_OPPORTUNITA],
  IN_OPPORTUNITA: [],
};

const koTecnici = [
  PltOpportunityStatusCode.KO_TECNICO,
  PltOpportunityStatusCode.KO_PREZZO,
  PltOpportunityStatusCode.KO_RIPENSAMENTO,
];
const koLavorazione = [
  PltOpportunityStatusCode.KO_MANCANZA_DOCUMENTI,
  PltOpportunityStatusCode.KO_ATTESA_PAGAMENTO,
  PltOpportunityStatusCode.KO_FINANZIAMENTO,
  PltOpportunityStatusCode.KO_REPERIBILITA_OPPORTUNITA,
];
const pendingLavorazione = [
  PltOpportunityStatusCode.PENDING_ATTESA_DOCUMENTI,
  PltOpportunityStatusCode.PENDING_ATTESA_FINANZIAMENTO,
  PltOpportunityStatusCode.PENDING_ATTESA_PAGAMENTO,
];

export const opportunityTechnicalCoordinatorAction: StateTransition<typeof PltOpportunityStatusCode> = {
  SOPRALLUOGO_FISSATO: [PltOpportunityStatusCode.SOPRALLUOGO_EFFETTUATO, ...koTecnici],
  SOPRALLUOGO_EFFETTUATO: [...koTecnici],
  PROPOSTA: [],
  TRATTATIVA: [],
  PENDING_ATTESA_DOCUMENTI: [
    PltOpportunityStatusCode.PENDING_ATTESA_FINANZIAMENTO,
    PltOpportunityStatusCode.PENDING_ATTESA_PAGAMENTO,
    ...koLavorazione,
  ],
  PENDING_ATTESA_FINANZIAMENTO: [
    PltOpportunityStatusCode.APPUNTAMENTO_DA_FISSARE,
    ...koLavorazione.filter((v) => v !== PltOpportunityStatusCode.KO_ATTESA_PAGAMENTO),
  ],
  PENDING_ATTESA_PAGAMENTO: [
    PltOpportunityStatusCode.APPUNTAMENTO_DA_FISSARE,
    ...koLavorazione.filter((v) => v !== PltOpportunityStatusCode.KO_FINANZIAMENTO),
  ],
  APPUNTAMENTO_DA_FISSARE: [PltOpportunityStatusCode.DA_INSTALLARE, ...koLavorazione],
  DA_INSTALLARE: [
    PltOpportunityStatusCode.INSTALLAZIONE_OK_DOCUMENTI_OK,
    PltOpportunityStatusCode.INSTALLAZIONE_OK_DOCUMENTI_PENDING,
    ...koLavorazione,
  ],
  INSTALLAZIONE_OK_DOCUMENTI_PENDING: [PltOpportunityStatusCode.INSTALLAZIONE_OK_DOCUMENTI_OK],
  INSTALLAZIONE_OK_DOCUMENTI_OK: [PltOpportunityStatusCode.PRATICA_CHIUSA],
  KO_TECNICO: [],
  KO_PREZZO: [],
  KO_RIPENSAMENTO: [],
  KO_MANCANZA_DOCUMENTI: [
    PltOpportunityStatusCode.APPUNTAMENTO_DA_FISSARE,
    PltOpportunityStatusCode.PENDING_ATTESA_DOCUMENTI,
  ],
  KO_ATTESA_PAGAMENTO: [
    PltOpportunityStatusCode.APPUNTAMENTO_DA_FISSARE,
    PltOpportunityStatusCode.PENDING_ATTESA_PAGAMENTO,
  ],
  KO_FINANZIAMENTO: [
    PltOpportunityStatusCode.APPUNTAMENTO_DA_FISSARE,
    PltOpportunityStatusCode.PENDING_ATTESA_FINANZIAMENTO,
  ],
  KO_REPERIBILITA_OPPORTUNITA: [PltOpportunityStatusCode.APPUNTAMENTO_DA_FISSARE, ...pendingLavorazione],
  PRATICA_CHIUSA: [],
};
export const opportunityInstallerAction = {
  DA_INSTALLARE: [
    PltOpportunityStatusCode.INSTALLAZIONE_OK_DOCUMENTI_OK,
    PltOpportunityStatusCode.INSTALLAZIONE_OK_DOCUMENTI_PENDING,
    ...koLavorazione,
  ],
};
export const leadAdminAction = {
  ...ccOperatorAction,
};
export const opportunityAdminAction = {
  ...opportunityTechnicalCoordinatorAction,
  ...opportunityInstallerAction,
};

export function getEnumKeyByEnumValue<T extends { [index: string]: string }>(
  myEnum: T,
  enumValue: string,
): keyof T | null {
  let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
  return keys.length > 0 ? keys[0] : null;
}
