import {
  ContentChild,
  ContentChildren,
  Directive, ElementRef,
  Input,
  TemplateRef,
} from '@angular/core';

@Directive({ selector: '[t-filter]' })
export class DatatableFilterDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: '[t-col-title]' })
export class DatatableColumnTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: '[t-column]' })
export class DatatableColumnDirective {
  @Input() field: any;
  @Input() clientFilter: any;
  @Input() width: string;
  @Input() name: string;
  @Input() visible: boolean = true;
  @ContentChild(DatatableColumnTitleDirective) title;
  @ContentChild(DatatableFilterDirective) filter;

  constructor() {}
}

@Directive({ selector: '[t-header]' })
export class DatatableHeaderDirective {
  @ContentChildren(DatatableColumnDirective) columns;

  constructor() {}
}
@Directive({ selector: '[t-cell]' })
export class DatatableCellDirective {
  @Input() name: string;
  constructor(public elRef: ElementRef) {}
}

@Directive({ selector: '[t-body]' })
export class DatatableBodyDirective {
  @ContentChildren(DatatableCellDirective) cells;
  constructor(public templateRef: TemplateRef<any>) {}
}
