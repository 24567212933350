<ng-template header-body>
  <div class="text-end align-items-center">
    <button class="btn btn-header" [routerLink]="['./detail']">
      <i class="fas fa-plus cl-medium fa-1x"></i>
    </button>
  </div>
</ng-template>

<fullstack-datatable
  [list]="products"
  [totalRecords]="total"
  [cmItems]="cmItems"
  [options]="{ lazy: true }"
  (selected)="selected = $event"
  (lazyLoad)="onLazyLoad($event)"
>
  <div t-header>
    <div t-column [name]="'Nome'" field="name">
      <fullstack-input-box *t-filter [(ngModel)]="filter.name" (ngModelChange)="filter.name = $event; load()">
      </fullstack-input-box>
    </div>
    <div t-column [name]="'Categoria'">
      <fullstack-input-select-box
        *t-filter
        [(ngModel)]="filter.category"
        (ngModelChange)="filter.category = $event; load()"
      >
        <sb-option [value]="i.value" *ngFor="let i of categoies$ | async">{{ i.label }}</sb-option>
      </fullstack-input-select-box>
    </div>
    <div t-column [name]="'Prezzo'" field="price">
      <fullstack-slider
        *t-filter
        ngModel
        (ngModelChange)="
          filter.price = {
            from: $event[0],
            to: $event[1]
          };
          load()
        "
        [options]="{ min: 0, max: 3000 }"
      ></fullstack-slider>
    </div>
    <div t-column [name]="'Descrizione'"></div>
  </div>

  <ng-template t-body let-row>
    <td>{{ row?.name }}</td>
    <td>{{ row?.category | domainValue: 'products_category_type' | async }}</td>
    <td>{{ row?.price | currency: 'EUR' : '€' : '0.0-2' }}</td>
    <td>{{ row?.description }}</td>
  </ng-template>
</fullstack-datatable>
