import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import {
  AssignedPipe,
  EstimateProductsPipe,
  EstimateStatePipe,
  GeFirstNamePipe,
  GetEmailPipe,
  GetLastNamePipe,
  GetPhonePipe,
  GetRolesPipe,
  NameByIdPipe,
} from './utils.pipe';

const DECLARATIONS = [
  GetRolesPipe,
  NameByIdPipe,
  AssignedPipe,
  GetEmailPipe,
  GetPhonePipe,
  EstimateStatePipe,
  EstimateProductsPipe,
  GeFirstNamePipe,
  GetLastNamePipe,
];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [CommonModule],
  providers: [],
  exports: [...DECLARATIONS],
})
export class PipesModule {}
