import { NgModule } from '@angular/core';
import { SharedModule } from '../shared.module';
import { CalendarSlotSelectorModalComponent } from './calendar-slot-selector-modal/calendar-slot-selector-modal.component';
import { CreateEstimateModalComponent } from './create-estimate-modal/create-estimate-modal.component';
import { FormsModule } from '../forms/forms.module';
import { UpdateEstimateModalComponent } from './update-estimate-modal/update-estimate-modal.component';
import { AddNoteModal } from './add-note-modal/add-note-modal';
import { SelectPrimaryPackageModalComponent } from './select-primary-package-modal/select-primary-package-modal.component';
import { SelectAccessoryPackageModalComponent } from './select-accessory-package-modal/select-accessory-package-modal.component';
import { InsertSignatureModalComponent } from './insert-signature-modal/insert-signature-modal.component';
import { SignSurfaceModule } from '../components/sign-surface/sign-surface.module';
import { ViewEstimatePdfModalComponent } from './view-estimate-pdf-modal/view-estimate-pdf-modal.component';
import { PdfViewerModule } from '../components/pdf-viewer/pdf-viewer.module';

const DECLARATIONS = [
  CalendarSlotSelectorModalComponent,
  CreateEstimateModalComponent,
  UpdateEstimateModalComponent,
  AddNoteModal,
  ViewEstimatePdfModalComponent
];

@NgModule({
  declarations: [...DECLARATIONS, SelectPrimaryPackageModalComponent, SelectAccessoryPackageModalComponent, InsertSignatureModalComponent],
  providers: [],
  exports: [...DECLARATIONS],
  imports: [SharedModule, FormsModule, SignSurfaceModule, PdfViewerModule],
})
export class ModalsModule {}
