export interface IDashboardQuery {
  start?: Date;
  end?: Date;
  userId?: string;
}
export interface IMarketingLeadDashboardQuery {
  code?: number;
  start?: Date;
  end?: Date;
  network?: string;
}
