import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import type { ISite } from '@renovars/common';
import { MessageService } from 'primeng/api';
import { tap, catchError, of, map } from 'rxjs';
import { ISiteOptions, SITE_OPTIONS } from '../..';

@Injectable()
export class SiteService {
  constructor(
    private httpClient: HttpClient,
    private messageService: MessageService,
    @Inject(SITE_OPTIONS) private siteOpt: ISiteOptions,
  ) {}

  save(site: ISite) {
    return this.httpClient.post(`${this.siteOpt.url}/site`, site).pipe(
      tap(() => {
        this.messageService.add({
          severity: 'success',
          summary: 'Sede creata con successo',
        });
      }),
      catchError((err) => {
        this.messageService.add({
          severity: 'error',
          summary: err?.error?.message,
        });
        return of(err);
      }),
    );
  }
  findAll() {
    return this.httpClient.get<ISite[]>(`${this.siteOpt.url}/site`);
  }
  findById(id: string) {
    return this.httpClient.get(`${this.siteOpt.url}/site/${id}`);
  }
  update(id: string, site: ISite) {
    return this.httpClient.patch(`${this.siteOpt.url}/site/${id}`, site);
  }
  delete(id: string) {
    return this.httpClient.delete(`${this.siteOpt.url}/site/${id}`);
  }
  distincCampaingCodes() {
    return this.httpClient
      .get<string[]>(`${this.siteOpt.url}/leads/campaign/codes`)
      .pipe(map((values) => values.map((v) => ({ label: v, value: v }))));
  }
}
