import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OpportunityEventService } from './opportunity-event.service';
import { UtentiService } from './utenti.service';
import { PltUsersCacheService } from './plt-users-cache.service';
import { CsvUtilsService } from '../services/csv-utils.service';
import { EstimateService } from './estimates.service';

const DECLARATIONS = [];

@NgModule({
  declarations: [...DECLARATIONS],
  imports: [CommonModule],
  providers: [UtentiService, OpportunityEventService, PltUsersCacheService, CsvUtilsService, EstimateService],
  exports: [...DECLARATIONS],
})
export class ServicesModule {}
