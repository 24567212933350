import { Component, OnInit } from '@angular/core';
import { IUser } from '@renovars/common';
import { AppConfigService, AuthServices } from '@renovars/core-ng';
import { Observable } from 'rxjs';
import { environment } from './../../../../../environments/environment';

@Component({
  selector: 'home-page',
  templateUrl: `./home-page.component.html`,
})
export class HomePageComponent implements OnInit {
  user$: Observable<IUser>;
  environment = environment;

  constructor(private authService: AuthServices, private appConfig: AppConfigService) {}

  ngOnInit(): void {
    this.user$ = this.authService.user();
  }

  logout() {
    this.authService.logout(this.appConfig.getApplicationId());
  }
}
