<div class="d-flex flex-column m-2" style="width: 180px">
  <div class="align-self-center">
    <span class="small overflow-hidden">{{
      (value[0] != options?.min ? (value[0] | currency: '' : '' : '0.0-2') : '') +
        ' <-> ' +
        (value[1] != options?.max ? (value[1] | currency: '' : '' : '0.0-2') : '')
    }}</span>
  </div>
  <div class="p-2">
    <p-slider
      [(ngModel)]="value"
      [min]="options?.min"
      [max]="options?.max"
      [range]="true"
      [step]="options?.step"
      (onSlideEnd)="slideEnd($event)"
    ></p-slider>
  </div>
</div>
