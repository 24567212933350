import { AbstractControl, ControlValueAccessor, ValidationErrors } from '@angular/forms';

export class FormElement implements ControlValueAccessor {
  value: any;
  isDisabled;
  _onChange = (e) => {};
  _onTouch;
  _validatorChange;
  form: AbstractControl;

  writeValue(obj: any): void {
    this.value = obj;
    if (this.form) {
      this.form.patchValue(this.value, { onlySelf: true });
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
    if (this.form) {
      this.form.valueChanges.subscribe((v) => {
        this._onChange(v);
      });
    }
  }
  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }
  validate(): ValidationErrors {
    if (this.form) {
      return this.form.valid ? null : { error: true };
    }
  }
}
