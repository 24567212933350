import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { EstimateService } from '@fi/app/services/estimates.service';

@Component({
  selector: 'app-view-estimate-pdf-modal',
  templateUrl: './view-estimate-pdf-modal.component.html',
  styleUrls: ['./view-estimate-pdf-modal.component.scss'],
})
export class ViewEstimatePdfModalComponent {
  base64File: string;
  constructor(
    public dialogRef: MatDialogRef<ViewEstimatePdfModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { estimateId: string, projectId: string },
    private estimateApi: EstimateService,
  ) {


    this.estimateApi.exportBase64(this.data.estimateId, false).subscribe((res) => {
        this.base64File = `data:application/pdf;base64,${res.data}`;
    });
  }
}
