import { IContactCompany, IContactIndividual } from '@renovars/common';
import { PltOpportunityStatusCode } from '../../enums';
import { CommonFlow, FlowStatus, Lead } from './leads.interface';

export interface Opportinity extends CommonFlow<typeof PltOpportunityStatusCode> {
  contact: string;
  buyer: string;
  lead: string;
  attachments: string[];
  cache?: {
    lead: Lead;
    contact: IContactCompany | IContactIndividual;
    buyer: IContactCompany | IContactIndividual;
  };
}
export type OpportunityStatus = FlowStatus<typeof PltOpportunityStatusCode>;
export type OpportunityStatusCommand = Pick<OpportunityStatus, 'code' | 'metadata' | 'assigned'>;
