export interface IDomainValueMeta<T = any> {
  type: string;
  code: number;
  data?: T;
}

export enum DocumentRequiredState {
  INCARICO = 'incarico',
  PUBBLICAZIONE = 'pubblicazione',
  VENDITA = 'vendita',
  ACQUISTO = 'acquisto',
  LOCAZIONE = 'locazione',
  AFFITTO = 'affitto',
}
export enum DocumentAction {
  SOTTOSCRIVERE = 'da_sottoscrivere',
  SCARICARE = 'scaricare',
  CARICARE = 'caricare',
}

export const documentRequiredStateLabels = [
  {
    label: 'Incarico',
    value: DocumentRequiredState.INCARICO,
    color: 'secondary',
  },
  {
    label: 'Pubblicazione',
    value: DocumentRequiredState.PUBBLICAZIONE,
    color: 'warning',
  },
  { label: 'Vendita', value: DocumentRequiredState.VENDITA, color: 'danger' },
  { label: 'Acquisto', value: DocumentRequiredState.ACQUISTO, color: 'danger' },
  {
    label: 'Locazione',
    value: DocumentRequiredState.LOCAZIONE,
    color: 'danger',
  },
  { label: 'Affitto', value: DocumentRequiredState.AFFITTO, color: 'danger' },
  { label: 'Altri', value: null, color: 'primary' },
];

export const documentActionLabels = [
  { label: 'Da sottoscrivere', value: DocumentAction.SOTTOSCRIVERE },
  { label: 'Da scaricare', value: DocumentAction.SCARICARE },
  { label: 'Da caricare', value: DocumentAction.CARICARE },
];
