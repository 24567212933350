import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ISource } from '@renovars/common';
import { SourcesServices } from '@renovars/fe-core-libs/services/sources.service';

@Component({
  selector: 'source-detail',
  template: `
    <ng-template header-body></ng-template>
    <div class="content">
      <form [formGroup]="form" (submit)="save()" novalidate>
        <source-form formControlName="sourceForm"></source-form>

        <div class="text-end">
          <button type="submit" class="btn btn-secondary rounded">Salva</button>
        </div>
      </form>
    </div>
  `,
})
export class SourceDetailComponent implements OnInit {
  id;
  form = this.fb.group({
    sourceForm: this.fb.control(null, []),
  });

  constructor(
    protected fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private sourceService: SourcesServices
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe((p) => {
      this.id = p.id;

      if (this.id) {
        this.sourceService.get(this.id).subscribe((res) => {
          this.form.get('sourceForm').patchValue(res);
        });
      }
    });
  }

  save() {
    if (this.form.valid) {
      const obj: ISource = this.form.get('sourceForm').value;
      if (this.id) {
        this.sourceService
          .update(this.id, obj)
          .subscribe(() => this.router.navigate(['/home/settings/sources']));
      } else {
        this.sourceService
          .save(obj)
          .subscribe(() => this.router.navigate(['/home/settings/sources']));
      }
    }
  }
}
