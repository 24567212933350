<div class="row">
  <div class="col-12">
    <header class="page-header mb-4">
      <div class="row align-items-center">
        <div class="col">
          <h2 class="tx-title-2">Timeline</h2>
        </div>
      </div>
    </header>
  </div>
</div>
<div class="row timeline" *ngFor="let item of items">
  <ng-container *ngIf="item">
    <div class="col-3">
      <span class="timeline__date">{{ item.createdAt | date : "dd" }}</span
      ><span class="timeline__month">{{ item.createdAt | date : "MMMM" }}</span
      ><span class="timeline__year">{{ item.createdAt | date : "yy" }}</span
      ><span class="timeline__time">{{ item.createdAt | date : "HH:mm" }}</span>
    </div>
    <div class="col-9 small">
      <div class="card card--kanban is-timeline p-2">
        <div>
          <strong>{{ item.title }}:</strong>
        </div>
        <div>
          <small>{{ item.body }}</small>
        </div>
        <div class="text-right m-1">
          <small>{{ item.username }}</small>
        </div>
      </div>
    </div>
  </ng-container>
</div>
