import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  CommonRoles,
  EventType,
  IAvailabilitySlot,
  IContact,
  IDomainValue,
  IEvent,
  IJwtPayload,
  IPaginateRequest,
  IUser,
} from '@renovars/common';
import { FiRoles, IFiAppointmentSearchDTO } from '@renovars/common/facileimmobiliare';
import { PltEventType } from '@renovars/common/plenitude';
import { ContactsServices, AuthServices } from '@renovars/core-ng';
import { DeletePersonalModalComponent } from '@renovars/fe-core-libs/features/calendario/components/modals/delete-personal-modal/delete-personal-modal.component';
import { PersonalEventModalComponent } from '@renovars/fe-core-libs/features/calendario/components/modals/personal-event-modal/personal-event-modal.component';
import {
  CalendarioModuleConfig,
  CALENDARIO_CONFIG_PROVIDER,
} from '@renovars/fe-core-libs/features/calendario/module.options';
import { CalendarioService } from '@renovars/fe-core-libs/services/calendario.service';
import { DomainValuesService } from '@renovars/fe-core-libs/services/domain-values.service';
import { UsersService } from '@renovars/fe-core-libs/services/users.service';
import _ from 'lodash';
import { Observable, pluck } from 'rxjs';

@Component({
  selector: 'plt-calendar',
  templateUrl: './plt-calendar.component.html',
  styles: [],
})
export class PltCalendarComponent implements OnInit {
  list: IEvent[];
  selectedUserAvailability: IAvailabilitySlot[];
  storeManagers$: Observable<IUser[]>;
  contacts$: Observable<IContact[]>;
  paginateRequest: IPaginateRequest<IFiAppointmentSearchDTO> = {
    form: { showOnlyValidEvents: true },
    skip: 0,
    limit: 1000,
  };
  selected;
  eventTypes: IDomainValue<unknown>[];
  domainValuesList$: Observable<IDomainValue<unknown>[]>;
  currentUser: IJwtPayload;
  agentList$: Observable<IUser[]>;
  constructor(
    @Inject(CALENDARIO_CONFIG_PROVIDER) protected config: CalendarioModuleConfig,
    protected calendarioService: CalendarioService,
    protected usersService: UsersService,
    protected contactsService: ContactsServices,
    protected authService: AuthServices,
    protected dialog: MatDialog,
    protected domainValueService: DomainValuesService
  ) {}

  ngOnInit(): void {
    this.storeManagers$ = this.usersService.getUsersListDownline([CommonRoles.IS_ADMIN], null);
    this.eventTypes = Object.keys(PltEventType)
      .filter((k, index) => index < 2)
      .map((k) => ({ code: k, label: PltEventType[k] } as any));
    this.authService.user().subscribe((u) => (this.currentUser = u));

    this.loadAgentList(null);
  }

  searchContact(searchString) {
    this.contacts$ = this.contactsService.searchByNominative(searchString).pipe(pluck('data'));
  }

  search() {
    this.calendarioService
      .search(_.cloneDeep(this.paginateRequest))
      .subscribe((res) => (this.list = res.data));
  }

  searchByAddress(event) {
    this.paginateRequest.form.address = event.target.value;
    this.search();
  }

  searchByUser(userId: string) {
    this.selectedUserAvailability = null;
    this.usersService.getUserMeta(userId).subscribe((res) => {
      this.selectedUserAvailability = res ? res.availabilitySlots : null;
    });

    this.paginateRequest.form.network = undefined;
    this.paginateRequest.form.userId = userId;
    this.search();
  }

  viewChange(data) {
    this.paginateRequest.form.eventStartRange = { start: data.currentStart, end: data.currentEnd };
    this.search();
  }

  onEventClick(event) {
    if (event.event && Object.keys(event.event.extendedProps).length > 0) {
      const isSameUser = this.currentUser.sub === event.event.extendedProps.insertUser;
      const isAdmin = this.currentUser.roles.includes(CommonRoles.IS_ADMIN);
      const isPersonal = event.event.extendedProps.type === EventType.PERSONALE;
      if (isPersonal) {
        if (isSameUser || isAdmin) {
          this.dialog
            .open(DeletePersonalModalComponent, {
              data: {
                event: event.event.extendedProps,
              },
            })
            .afterClosed()
            .subscribe((res) => {
              if (res) {
                this.search();
              }
            });
        }
      } else {
        this.dialog.open(this.config.eventModalClass, {
          minWidth: 800,
          data: {
            event: event.event.extendedProps,
          },
        });
      }
    }
  }

  getColor(event: IEvent) {
    switch (event.type) {
      case 1:
        return '#29b6f6';
      case 2:
        return '#66bb6a';
      case EventType.PERSONALE:
        return '#FF5252';
      default:
        return '#ffa726';
    }
  }

  createPersonalEventClick() {
    this.dialog
      .open(PersonalEventModalComponent, {
        data: {},
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.search();
        }
      });
  }

  getEvent(e) {
    const name =
      e?.cache?.contact?.businessName || e?.cache?.contact?.firstName + ' ' + e?.cache?.contact?.lastName;
    let title = `${name}`;
    if (e.type === EventType.PERSONALE) {
      title = `Riservato`;
    }
    return {
      title,
      start: e.start,
      end: e.end,
      color: this.getColor(e),
      extendedProps: e,
    };
  }

  onDateClick(event) {
    this.dialog
      .open(PersonalEventModalComponent, {
        data: {
          start: event.date,
        },
      })
      .afterClosed()
      .subscribe((res) => {
        if (res) {
          this.search();
        }
      });
  }

  loadAgentList(rootAgent) {
    this.agentList$ = this.usersService.getUsersListDownline([FiRoles.IS_ADMIN], null, rootAgent);
  }

  setNetworkFilter(value: string) {
    this.paginateRequest.form.userId = undefined;
    this.paginateRequest.form.network = value;
    this.search();
  }
}
